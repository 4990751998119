import { IAppPaginationClass } from './AppPaginationTypes';

class $AppPaginationClass implements IAppPaginationClass {
  private _count = 0;
  private _currentPage = 1;
  public itemOnPage = 10;

  public goToPage(page: number): void {
    if (this.currentPage !== 0 && this.currentPage !== this.getMaxPage + 1) {
      this.currentPage = page;
    }
  }

  public goToPrev(): void {
    this.currentPage -= 1;
  }

  public goToNext(): void {
    this.currentPage += 1;
  }

  public goToLast(): void {
    this.currentPage = this.getMaxPage;
  }

  public goToFirst(): void {
    this.currentPage = 1;
  }

  public get getListExtreme(): { idxFrom: number; idxTo: number } {
    let secondEdge, firstEdge;

    if (this.currentPage === this.getMaxPage) {
      secondEdge = this.count;
      firstEdge = secondEdge - (secondEdge - (this.currentPage - 1) * this.itemOnPage);
    } else if (this.currentPage === 1) {
      firstEdge = 0;
      secondEdge = this.itemOnPage;
    } else {
      firstEdge = (this.currentPage - 1) * this.itemOnPage;
      secondEdge = firstEdge + this.itemOnPage;
    }

    const edges = {
      idxFrom: firstEdge,
      idxTo: secondEdge,
    };
    return edges;
  }

  public get getMaxPage(): number {
    return Math.ceil(this.count / this.itemOnPage);
  }

  public get isDisabledPrev(): boolean {
    return this.currentPage === 1;
  }

  public get isDisabledNext(): boolean {
    return this.currentPage === this.getMaxPage;
  }

  public get paginationController(): Array<number> {
    if (this.getMaxPage >= 3) {
      if (this.currentPage === 1) {
        return [this.currentPage, this.currentPage + 1, this.currentPage + 2];
      }
      if (this.currentPage === this.getMaxPage) {
        return [this.getMaxPage - 2, this.getMaxPage - 1, this.getMaxPage];
      }
      return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
    }
    return this.getMaxPage === 2 ? [1, 2] : [1];
  }

  get count() {
    return this._count;
  }

  set count(val: number) {
    this._count = val;
  }

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(val: number) {
    this._currentPage = val;
  }
}
export default new $AppPaginationClass();
