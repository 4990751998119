import { IProfileInfo } from '@/services/ProfileService/ProfileServiceTypes';

export interface IState {
  profileInfo: IProfileInfo;
  imageUploadProfile: string;
  isFetchingImageUploadProfile: boolean;
  portfolioFileUpload: string;
  isFetchingPortfolioFileUpload: boolean;
  patchProfileErrorStatusCode: number | null;
}

export default () => ({
  profileInfo: {} as IProfileInfo,
  imageUploadProfile: '',
  isFetchingImageUploadProfile: false,
  portfolioFileUpload: '',
  isFetchingPortfolioFileUpload: false,
  patchProfileErrorStatusCode: null,
});
