<template>
  <a
    :href="item.url"
    target="_blank"
    class="box-border w-full appearance-none select-none flex justify-between items-center py-2 text-black-50 hover:text-client active:text-client"
  >
    <div class="text-lg">{{ item.name }}</div>
    <UIIcon name="chevron-up" :size="24" rotate="90deg" class="stroke-current fill-none" />
  </a>
</template>

<script lang="ts">
export default { name: 'MobileNavItem' };
</script>

<script setup lang="ts">
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { LayoutNavItem } from '@/helpers/types/layouts.types';

defineProps<{
  item: LayoutNavItem;
}>();
</script>

<style scoped lang="scss">
a {
  -webkit-tap-highlight-color: transparent !important;
}
</style>
