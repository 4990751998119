export function checkStringIncludesString(
  checkedString: string | undefined,
  stringToCheck: string
): boolean {
  if (!checkedString) return false;
  return checkedString.toLowerCase().includes(stringToCheck.toLowerCase());
}

export const lineBreaker = (str?: string, length?: number) => {
  if (str?.length && length) {
    return str.length > length ? str.substring(0, length) + '...' : str;
  }
};
