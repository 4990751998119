<template>
  <div class="app-input-dropdown">
    <app-input
      @onInput="search"
      :color="'secondary'"
      :disabled="disabled"
      :value="activeItem[listKey]"
      :label="label"
      :errors="errors"
      type="main"
      :placeholder="placeholder"
      :note="note"
    />

    <div
      v-if="isShowList"
      class="select-list"
      :class="{ scroll: list.length > 7 }"
      v-click-outside="closeList"
    >
      <div class="select-list__spinner" v-if="isLoading || initialDropdownOpen">
        <app-spinner size="small" />
      </div>
      <div v-else-if="!list || list.length === 0">
        <button class="select-list__item" disabled>
          <app-text text="Не найдено" />
        </button>
      </div>
      <div v-else>
        <button
          v-for="(item, index) in list"
          :key="index"
          class="select-list__item"
          :class="{ active: item[listKey] === activeItem[listKey] }"
          @click="changeActiveItem(item)"
        >
          <app-text v-if="item.title" :text="item.title" />
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppSpinner from '@/components/common/app-spinner';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';

export default Vue.extend({
  components: {
    AppInput,
    AppText,
    AppSpinner,
  },
  props: {
    defaultValue: {
      type: Object,
      required: false,
    },
    listKey: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    note: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },
  data() {
    const activeItem: ISelectItem = {};
    return {
      isShowList: false,
      activeItem,
      //required for spinner appearing when list drops initially
      initialDropdownOpen: true,
    };
  },
  watch: {
    defaultValue() {
      this.activeItem = { ...this.defaultValue };
    },
    isLoading(state) {
      if (state) this.initialDropdownOpen = false;
    },
  },
  methods: {
    changeActiveItem(value: ISelectItem): void {
      if (this.activeItem[this.listKey] == value[this.listKey]) {
        this.activeItem = {};
      } else {
        this.activeItem = { ...value };
      }
      this.$emit('onSelect', value);
      this.closeList();
    },
    closeList(): void {
      this.isShowList = false;
    },
    showList() {
      this.isShowList = true;
    },
    search(value: string) {
      if (value.length >= 3) {
        this.showList();
      } else this.closeList();
      this.$emit('search', value);
      if (value.length < 3) {
        this.initialDropdownOpen = true;
      }
      if (value.length === 0) {
        this.activeItem[this.listKey] = '';
      }
    },
  },
  beforeMount() {
    this.activeItem = { ...this.defaultValue };
  },
});
</script>
<style lang="scss">
@import 'style';
</style>
