<template>
  <div class="app-multi-select-wrap">
    <button class="selected-block" :class="{ active: isShowList }" @click="toggleIsShowList">
      <app-text class="selected-block__name" :text="getTitle" />
    </button>
    <div
      v-if="isShowList"
      class="select-list"
      :class="{ scroll: items.length > 7 }"
      v-click-outside="closeList"
    >
      <div v-for="(item, index) in items" :key="index" class="select-list__item">
        <app-check-box
          :text="item[listKey]"
          :is-checked="checkActiveItem(item)"
          @change="changeActiveItems(item)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';

export default Vue.extend({
  name: 'AppMultiSelect',
  components: { AppText, AppCheckBox },
  props: {
    defaultValues: {
      type: Array as PropType<Array<ISelectItem>>,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    listKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      required: true,
    },
  },
  data() {
    const activeItems: Array<ISelectItem> = [];
    return {
      isShowList: false,
      activeItems,
    };
  },
  beforeMount() {
    if (this.defaultValues?.length) {
      this.activeItems = this.defaultValues?.map((item) => {
        return { ...item };
      });
    }
  },
  computed: {
    getTitle() {
      let title = '';
      if (!this.activeItems.length) {
        return this.titleText;
      }
      this.activeItems.forEach((item, index) => {
        if (index === this.activeItems.length - 1) {
          title = `${title}${item[this.listKey]}`;
        } else {
          title = `${title}${item[this.listKey]}, `;
        }
      });
      return title;
    },
  },
  methods: {
    toggleIsShowList(): void {
      if (!this.disabled) {
        this.isShowList = !this.isShowList;
      }
    },
    changeActiveItems(value: ISelectItem) {
      let hasItem = this.checkActiveItem(value);
      if (hasItem) {
        this.activeItems = this.activeItems.filter(
          (item) => item[this.listKey] !== value[this.listKey]
        );
      } else {
        this.activeItems.push(value);
      }
      return this.$emit('onSelect', this.activeItems);
    },
    checkActiveItem(value: ISelectItem) {
      return this.activeItems?.some((item) => item[this.listKey] === value[this.listKey]);
    },
    closeList(): void {
      this.isShowList = false;
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
