// import { IState } from '@/store/modules/user-management/state';
// import { formattedDate } from '@/utils/custom';

export default {
  // invitedUsersList: (state: IState) => {
  //   return state.invitedUsers?.invitations?.map((item, index) => {
  //     let statusTable = '';
  //     if (item.isViewed && item.isEmailSended && item.status === null) {
  //       statusTable = 'Просмотрено';
  //     }
  //     if (item.isEmailSended && !item.isViewed && item.status === null) {
  //       statusTable = 'Отправлено';
  //     }
  //     if (item.status === false && item.isEmailSended && item.isViewed) {
  //       statusTable = 'Отклонено';
  //     }
  //     const dateChangeStatus = `${formattedDate(item.updatedAt, 'onlyDate')} ${formattedDate(
  //       item.updatedAt,
  //       'onlyTime'
  //     )}`;
  //
  //     return {
  //       ...item,
  //       actions: '',
  //       check: '',
  //       statusTable: statusTable,
  //       updatedAt: dateChangeStatus,
  //     };
  //   });
  // },
  // eventUsersList: (state: IState) => {
  //   return state.eventUsers?.eventUsers?.map((item) => {
  //     let registrationSource = '';
  //     const dateRegistration = `${formattedDate(item.registrationDate, 'onlyDate')} ${formattedDate(
  //       item.registrationDate,
  //       'onlyTime'
  //     )}`;
  //     const lastActivity = `${formattedDate(item.lastActiveDate, 'onlyDate')} ${formattedDate(
  //       item.lastActiveDate,
  //       'onlyTime'
  //     )}`;
  //     if (item.commonSource === 'SELF_REGISTRATION') {
  //       registrationSource = 'Зарегистрировался сам';
  //     }
  //     if (item.commonSource === 'REFERRAL_LINK') {
  //       registrationSource = item.referralSource?.source;
  //     }
  //     if (item.commonSource === 'INVITATION') {
  //       registrationSource = 'Приглашение';
  //     }
  //     return {
  //       ...item,
  //       actions: '',
  //       check: '',
  //       registrationDate: dateRegistration,
  //       lastActiveDate: lastActivity,
  //       registrationSource,
  //     };
  //   });
  // },
};
