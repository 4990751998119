<template>
  <button
    :class="classes"
    :disabled="isDisabled"
    :style="`background: ${getButtonColor}`"
    :type="webType"
    :aria-label="text"
    @click="$emit('click')"
  >
    <slot name="prepend" />
    <AppText
      v-if="text"
      :text="text"
      :class="titleClasses"
      :color="isDisabled ? 'main' : colorText"
      :type="fontType"
    />
    <slot name="append" />
    <slot />
  </button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import {
  TButtonType,
  TButtonSize,
  TButtonText,
  TButtonWebType,
  ButtonSize,
  ButtonType,
  ButtonWebType,
} from './AppButtonTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import {
  ITextColor,
  TextColorEnum,
  TextTypeEnum,
} from '@/ui/components/text/app-text/AppTextTypes';

export default Vue.extend({
  name: 'AppButton',
  components: { AppText },
  props: {
    type: {
      type: String as PropType<TButtonType>,
      default: 'primary',
      validator: (value) => Object.values(ButtonType).includes(value as ButtonType),
    },
    fontType: {
      type: String as PropType<TButtonText>,
      default: 'main-book',
      validator: (value) => Object.values(TextTypeEnum).includes(value as TextTypeEnum),
    },
    size: {
      type: String as PropType<TButtonSize>,
      default: 'medium',
      validator: (value) => Object.values(ButtonSize).includes(value as ButtonSize),
    },
    text: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    webType: {
      type: String as PropType<TButtonWebType>,
      default: 'button',
      validator: (value) => Object.values(ButtonWebType).includes(value as ButtonWebType),
    },
    colorButton: {
      type: String,
    },
    colorText: {
      type: String as PropType<ITextColor>,
      default: 'button-title',
      validator: (value) => Object.values(TextColorEnum).includes(value as ITextColor),
    },
  },
  computed: {
    classes() {
      return [
        'button',
        `button--${this.type}`,
        `button--${this.size}`,
        { disabled: this.isDisabled },
      ];
    },
    getButtonColor() {
      if (this.type === 'primary' && !this.isDisabled) {
        return this.colorButton;
      } else return '';
    },
    titleClasses() {
      return [`button__text-${this.fontType}`];
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
