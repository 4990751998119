<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="fourthTitle"
    text-head="Краткая информация"
    :is-confirm-exit="true"
  >
    <div class="app-modal-profile-edit-short-info">
      <app-input
        tag="textarea"
        placeholder="Введите краткую информацию о себе"
        :value="inputValue"
        @onInput="
          (val) => {
            inputValue = val;
          }
        "
      />
      <app-button
        text="Сохранить изменения"
        @click="saveProfileDescription(inputValue)"
        :is-disabled="isActiveSubmitButton"
      />
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton, AppInput } from '@/ui/components';
import { mapActions, mapState } from 'vuex';
import { ICountry } from '@/services/ProfileService/ProfileServiceTypes';
export default Vue.extend({
  components: {
    AppModal,
    AppButton,
    AppInput,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputValue: '',
      countryValue: {} as ICountry,
      firstName: '',
      lastName: '',
    };
  },
  methods: {
    ...mapActions('profile', ['AChangeProfileDescription']),
    toggleModal() {
      this.$emit('toggleModal');
    },

    saveProfileDescription(value: string) {
      if (value !== '') {
        this.AChangeProfileDescription({
          shortBiography: value,
        });
      }
      this.toggleModal();
    },
  },
  computed: {
    ...mapState('profile', ['profileInfo']),
    isActiveSubmitButton(): boolean {
      return !this.inputValue;
    },
  },
  mounted() {
    this.countryValue = this.profileInfo?.profile?.countryOfResidence;
    this.firstName = this.profileInfo?.profile?.firstName;
    this.lastName = this.profileInfo?.profile?.lastName;
    this.inputValue = this.profileInfo?.profile?.shortBiography;
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.app-modal-profile-edit-short-info {
  .app-input__inner_input {
    min-height: 250px;
    resize: none;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        border-radius: 6px;
        background-color: var(--client-color);
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &-track {
        background: white;
        border-radius: 6px;
      }
    }
  }
}
</style>
