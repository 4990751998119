<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head=""
    type="thirdTitle"
    :is-align-text="true"
  >
    <div class="flex flex-col items-center justify-center space-y-[30px] lg:py-[16px]">
      <h2 class="text-2xl lg:text-5xl/3 font-medium">Уведомление</h2>
      <UIIcon name="info" :size="81" class="stroke-client fill-none" />
      <p class="text-sm lg:text-base text-black-50/70 text-center mw-[444px]">
        Данный функционал доступен при использовании модуля Регистрации и авторизации, чтобы им
        воспользоваться необходимо расширить лицензию ММСО. Коннект
      </p>
    </div>
  </AppModal>
</template>

<script lang="ts">
export default { name: 'AppModalAccessDenied' };
</script>

<script setup lang="ts">
import AppModal from '@/ui/components/modals/app-modal';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';

const emits = defineEmits(['toggleModal']);
defineProps<{ isShowModal: boolean }>();

const toggleModal = () => {
  emits('toggleModal');
};
</script>
