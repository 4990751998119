var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowModal)?_c('AppModal',{attrs:{"is-show-modal":_vm.isShowModal,"is-align-text":true,"text-type":"thirdTitle","text-head":"Наполнение слайдера","is-confirm-exit":true},on:{"toggleModal":_vm.toggleModal}},[_c('div',{key:_vm.countKey},_vm._l((_vm.sliderListInfo),function(item,index){return _c('div',{key:item.id,staticClass:"event-images-slider"},[(_vm.sliderListInfo?.length > 1)?_c('div',{staticClass:"event-images-slider__header"},[_c('app-text',{attrs:{"text":`Карточка ${index + 1}`,"type":"primary","line-height":"25"}}),_c('div',{staticClass:"event-images-slider__header-toolbar"},[_c('app-button-icon',{attrs:{"name":"trash-2","color":"#83888F"},on:{"click":function($event){return _vm.removeSlider(item.id)}}}),(index !== _vm.sliderListInfo?.length - 1)?_c('app-button-icon',{attrs:{"name":"arrow-down","color":"#83888F"},on:{"click":function($event){return _vm.moveDown(index)}}}):_vm._e(),(index !== 0)?_c('app-button-icon',{attrs:{"name":"arrow-up","color":"#83888F"},on:{"click":function($event){return _vm.moveUp(index)}}}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"event-images-slider__image-input"},[_c('div',{staticClass:"event-images-slider__item"},[(_vm.isFetchingInfoBlockImageUpload && _vm.activeIndex === index)?_c('div',{staticClass:"event-images-slider__spinner-container"},[_c('app-spinner',{attrs:{"size":"small"}})],1):(item.image)?_c('div',{staticClass:"event-images-slider__image-container"},[_c('img',{staticClass:"event-images-slider__image",attrs:{"src":item.image}}),(item.image)?_c('app-button-icon',{attrs:{"name":"x","color":"#FFFFFF","icon-size":"20"},on:{"click":function($event){return _vm.deleteImage(item.id)}}}):_vm._e()],1):_c('div',{staticClass:"event-images-slider__input-container",class:{
              'with-err': item.errorImage && _vm.errorIndexesList.includes(index),
            }},[_c('input',{staticClass:"event-images-slider__add-image-input",attrs:{"id":"add-file","type":"file","name":"add-file","accept":_vm.acceptedFileTypes,"multiple":""},on:{"change":(event) => _vm.AUploadImage(event, item.id, index)}}),_c('div',{staticClass:"event-images-slider__info"},[_c('app-icon',{attrs:{"name":"plus","size":"18","color":"#83888F"}}),_c('app-text',{attrs:{"text":"Переместите фото с вашего пк в эту область или нажмите “+”","type":"small","line-height":"20","special-text-color":"#83888F"}})],1)]),(
              _vm.failedUploadImageErrorCode === 422 &&
              !_vm.isFetchingInfoBlockImageUpload &&
              _vm.activeIndex === index
            )?_c('app-text',{attrs:{"text":"Загрузите изображение в формате: jpg, jpeg, png, не\n          более 10 МБ","type":"small","color":"error"}}):_vm._e()],1)]),_c('app-input',{attrs:{"label":"Заголовок карточки","type":"text","maxlength":_vm.EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH,"value":item.title,"placeholder":"Заголовок","note":`${item.title.length}/${_vm.EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH}`,"errors":item.errorTitle},on:{"onInput":(value) => _vm.onInputValueTitle(value, item.id, index)},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('app-input',{staticClass:"event-images-slider__input-description",attrs:{"label":"Текст","type":"text","tag":"textarea","maxlength":_vm.EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION,"value":item.description,"placeholder":"Описание","note":`${item.description.length}/${_vm.EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION}`,"errors":item.errorDescription},on:{"onInput":(value) => _vm.onInputValueDescription(value, item.id, index)},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)}),0),_c('app-button',{staticClass:"event-images-slider__add-button",attrs:{"text":"Добавить еще одну карточку","type":"secondary","font-type":"primary"},on:{"click":_vm.addNewSlide}}),_c('app-button',{attrs:{"text":"Сохранить"},on:{"click":_vm.onSaveInfoBlock}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }