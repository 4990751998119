<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Восстановление пароля"
  >
    <div v-if="step === 1">
      <div class="app-modal-recovery-by-phone__subtitle">
        <app-text
          type="primary-book"
          color="secondary"
          :text="'Введите номер телефона, который вы использовали при регистрации, на него мы отправим код для сброса пароля.'"
        />
      </div>
      <AppInput
        class="app-modal-recovery-by-phone__input-email"
        type="phone"
        label="Номер телефона"
        placeholder="+7 ( ___ ) ___ __ __"
        :value="email"
        @onInput="(val) => onInput(val, 'email')"
      />
      <AppButton text="Продолжить" :is-disabled="false" />
    </div>
    <div v-if="step === 2">
      <div class="app-modal-recovery-by-phone__subtitle">
        <app-text
          type="primary-book"
          color="secondary"
          :text="'На ваш номер было выслано СМС с кодом подтверждения.'"
        />
      </div>

      <AppButton text="Продолжить" :is-disabled="false" />
      <div class="app-modal-recovery-by-phone__note">
        <app-text type="primary-book" color="secondary" :text="'Отправить код повторно'" />
      </div>
    </div>
    <div v-if="step === 3">
      <div class="app-modal-recovery-by-phone__subtitle">
        <app-text
          type="primary-book"
          color="secondary"
          :text="'Пароль успешно сброшен. Установите, пожалуйста, новый пароль.'"
        />
      </div>
      <AppInput
        class="app-modal-recovery-by-phone__input-password"
        label="Пароль"
        placeholder="Введите пароль"
        note="Пароль должен иметь не менее 8 символов, строчные и прописные буквы и цифры."
        :value="password"
        :type="isPassHidden ? 'password' : 'text'"
        @onInput="(val) => onInput(val, 'email')"
        ><template #append>
          <div
            class="app-modal-recovery-by-phone__password-controller"
            @click="isPassHidden = !isPassHidden"
          >
            <app-icon :name="isPassHidden ? 'eye-off' : 'eye'" />
          </div> </template
      ></AppInput>
      <AppButton
        class="app-modal-recovery-by-phone__button"
        text="Продолжить"
        :is-disabled="false"
      />
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { AppIcon } from '@/ui/components/icons';

export default Vue.extend({
  name: 'AppModalRecoveryByPhone',
  components: { AppModal, AppText, AppInput, AppButton, AppIcon },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isLetterSent: {
      type: Boolean,
      default: false,
    },
    isShowPasswordInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      step: 2,
      email: '',
      password: '',
      isPassHidden: true,
    };
  },
  methods: {
    toggleModal() {
      return this.$emit('toggleModal');
    },
    openModalRegistration() {
      return this.$emit('openModalRegistration');
    },
    onInput(val: string, field: string) {
      field === 'email' ? (this.email = val) : (this.password = val);
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
