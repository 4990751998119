import { IState } from './state';
import {
  IEvent,
  IEventInfo,
  IEventProgram,
  IFavoriteEvent,
  IInvitationsEventInfo,
  IMyEvent,
  IRecommended,
  ISpeakerCard,
  IUserEventDocument,
} from '@/services/EventService/EventServiceTypes';
import { BannerAdsBlockData } from '@/helpers/types/shared/banners.types';
import { INewsItem } from '@/helpers/types/admin/admin-news.types';

export const types = {
  SET_EVENT_LIST: 'SET_EVENT_LIST',
  SET_EVENT_INFO: 'SET_EVENT_INFO',
  SET_MY_EVENT_LIST: 'SET_MY_EVENT_LIST',
  SET_FAVORITE_EVENTS: 'SET_FAVORITE_EVENTS',
  SET_USER_DOCUMENTS_READ: 'SET_USER_DOCUMENTS_READ',
  SET_INVITATIONS_EVENT: 'SET_INVITATIONS_EVENT',
  CANCEL_INVITATION: 'CANCEL_INVITATION',
  LOGOUT_EVENT: 'LOGOUT_EVENT',
  ADD_FAVORITE_EVENT: 'ADD_FAVORITE_EVENT',
  DELETE_FAVORITE_EVENT: 'DELETE_FAVORITE_EVENT',
  READ_EVENT_DOCUMENT: 'READ_EVENT_DOCUMENT',
  SET_RECOMMENDED_EVENTS: 'SET_RECOMMENDED_EVENTS',
  SET_SEARCH_PROGRAM: 'SET_SEARCH_PROGRAM',
  SET_UPLOAD_IMAGE_EVENT: 'SET_UPLOAD_IMAGE_EVENT',
  SET_FETCHING_UPLOAD_IMAGE_EVENT: 'SET_FETCHING_UPLOAD_IMAGE_EVENT',
  SET_FETCHING_UPLOAD_FILE: 'SET_FETCHING_UPLOAD_FILE',
  SET_UPLOAD_FILE: 'SET_UPLOAD_FILE',
  CLEAR_IMAGE_EVENT: 'CLEAR_IMAGE_EVENT',
  DELETE_FAVORITE_PROGRAM: 'DELETE_FAVORITE_PROGRAM',
};

export default {
  [types.SET_EVENT_LIST](state: IState, eventList: Array<IEvent>) {
    state.eventList = eventList;
  },
  [types.SET_EVENT_INFO](state: IState, eventInfo: IEventInfo) {
    state.eventInfo = eventInfo;
  },
  [types.SET_FAVORITE_EVENTS](state: IState, favoriteEvents: Array<IFavoriteEvent>) {
    state.favoriteEvents = favoriteEvents;
  },
  [types.SET_MY_EVENT_LIST](state: IState, myEventList: Array<IMyEvent>) {
    state.myEventList = myEventList;
  },
  [types.SET_USER_DOCUMENTS_READ](state: IState, documentsRead: Array<IUserEventDocument>) {
    state.eventDocumentsRead = documentsRead;
  },
  [types.SET_INVITATIONS_EVENT](state: IState, invitationsEventInfo: IInvitationsEventInfo) {
    state.invitationsEventInfo = invitationsEventInfo.uncheckedInvitations ?? [];
  },
  [types.CANCEL_INVITATION](state: IState, invitationId: string) {
    state.invitationsEventInfo = state.invitationsEventInfo.filter(
      (item) => item.uuid !== invitationId
    );
  },
  [types.LOGOUT_EVENT](state: IState) {
    state.myEventList = [];
    state.favoriteEvents = [];
    state.eventDocumentsRead = [];
    state.invitationsEventInfo = [];
  },
  [types.ADD_FAVORITE_EVENT](state: IState, favoriteEvent: IFavoriteEvent) {
    state.favoriteEvents.push(favoriteEvent);
  },
  [types.DELETE_FAVORITE_EVENT](state: IState, eventUUID: string) {
    state.favoriteEvents = state.favoriteEvents.filter((item) => item.eventUUID !== eventUUID);
  },
  [types.READ_EVENT_DOCUMENT](state: IState, readDocument: IUserEventDocument) {
    state.eventDocumentsRead.push(readDocument);
  },
  [types.SET_RECOMMENDED_EVENTS](state: IState, recommended: IRecommended) {
    state.recommended = recommended;
  },
  [types.SET_SEARCH_PROGRAM](state: IState, searchProgram: Array<IEventProgram>) {
    state.eventInfo.eventProgram = searchProgram;
    state.searchProgram = searchProgram;
  },
  [types.SET_UPLOAD_IMAGE_EVENT](state: IState, url: string) {
    state.imageEventUpload = url;
  },
  [types.SET_FETCHING_UPLOAD_IMAGE_EVENT](state: IState, isFetching: boolean) {
    state.isFetchingUploadImage = isFetching;
  },
  [types.SET_UPLOAD_FILE](state: IState, url: string) {
    state.fileUpload = url;
  },
  [types.SET_FETCHING_UPLOAD_FILE](state: IState, isFetching: boolean) {
    state.isFetchingUploadFile = isFetching;
  },
  [types.CLEAR_IMAGE_EVENT](state: IState) {
    state.imageEventUpload = '';
  },
  [types.DELETE_FAVORITE_PROGRAM](state: IState, programUUID: string) {
    state.eventInfo.eventProgram = state.eventInfo.eventProgram.filter(
      (item) => item.uuid !== programUUID
    );
  },
  updateSpeakersOrder(state: IState, speakers: ISpeakerCard[]) {
    state.eventInfo.eventSpeaker = speakers;
  },
  changeActiveEventFilter(state: IState, filter: 'all' | 'future' | 'past') {
    state.activeEventFilter = filter;
  },
  setAdBanners(state: IState, adBanners: BannerAdsBlockData | null) {
    state.adBanners = adBanners;
  },
  setEventNews(state: IState, news: INewsItem[] | null) {
    state.news = news;
  },
  clearEventInfo(state: IState) {
    state.eventInfo = {} as IEventInfo;
    state.news = null;
  },
};
