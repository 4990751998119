import { $Http } from '@/classes/base';
import { AxiosResponse } from 'axios';

const $MMCOHttp = new $Http({
  _baseURL: process.env.VUE_APP_SOCKET_DOMAIN,
  _headers: {
    'Content-type': 'multipart/form-data',
  },
});
$MMCOHttp.instance.interceptors.response.use((response: AxiosResponse<any>) => {
  return new Promise((resolve, reject) => {
    if (response.status === 200 || response.status === 201) {
      resolve(response.data);
    } else {
      reject(response);
    }
  });
});
export default $MMCOHttp;
