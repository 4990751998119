import { IError } from '@/common/types/errors';
import {
  ICity,
  ICountry,
  IRegion,
  IRegistrationForm,
} from '@/services/AuthService/AuthServiceTypes';

export interface IState {
  isAuthFetching: boolean;
  isLogin: boolean;
  authErrors: IError;
  registrationForm: IRegistrationForm;
  isFieldsExist: boolean;
  isRefreshing: boolean;
  recoverToken: string;
  searchCities: Array<ICity>;
  isCitiesLoading: boolean;
  searchRegions: Array<IRegion>;
  isRegionsLoading: boolean;
  searchCountries: Array<ICountry>;
  isCountriesLoading: boolean;
  searchMunicipalities: Array<string>;
  isMunicipalitiesLoading: boolean;
  listRoles: Array<string>;
}
export default (): IState => ({
  isAuthFetching: false,
  isLogin: false,
  authErrors: {},
  registrationForm: {
    steps: {
      title: '',
      fields: [],
      buttonText: '',
    },
  } as IRegistrationForm,
  isFieldsExist: false,
  isRefreshing: false,
  recoverToken: '',
  searchCities: [],
  isCitiesLoading: false,
  searchRegions: [],
  isRegionsLoading: false,
  searchCountries: [],
  isCountriesLoading: false,
  searchMunicipalities: [],
  isMunicipalitiesLoading: false,
  listRoles: [],
});
