<template>
  <div>
    <div v-if="disabled" class="reference" slot="reference" :style="{ cursor: 'auto' }">
      <slot name="control" />
    </div>

    <Popper
      v-if="!disabled"
      :trigger="trigger"
      :options="{ placement: placement + placementModifier }"
      :disabled="disabled"
    >
      <div class="popper" :style="{ margin: offset }">
        <slot />
      </div>

      <div class="reference" slot="reference" :style="{ cursor: 'pointer' }">
        <slot name="control" />
      </div>
    </Popper>
  </div>
</template>

<script lang="ts">
export default { name: 'UIPopover' };
</script>

<script setup lang="ts">
import Popper from 'vue-popperjs';
import {
  TUIPopoverPlacement,
  TUIPopoverPlacementModifier,
  TUIPopoverTrigger,
} from '@/helpers/types/ui.types';

withDefaults(
  defineProps<{
    // Этот и остальные параметры берутся из https://popper.js.org/docs/v1/
    trigger?: TUIPopoverTrigger;
    placement?: TUIPopoverPlacement;
    placementModifier?: TUIPopoverPlacementModifier;
    offset?: string;
    disabled?: boolean;
  }>(),
  {
    trigger: 'clickToToggle',
    placement: 'auto',
    placementModifier: '',
    offset: '10px',
  }
);
</script>

<style lang="scss">
@import 'vue-popperjs/dist/vue-popper.css';
@import '~@/ui/styles/font/style.scss';

.popper[x-placement^='top'] .popper__arrow {
  border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
}
.popper[x-placement^='bottom'] .popper__arrow {
  border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
}
.popper[x-placement^='left'] .popper__arrow {
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.6);
}
.popper[x-placement^='right'] .popper__arrow {
  border-color: transparent rgba(0, 0, 0, 0.6) transparent transparent;
}
.popper {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(32px);
  box-shadow: none;
  border: none;
  padding: 6px 8px;
  color: #fff;
  font-family: $font-family-Suisse-Intl-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped>
.reference {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
