<template>
  <button
    @click="emitClick"
    class="app-button-icon"
    :class="[buttonSize, buttonStyle]"
    :type="webType"
    :disabled="disabled"
  >
    <app-icon :name="name" :color="color" :colorHover="colorHover" :size="iconSize" />
  </button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { AppIcon } from '@/ui/components/icons/index';
import { TIconName } from '@/ui/components/icons/app-icon/AppIconTypes';
import { TButtonStyle } from '@/ui/components/buttons/ButtonStyleTypes';
import { ButtonWebType, TButtonWebType } from '@/ui/components/buttons/app-button/AppButtonTypes';

export default Vue.extend({
  name: 'AppButtonIcon',
  components: { AppIcon },
  props: {
    name: {
      type: String as PropType<TIconName>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: String,
      default: '#000000',
    },
    colorHover: {
      type: String,
      required: false,
    },
    iconSize: {
      type: [String, Number],
      default: '24',
    },
    buttonSize: {
      type: String,
    },
    buttonStyle: {
      type: String as PropType<TButtonStyle>,
      default: 'primary',
    },
    webType: {
      type: String as PropType<TButtonWebType>,
      default: 'button',
      validator: (value) => Object.values(ButtonWebType).includes(value as ButtonWebType),
    },
  },
  methods: {
    emitClick(): void {
      this.$emit('click');
    },
  },
});
</script>

<style lang="scss">
@use './style.scss';
</style>
