var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowModal)?_c('div',{staticClass:"app-modal-wrap animate",class:{
    'open-modal': _vm.isShowModal,
    'close-modal': !_vm.isShowModal,
    top: _vm.positionModal === 'top',
    center: _vm.positionModal === 'center',
  },on:{"mousedown":_vm.onBackGroundClick}},[_c('div',{staticClass:"modal-main__icon-mobile-container"},[(_vm.$isWindow.small && !_vm.isHideIconClose && !_vm.fullOnMobile)?_c('div',{staticClass:"modal-main__icon-mobile",on:{"click":_vm.toggleModal}},[_c('app-icon',{attrs:{"size":16,"color":"#83888F","name":"close"}})],1):_vm._e()]),_c('div',{staticClass:"modal-main",class:{
      'full-on-mobile': _vm.fullOnMobile,
      'large-size': _vm.size === 'large',
    },on:{"mousedown":_vm.stopPropagation}},[_c('div',{staticStyle:{"margin-top":"-25px"},attrs:{"id":"appModalTop"}}),(_vm.textHead)?_c('div',{staticClass:"modal-main__head",class:{ 'text-left': _vm.isAlignText }},[_c('app-text',{attrs:{"type":_vm.textType,"text":_vm.textHead}})],1):_vm._e(),(!_vm.$isWindow.small && !_vm.isHideIconClose)?_c('div',{staticClass:"modal-main__icon",on:{"click":_vm.toggleModal}},[_c('app-icon',{attrs:{"size":24,"color":"#83888F","name":"close"}})],1):_vm._e(),_vm._t("default")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }