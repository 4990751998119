import clientHttp from '@/utils/custom/http/СlientHttp';
import router from '@/router';

export class StatisticsApi {
  public static async visitEventPage() {
    const slug = router.currentRoute.params.slug ?? '';
    await clientHttp.post(`/events/${slug}/add-visit`);
  }
  public static async visitProgramVideo(programUUID: string) {
    const slug = router.currentRoute.params.slug ?? '';
    await clientHttp.post(`/events/${slug}/add-program-video-visit`, undefined, {
      programUUID,
    });
  }
}
