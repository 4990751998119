<template>
  <div class="app-select-with-button">
    <slot name="label">
      <label v-if="label" class="app-select-with-button__label">
        <app-text :text="label" type="primary-book" />
      </label>
    </slot>
    <button
      class="selected-block"
      :class="{ active: isShowList, error: errors.length }"
      @click="toggleIsShowList"
    >
      <div class="selected-block__container">
        <div
          v-if="selectedValue && selectedValue?.[colorKey]"
          :style="`background-color:${selectedValue?.[colorKey]}`"
          class="app-select-with-button__color"
        />
        <app-text
          v-if="selectedValue"
          class="selected-block__name"
          type="main"
          :text="selectedValue?.[listKey]"
        />
        <app-text v-else :color="'secondary'" :text="titleText" type="main" />
      </div>
      <app-icon
        class="app-select-with-button__chevron-down"
        name="chevron-down"
        :class="{ 'app-select-wrap__chevron-down_active': isShowList }"
      />
    </button>

    <div
      v-if="isShowList"
      class="select-list"
      :class="{ scroll: items.length > 7 }"
      v-click-outside="closeList"
    >
      <button
        v-for="(item, index) in items"
        :key="index"
        class="select-list__item"
        :class="{
          active: item[listKey] === selectedValue?.[listKey],
        }"
        @click="changeActiveItem($event, item)"
      >
        <div class="app-select-with-button__color-container">
          <div
            v-if="item[colorKey]"
            class="app-select-with-button__color"
            :style="`background-color:${item[colorKey]}`"
          />
          <app-text :text="item[listKey]" />
        </div>

        <app-button-icon id="delete-button" name="x" icon-size="14" @click="deleteElement(item)" />
      </button>
      <app-button
        class="app-select-with-button__add-track"
        :text="buttonTitle"
        font-type="primary-book"
        @click="addElement"
      />
    </div>
    <span v-if="!!errors.length && isHaveBottomText" class="app-input__error">
      <app-text
        v-for="(err, i) in errors"
        :key="err"
        :text="`${err}${i < errors.length - 1 ? '.' : ''}`"
        type="small-book"
        :color="'error'"
      />
    </span>

    <div v-else-if="isHaveEmptyBlock" class="app-select-with-button__empty" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { AppButton, AppText, AppButtonIcon } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';

export default Vue.extend({
  components: {
    AppText,
    AppIcon,
    AppButtonIcon,
    AppButton,
  },
  props: {
    selectedValue: {
      type: Object,
      required: false,
    },
    buttonTitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    listKey: {
      type: String,
      required: true,
    },
    colorKey: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isHaveBottomText: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHaveEmptyBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isShowList: false,
    };
  },
  computed: {
    getTitle() {
      let title = '';
      if (Object.keys(this.selectedValue)?.length) {
        title = this.selectedValue[this.listKey];
      }
      return title || this.titleText;
    },
  },
  methods: {
    toggleIsShowList(): void {
      if (!this.disabled) {
        this.isShowList = !this.isShowList;
      }
    },
    changeActiveItem(event: Event, value: ISelectItem): void {
      // Обрабатываем клик по крестику, чтобы не выбиралось при удалении
      const target = event.target as HTMLElement;
      if (target.classList.contains('icon-x')) return;

      if (this.selectedValue?.[this.listKey] == value[this.listKey]) {
        this.$emit('onSelect', null);
      } else {
        this.$emit('onSelect', value);
      }
      this.closeList();
    },
    closeList(): void {
      this.isShowList = false;
    },
    addElement() {
      this.$emit('addElement');
    },
    deleteElement(value: ISelectItem) {
      if (this.selectedValue?.[this.listKey] == value[this.listKey]) {
        this.$emit('onSelect', null);
      }
      this.$emit('onDelete', value);
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
