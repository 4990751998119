<template>
  <div class="slider">
    <div class="slider__show-value">
      <app-text :text="getScaleText" color="secondary" type="small" />
    </div>
    <div class="slider__input-wrapper" ref="verticalSliderWrapper">
      <div class="slider__input-filler"></div>
      <div
        class="slider__input-lower-filler"
        :class="{
          light: light,
        }"
      ></div>
      <input
        type="range"
        id="slider"
        ref="verticalSliderInput"
        v-model="scale"
        :min="min"
        :max="max"
        :step="step"
        @input="input"
      />
    </div>
    <div for="slider" class="slider__icon"><app-icon name="search" :size="iconSize" /></div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { AppIcon } from '../../icons';
import { AppText } from '../../text';

interface InputEvent extends Event {
  target: HTMLInputElement;
}
export default Vue.extend({
  name: 'AppInputSlider',
  components: {
    AppIcon,
    AppText,
  },
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 3,
    },
    step: {
      type: Number,
      default: 0.02,
    },
    height: {
      type: Number,
      default: 300,
    },
    iconSize: {
      type: String,
      default: '16',
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialScale: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    input(event: Event) {
      const payload = event as InputEvent;
      const value = Number(payload.target?.value);
      const input = this.$refs.verticalSliderInput as HTMLInputElement;
      const sliderWrapper = this.$refs.verticalSliderWrapper as HTMLDivElement;
      const ratio =
        (Number(input.value) - Number(input.min)) / (Number(input.max) - Number(input.min)) + '';
      sliderWrapper.style.setProperty('--ratio', ratio);
      this.$emit('onInput', value);
    },
  },
  computed: {
    getScaleText(): string {
      let num = Number(this.scale);
      let fixed = num.toFixed(2);
      return `${fixed} x`;
    },
    getInputHeight(): string {
      const scaleValueHeight = 35;
      const gap = 10;
      return this.height - scaleValueHeight - Number(this.iconSize) - 2 * gap + 'px';
    },
  },
  data() {
    return {
      scale: this.initialScale,
    };
  },
  mounted() {
    const sliderWrapper = this.$refs.verticalSliderWrapper as HTMLDivElement;
    sliderWrapper.style.setProperty('--vertical-slider-height', this.getInputHeight);
  },
});
</script>
<style lang="scss">
@import 'style';

.slider__input-lower-filler.light {
  background: white;
}
</style>
