<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Открытие регистрации"
    type="thirdTitle"
    :is-align-text="true"
  >
    <div class="app-modal-pick-date-registration">
      <div class="app-modal-pick-date-registration__date">
        <app-calendar-range
          :is-have-title-and-subtitle="false"
          :default-end-date="`${
            eventInfo?.registrationEndDate
              ? `${eventInfo?.registrationEndDate.split('T')[0]}T12:00:00.000Z`
              : ''
          }`"
          :default-start-date="`${
            eventInfo?.registrationStartDate
              ? `${eventInfo?.registrationStartDate.split('T')[0]}T12:00:00.000Z`
              : ''
          }`"
          @getRangeDay="onDayClick"
        />
        <div>
          <app-text text="* Время начала и окончания" type="primary-book" />
          <div class="app-modal-pick-date-registration__calendar-block-time">
            <div class="app-modal-pick-date-registration__time-container">
              <app-input
                v-model="inputValueTimeStart"
                class="app-modal-pick-date-registration__time-input"
                @onInput="onInputTimeStart"
                :is-have-bottom-text-error="false"
                :is-have-empty-block="false"
                placeholder="c 12:00"
                type="clock"
                :maxlength="5"
              />
              <app-input
                v-model="inputValueTimeEnd"
                class="app-modal-pick-date-registration__time-input"
                @onInput="onInputTimeEnd"
                :is-have-bottom-text-error="false"
                :is-have-empty-block="false"
                placeholder="до 12:00"
                type="clock"
                :maxlength="5"
              />
            </div>
          </div>
          <app-text
            type="secondary"
            color="secondary"
            tag="p"
            style="margin-top: 5px"
            :text="`Часовой пояс события: ${this.eventInfo.originalTimezone.slice(0, 3)}`"
          />
        </div>
      </div>
      <div class="app-modal-pick-date-registration__input">
        <app-input
          label="Целевое количество участников"
          placeholder="Введите количество"
          :value="inputValueTargetNumOfParticipants"
          @onInput="changeTargetNumOfParticipants"
          type="numeric"
        />
      </div>
      <div class="app-modal-pick-date-registration__buttons">
        <app-button text="Отменить" type="secondary" @click="cancel" />
        <app-button text="Сохранить" @click="onSave" />
      </div>
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import AppCalendarRange from '@/ui/components/calendars/app-calendar-range/AppCalendarRange.vue';
import { AppInput, AppText, AppButton } from '@/ui/components';
import { mapActions, mapState } from 'vuex';
import { EX_$Toaster } from '@/classes/content';
export default Vue.extend({
  components: {
    AppModal,
    AppCalendarRange,
    AppInput,
    AppText,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      inputValueTimeStart: '',
      inputValueTimeEnd: '',
      inputValueTargetNumOfParticipants: '',
      selectedDate: {} as { startDate: string; endDate: string },
    };
  },
  methods: {
    ...mapActions('event', ['AGetEventInfo']),
    ...mapActions('admin', ['APatchEventChange']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    onInputTimeStart(value: string) {
      this.inputValueTimeStart = value;
    },
    onInputTimeEnd(value: string) {
      this.inputValueTimeEnd = value;
    },
    changeTargetNumOfParticipants(value: string) {
      this.inputValueTargetNumOfParticipants = value;
    },
    cancel() {
      this.toggleModal();
    },
    async onSave() {
      const startDate = `${this.selectedDate.startDate.substr(0, 11)}${
        this.inputValueTimeStart
      }:00${this.eventInfo.originalTimezone || '.000Z'}`;
      const endDate = `${this.selectedDate.endDate.substr(0, 11)}${this.inputValueTimeEnd}:00${
        this.eventInfo.originalTimezone || '.000Z'
      }`;
      if (
        Date.parse(startDate) <= Date.parse(this.eventInfo?.endDate) &&
        Date.parse(startDate) < Date.parse(endDate)
      ) {
        await this.APatchEventChange({
          uuid: this.eventInfo.uuid,
          registrationStartDate: startDate,
          endDate: this.eventInfo?.endDate,
          registrationEndDate: endDate,
          targetNumOfParticipants: +this.inputValueTargetNumOfParticipants,
        });
        await this.AGetEventInfo(this.$route.params.slug);
        this.$emit('onCheckOpenRegistration');
        this.toggleModal();
      } else {
        this.EX_Toaster.error('Неправильная дата');
      }
    },
    onDayClick(range: any) {
      this.inputValueTimeStart = '';
      this.inputValueTimeEnd = '';
      this.selectedDate = range;
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
  },
  mounted() {
    this.inputValueTimeStart = this.eventInfo?.registrationStartDate.split('T')[1].slice(0, 5);
    this.inputValueTimeEnd = this.eventInfo?.registrationEndDate.split('T')[1].slice(0, 5);
    this.selectedDate = {
      startDate: `${this.eventInfo?.registrationStartDate.split('T')[0]}T`,
      endDate: `${this.eventInfo?.registrationEndDate.split('T')[0]}T`,
    };
    this.inputValueTargetNumOfParticipants = this.eventInfo?.targetNumOfParticipants;
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.app-modal-pick-date-registration {
  .vc-pane-layout {
    background: none !important;
    border-bottom: none !important;
    width: 320px;
  }
  .app-calendar-range__border {
    background: none !important;
  }
  .app-modal-pick-date-registration__time-input {
    .app-input__inner {
      &_input {
        background-color: #ffffff !important;
      }
      background: #ffffff !important;
      padding: 7px 12px;
    }
  }
}
</style>
