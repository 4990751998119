<template>
  <a
    :href="navItem.url"
    target="_blank"
    class="box-border appearance-none select-none text-sm/3 text-black-50 hover:text-blue active:text-blue visited:text-black-50"
    :style="{ fontWeight }"
  >
    {{ navItem.name }}
  </a>
</template>

<script lang="ts">
export default { name: 'NavItem' };
</script>

<script setup lang="ts">
import { LayoutNavItem } from '@/helpers/types/layouts.types';

defineProps<{
  navItem: LayoutNavItem;
  fontWeight: string;
}>();
</script>

<style scoped lang="scss"></style>
