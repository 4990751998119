type TDate =
  | 'onlyDate'
  | 'onlyTime'
  | 'fullYear'
  | 'fullMonth'
  | 'onlyDayAndMonth'
  | 'onlyDay'
  | 'onlyMonth'
  | 'onlyYear'
  | 'dateFullMonth'
  | 'dateFullYear'
  | 'onlyDefaultMonth'
  | 'dayOfWeek'
  | 'onlyHour'
  | 'onlyMinutes';

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сeнтября',
  'октября',
  'ноября',
  'декабря',
];
const defaultMonth = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сeнтябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];
const daysWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const formattedDate = function (date: string, type?: TDate, UTC0?: boolean): string {
  const tmpDate = new Date(date);
  //01.01.23
  let onlyDate = '';
  //01.01
  let onlyDayAndMonth = '';
  //17:00
  let onlyTime = '';
  //01.01.2023
  let dateFullYear = '';
  //5 мая
  let dateFullMonth = '';
  // 5
  let onlyDay = '';
  //января
  let onlyMonth = '';
  //май
  let onlyDefaultMonth = '';
  //2023
  let onlyYear = '';
  //Пн
  let dayOfWeek = '';
  //07
  let onlyHour = '';
  //20
  let onlyMinutes = '';
  if (UTC0) {
    onlyDate = `${`0${tmpDate.getUTCDate()}`.slice(-2)}.${`0${tmpDate.getUTCMonth() + 1}`.slice(
      -2
    )}.${`0${tmpDate.getUTCFullYear()}`.slice(-2)}`;

    onlyDayAndMonth = `${`0${tmpDate.getUTCDate()}`.slice(-2)}.${`0${
      tmpDate.getUTCMonth() + 1
    }`.slice(-2)}`;

    onlyTime = `${`0${tmpDate.getUTCHours()}`.slice(-2)}:${`0${tmpDate.getUTCMinutes()}`.slice(
      -2
    )}`;

    dateFullYear = `${`0${tmpDate.getUTCDate()}`.slice(-2)}.${`0${tmpDate.getUTCMonth() + 1}`.slice(
      -2
    )}.${`${tmpDate.getUTCFullYear()}`}`;

    dateFullMonth = `${tmpDate.getUTCDate()} ${months[tmpDate.getUTCMonth()]}`;

    onlyDay = tmpDate.getUTCDate().toString();

    onlyMonth = `${months[tmpDate.getUTCMonth()]}`;

    onlyYear = `${tmpDate.getUTCFullYear()}`;

    dayOfWeek = `${daysWeek[tmpDate.getUTCDay()]}`;

    onlyHour = `0${tmpDate.getUTCHours()}`.slice(-2);

    onlyMinutes = `0${tmpDate.getUTCMinutes()}`.slice(-2);
  } else {
    onlyDate = `${`0${tmpDate.getDate()}`.slice(-2)}.${`0${tmpDate.getMonth() + 1}`.slice(
      -2
    )}.${`0${tmpDate.getFullYear()}`.slice(-2)}`;

    onlyDayAndMonth = `${`0${tmpDate.getDate()}`.slice(-2)}.${`0${tmpDate.getMonth() + 1}`.slice(
      -2
    )}`;

    onlyTime = `${`0${tmpDate.getHours()}`.slice(-2)}:${`0${tmpDate.getMinutes()}`.slice(-2)}`;

    dateFullYear = `${`0${tmpDate.getDate()}`.slice(-2)}.${`0${tmpDate.getMonth() + 1}`.slice(
      -2
    )}.${`${tmpDate.getFullYear()}`}`;

    dateFullMonth = `${tmpDate.getDate()} ${months[tmpDate.getMonth()]}`;

    onlyDay = tmpDate.getDate().toString();

    onlyMonth = `${months[tmpDate.getMonth()]}`;

    onlyDefaultMonth = `${defaultMonth[tmpDate.getMonth()]}`;

    onlyYear = `${tmpDate.getFullYear()}`;

    dayOfWeek = `${daysWeek[tmpDate.getDay()]}`;

    onlyHour = `0${tmpDate.getHours()}`.slice(-2);

    onlyMinutes = `0${tmpDate.getMinutes()}`.slice(-2);
  }

  switch (type) {
    case 'onlyDate':
      return onlyDate;
    case 'onlyTime':
      return onlyTime;
    case 'fullYear':
      return `${dateFullYear} ${onlyTime}`;
    case 'fullMonth':
      return dateFullMonth;
    case 'onlyDayAndMonth':
      return onlyDayAndMonth;
    case 'onlyDay':
      return onlyDay;
    case 'onlyMonth':
      return onlyMonth;
    case 'onlyYear':
      return onlyYear;
    case 'dateFullMonth':
      return dateFullMonth;
    case 'dateFullYear':
      return dateFullYear;
    case 'onlyDefaultMonth':
      return onlyDefaultMonth;
    case 'dayOfWeek':
      return dayOfWeek;
    case 'onlyHour':
      return onlyHour;
    case 'onlyMinutes':
      return onlyMinutes;
    default:
      return `${onlyDate} ${onlyTime}`;
  }
};

export const getRangeDate = function (dateStart: string, dateEnd: string, UTC0?: boolean): string {
  const dayStart = formattedDate(dateStart, 'onlyDay', UTC0);
  const dayEnd = formattedDate(dateEnd, 'onlyDay', UTC0);
  const monthStart = formattedDate(dateStart, 'onlyMonth', UTC0);
  const monthEnd = formattedDate(dateEnd, 'onlyMonth', UTC0);
  if (monthStart === monthEnd) {
    if (dayStart === dayEnd) {
      return `${dayStart} ${monthStart}`;
    } else {
      return `${dayStart} - ${dayEnd} ${monthStart}`;
    }
  } else {
    return `${dayStart} ${monthStart} - ${dayEnd} ${monthEnd}`;
  }
};
export const getRangeTime = function (dateStart: string, dateEnd: string, UTC0?: boolean): string {
  const timeStart = formattedDate(dateStart, 'onlyTime', UTC0);
  const timeEnd = formattedDate(dateEnd, 'onlyTime', UTC0);
  return `${timeStart} - ${timeEnd}`;
};
/**
 * @description Возвращает день месяц и время
 * @param dateStart
 * @param dateEnd
 * @param UTC0
 */
export const getRangeDateAndTime = function (dateStart: string, dateEnd: string, UTC0?: boolean) {
  const dayStart = formattedDate(dateStart, 'onlyDay', UTC0);
  const dayEnd = formattedDate(dateEnd, 'onlyDay', UTC0);
  const monthStart = formattedDate(dateStart, 'onlyMonth', UTC0);
  const monthEnd = formattedDate(dateEnd, 'onlyMonth', UTC0);
  const timeStart = formattedDate(dateStart, 'onlyTime', UTC0);
  const timeEnd = formattedDate(dateEnd, 'onlyTime', UTC0);
  if (monthStart === monthEnd) {
    if (dayStart === dayEnd) {
      return `${dayStart} ${monthStart} ${timeStart} - ${timeEnd}`;
    } else {
      return `${dayStart} - ${dayEnd} ${monthStart} ${timeStart} - ${timeEnd}`;
    }
  } else {
    return `${dayStart} ${monthStart} - ${dayEnd} ${monthEnd} ${timeStart} - ${timeEnd}`;
  }
};
