import { $Http, EX_$Cookies, EX_$Observer, EX_$TokensAuth } from '@/classes/base';
import EX_TokensAuth from '@/classes/base/token-cookies';
import { AxiosResponse } from 'axios';
import { sleep } from '@/utils/custom';
import { get } from 'cross-domain-cookie';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { Fingerprint } from '@/helpers/classes/fingerprint.class';

interface IUpdateAccessTokenRequest {
  accessToken: string;
  refreshToken: string;
}

const getAccessToken = (): string => EX_TokensAuth.getAccessToken();
const setAccessToken = (accessToken: string): void => EX_TokensAuth.setAccessToken(accessToken);
const setRefreshToken = (refreshToken: string): void => EX_TokensAuth.setRefreshToken(refreshToken);
const setRoleInfo = (accessToken: string): void => EX_$TokensAuth.setRoleInfo(accessToken);
const updateAccessToken = (refreshToken: string): Promise<IUpdateAccessTokenRequest> =>
  EX_$Observer.context.$store.dispatch('auth/ARefresh', refreshToken);
const updateIsLogin = (isLogin: boolean): Promise<void> =>
  EX_$Observer.context.$store.dispatch('auth/ASetIsLogin', isLogin);
const updateIsRefreshing = (isRefreshing: boolean): Promise<void> =>
  EX_$Observer.context.$store.dispatch('auth/ASetIsRefreshing', isRefreshing);

let isTokenUpdating = false;
let isExpireRefresh = false;
let isLogout = false;

const _baseURL =
  process.env.NODE_ENV !== 'development'
    ? window.location.origin + '/api'
    : process.env.VUE_APP_API_DOMAIN;

const lang =
  process.env.VUE_APP_ENV_STATE === 'PRODUCTION' ? localStorage.getItem('lang') || 'ru' : undefined;
const mmcoClient = process.env.VUE_APP_HARDCODED_MMCO_CLIENT
  ? process.env.VUE_APP_HARDCODED_MMCO_CLIENT
  : undefined;

const $HttpClient = new $Http({
  _baseURL,
  _headers: {
    'Content-type': 'application/json',
    'Accept-Language': lang,
    'x-forward-url': document.URL,
    'Mmco-Client': mmcoClient,
  },
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  if (config.url.endsWith('export')) {
    config['responseType'] = 'blob';
    config.headers['accept'] = 'text/csv';
  }
  return config;
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  config.headers['x-user-fp'] = Fingerprint.get();
  return config;
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  config.headers['accept-language'] = lang;
  const isAccessToken = !!getAccessToken();
  if (isAccessToken) {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  }
  return config;
});

$HttpClient.instance.interceptors.response.use((response: AxiosResponse<any>) => {
  return new Promise((resolve, reject) => {
    if (response.status === 200 || response.status === 201) {
      isExpireRefresh = false;
      isTokenUpdating = false;
      resolve(response.data);
    } else {
      reject(response);
    }
  });
});

$HttpClient.instance.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalConfig = error.config;

    // Если рефреш токенов не получился, то удаляем заголовок
    if (isExpireRefresh) {
      delete originalConfig.headers['Authorization'];
    }

    if (error.response?.status === 500 && !isLogout) {
      EX_$Observer.context.$toastr.error('Что-то пошло не так. Повторите попытку позже');
    }

    if (error.response?.status === 401 && !isLogout && !!getAccessToken()) {
      // eslint-disable-next-line
      let currentRefreshToken = {
        refreshToken: '',
      };
      if (process.env.VUE_APP_ENV_STATE != 'LOCAL') {
        currentRefreshToken = await get({
          iframeUrl: process.env.VUE_APP_AUTH_DOMAIN,
          dataKey: 'refreshToken',
        });
      } else {
        currentRefreshToken.refreshToken = EX_$Cookies.get('refreshToken');
      }
      if (isExpireRefresh) {
        isLogout = true;
        return;
      } else {
        if (isTokenUpdating) {
          await sleep(500);
          // return $HttpClient.instance(originalConfig);
        } else {
          if (document.cookie.includes('accessToken')) {
            isTokenUpdating = true;
            updateIsRefreshing(true);
            await updateAccessToken(currentRefreshToken.refreshToken)
              .then(async (res: IUpdateAccessTokenRequest) => {
                await setAccessToken(res.accessToken);
                await setRoleInfo(res.accessToken);
                await setRefreshToken(res.refreshToken);
                await updateIsLogin(true);
                useAuthStore().setIsLoggedIn(true);
                // return $HttpClient.instance(originalConfig);
              })
              .catch(() => {
                isExpireRefresh = true;
                EX_TokensAuth.removeCookiesTokens();
                if (EX_$Observer.context.$route.meta?.requiresAuth)
                  EX_$Observer.context.$router.push('/');
              })
              .finally(() => {
                updateIsRefreshing(false);
              });
          }
        }
        return $HttpClient.instance(originalConfig);
      }
    }
    return Promise.reject(error);
  }
);

export default $HttpClient;
