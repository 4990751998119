import clientHttp from '@/utils/custom/http/СlientHttp';
import {
  ICreateReferralLinkRequestData,
  IEditReferralLinkRequestData,
  IEventUsersResponseData,
  IExportUser,
  IInvitedUsersResponseData,
  IInviteUser,
  IReferralLink,
  IReferralLinksRequestParams,
  IUsersInfoRequestParams,
} from '@/services/UserManagementService/UserManagementServiceTypes';
import { IProfile } from '@/services/ProfileService/ProfileServiceTypes';
import {
  TRegistrationPerDaySelected,
  TUserManagementFullStatisticsRegistrationPerDayResponse,
  TUserManagementFullStatisticsResponse,
} from '@/helpers/types/admin/user-management.types';

export class UserManagementApi {
  public static getEventUsers(params: IUsersInfoRequestParams): Promise<IEventUsersResponseData> {
    const payload: IUsersInfoRequestParams = {
      limit: params.limit,
      page: params.page,
    };
    if (params.search) {
      payload['search'] = params.search;
    }
    return clientHttp.get(
      `/events/${params.eventUUID}/event-user-management`,
      payload
    ) as Promise<IEventUsersResponseData>;
  }
  public static getInvitedUsers(
    params: IUsersInfoRequestParams
  ): Promise<IInvitedUsersResponseData> {
    const payload: IUsersInfoRequestParams = {
      limit: params.limit,
      page: params.page,
    };
    if (params.search) {
      payload['search'] = params.search;
    }
    return clientHttp.get(
      `/events/${params.eventUUID}/event-user-management/invitations`,
      payload
    ) as Promise<IInvitedUsersResponseData>;
  }
  public static exportEventUsers(params: IExportUser): Promise<Blob> {
    return clientHttp.get(`/events/${params.eventUUID}/event-user-management/participants/export`);
  }
  public static exportInvitedUsers(params: IExportUser): Promise<Blob> {
    return clientHttp.get(`/events/${params.eventUUID}/event-user-management/invitations/export`);
  }
  public static inviteUser(data: IInviteUser) {
    return clientHttp.post(`/event/user-invitation/invite`, data);
  }
  public static getReferralLinks(params: IReferralLinksRequestParams): Promise<IReferralLink[]> {
    return clientHttp.get(`/event/${params.eventUUID}/referral/source`);
  }
  public static createReferralLink(data: ICreateReferralLinkRequestData): Promise<IReferralLink> {
    return clientHttp.post(`/event/${data.eventUUID}/referral/source`, { source: data.source });
  }
  public static editReferralLinks(data: IEditReferralLinkRequestData): Promise<void> {
    return clientHttp.patch(`/event/${data.eventUUID}/referral/source`, { sources: data.sources });
  }
  public static getUserProfile(params: { eventUUID: string; userUUID: string }): Promise<IProfile> {
    return clientHttp.get(
      `/events/${params.eventUUID}/event-user-management/${params.userUUID}/profile`
    );
  }
  public static exportEventVisitors(slug: string): Promise<Blob> {
    return clientHttp.get(`/events/${slug}/visitors`);
  }
  public static getEventFullStatistics(
    slug: string
  ): Promise<TUserManagementFullStatisticsResponse> {
    return clientHttp.get(`/events/${slug}/full-stats`);
  }
  public static exportFullStatisticsTrafficSourceConversion(slug: string): Promise<Blob> {
    return clientHttp.get(`/events/${slug}/traffic`);
  }
  public static exportFullStatisticsReferralLinksConversion(slug: string): Promise<Blob> {
    return clientHttp.get(`/events/${slug}/referral`);
  }
  public static getEventFullStatisticsRegistrationsPerDay(
    slug: string,
    selected: TRegistrationPerDaySelected
  ): Promise<TUserManagementFullStatisticsRegistrationPerDayResponse> {
    return clientHttp.get(`/events/${slug}/dynamics`, {
      startDate: selected.start.format('YYYY-MM-DD'),
      endDate: selected.end.format('YYYY-MM-DD'),
    });
  }
  public static createFlag(
    slug: string,
    data: {
      name: string;
      checkedFlagNote: string;
      uncheckedFlagNote: string;
    }
  ) {
    return clientHttp.post(`/event/${slug}/flags`, data);
  }
  public static toggleFlag(
    params: {
      slug: string;
      flagUUID: string;
    },
    data: { eventUserUUID: string; value: boolean }
  ) {
    return clientHttp.put(`/event/${params.slug}/flags/${params.flagUUID}/`, data);
  }
}
