<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Запись мероприятия"
  >
    <div class="app-modal-program-record">
      <app-input
        placeholder="Вставьте ссылку на диск"
        @onInput="onInputRecordLinkInputValue"
        :errors="errors"
      >
        <template #prepend>
          <app-icon name="link" color="#83888F" />
        </template>
      </app-input>
      <app-button text="Сохранить" @click="saveRecordLink" />
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppInput, AppButton } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  components: {
    AppModal,
    AppInput,
    AppButton,
    AppIcon,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      recordLinkInputValue: '',
      errors: [] as Array<string>,
    };
  },
  methods: {
    ...mapActions('admin', ['ARedactorProgram', 'AGetAdminPrograms']),
    ...mapActions('program', ['AGetProgramInfo']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    onInputRecordLinkInputValue(value: string) {
      this.recordLinkInputValue = value;
    },
    async saveRecordLink() {
      const RegExp =
        /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-/])*)?/;
      if (RegExp.test(this.recordLinkInputValue.trim())) {
        await this.ARedactorProgram({
          eventUUID: this.eventInfo.uuid,
          uuid: this.selectedProgramId,
          recordedStreamLink: this.recordLinkInputValue,
        });
        await this.AGetAdminPrograms({
          eventUUID: this.eventInfo.uuid,
        });
        this.toggleModal();
      } else {
        this.errors = ['Неверный формат ссылки'];
      }
    },
  },
  computed: {
    ...mapState('admin', ['selectedProgramId']),
    ...mapState('program', ['programInfo']),
    ...mapState('event', ['eventInfo']),
  },
  async mounted() {
    await this.AGetProgramInfo({
      eventUUID: this.$route.params.slug,
      programUUID: this.selectedProgramId,
    });
    this.recordLinkInputValue = this.programInfo?.recordedStreamLink;
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
