import {
  IAddress,
  IChangeEvent,
  IChangeProgram,
  IChangeProgramsTracksOrAddress,
  ICreateEventRequestData,
  ICreateProgramRequestData,
  IEditBlocksEvent,
  IGetAllEventsInfoRequestParams,
  IProgramByAddress,
  IProgramByTrack,
  IRedactorProgram,
  ITrack,
} from '@/services/AdminService/AdminServiceTypes';
import clientHttp from '@/utils/custom/http/СlientHttp';
import { IEvent, ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import MMCOHttp from '@/utils/custom/http/MMCOHttp';
import { IEventUsersStatisticsResponse } from '@/helpers/types/admin/user-management.types';

export const patchEventChange = (data: IChangeEvent) => {
  return clientHttp.patch(`/event/${data.uuid}`, data);
};
export const GetAllEvents = (params: IGetAllEventsInfoRequestParams) => {
  const payload = {
    limit: params.limit,
    page: params.page,
  } as IGetAllEventsInfoRequestParams;
  if (params.search) {
    payload['search'] = params.search;
  }
  if (params.timeStatus) {
    payload['timeStatus'] = params.timeStatus;
  }
  if (params.isDeleted) {
    payload['isDeleted'] = params.isDeleted;
  }
  if (typeof params.isPublished !== null) {
    payload['isPublished'] = params.isPublished;
  }
  return clientHttp.get(`/event/admin/filter`, payload);
};

export const editBlocksEvent = (data: IEditBlocksEvent): Promise<IEvent> => {
  return clientHttp.patch(`/events/${data.uuid}/module-block`, { blocks: data.blocks });
};

export const createEvent = (data: ICreateEventRequestData): Promise<IEvent> => {
  return clientHttp.post(`/event`, data);
};
export const createProgram = (data: ICreateProgramRequestData) => {
  return clientHttp.post(`events/${data.eventUUID}/programs`, data);
};

export const getAllAddress = (eventUUID: string) => {
  return clientHttp.get(`/events/${eventUUID}/address`);
};
export const getAllTracks = (eventUUID: string): Promise<ITrack[]> => {
  return clientHttp.get(`/events/${eventUUID}/tracks`);
};
export const searchSpeakers = (search: string): Promise<ISpeakerCard[]> => {
  return clientHttp.get(`/speakers`, { search: search ? search : undefined });
};
export const createTrack = (data: ITrack) => {
  return clientHttp.post(`/events/${data.eventUUID}/tracks`, data);
};
export const createAddress = (data: { eventUUID: string; name: string }) => {
  return clientHttp.post(`/events/${data.eventUUID}/address`, data);
};

export const getAdminPrograms = (params: { eventUUID: string; search: string }) => {
  return clientHttp.get(
    `/events/${params.eventUUID}/programs/admin/all${
      params.search ? `?search=${params.search}` : ''
    }`
  );
};

export const getAdminDeletedPrograms = (params: { eventUUID: string; search: string }) => {
  return clientHttp.get(
    `/events/${params.eventUUID}/programs/admin/deleted${
      params.search ? `?search=${params.search}` : ''
    }`
  );
};
export const getProgramsByTrack = (params: IProgramByTrack) => {
  return clientHttp.get(`/events/${params.eventUUID}/programs/tracks/${params.uuid}`);
};
export const getProgramsByAddress = (params: { eventUUID: string; uuid: string }) => {
  return clientHttp.get(`/events/${params.eventUUID}/programs/address/${params.uuid}`);
};
export const redactorProgram = (data: IChangeProgram) => {
  const payload = { ...data };
  delete payload.eventUUID;
  delete payload.uuid;
  return clientHttp.patch(`/events/${data.eventUUID}/programs/${data.uuid}`, payload);
};
export const redactorTracksOrAddressOfProgram = (data: IChangeProgramsTracksOrAddress) => {
  return clientHttp.patch(`/events/${data.eventUUID}/programs/update/track-or-address`, data);
};
export const deleteTrack = (data: { eventUUID?: string; uuid?: string }) => {
  return clientHttp.delete(`/events/${data.eventUUID}/tracks/${data.uuid}`);
};
export const deleteAddress = (data: { eventUUID: string; uuid: string }) => {
  return clientHttp.delete(`/events/${data.eventUUID}/address/${data.uuid}`);
};
export const AUpdateProgram = (data: IRedactorProgram) => {
  const payload = { ...data };
  delete payload.eventUUID;
  delete payload.programUUID;
  return clientHttp.patch(`/events/${data.eventUUID}/programs/${data.programUUID}/add`, payload);
};
export const AGetAvailableFields = () => {
  return clientHttp.get(`/event/registration-form/available-fields`);
};
export const uploadAll = (formData: FormData, params?: object): Promise<{ url: string }> => {
  return MMCOHttp.postFile(`upload/all`, formData, params);
};
export const deleteProgram = (params: { programUUID: string; eventUUID: string }) => {
  return clientHttp.delete(`/events/${params.eventUUID}/programs/${params.programUUID}`);
};

export const recoverProgram = (params: {
  eventUUID: string;
  programUUID: string;
  dates?: { startDate: string; endDate: string };
}) => {
  return clientHttp.patch(
    `/events/${params.eventUUID}/programs/${params.programUUID}/deleted/recover`,
    {
      startDate: params.dates?.startDate,
      endDate: params.dates?.endDate,
    }
  );
};
export const checkIfEventExist = (params: { title: string; slug: string }) => {
  return clientHttp.get(
    `/event/admin/check/existence?${params.title ? `title=${params.title}&` : ''}${
      params.slug ? `slug=${params.slug}` : ''
    }`
  );
};

export const getUserManagementStatistics = async (
  slug: string
): Promise<IEventUsersStatisticsResponse> => {
  return clientHttp.get(`/events/${slug}/analytics`);
};
