import { cloneDeep } from 'lodash';

export function moveItem<T>(array: T[], index: number, direction: 'forward' | 'backward'): T[] {
  const newArray = cloneDeep(array);

  // Проверяем допустимые значения индекса и направления
  if (index < 0 || index >= newArray.length) throw new Error('Invalid index');
  if (direction !== 'forward' && direction !== 'backward') throw new Error('Invalid direction');

  // Перемещаем элемент вперед
  if (direction === 'forward' && index < newArray.length - 1) {
    const temp = newArray[index];
    newArray[index] = newArray[index + 1];
    newArray[index + 1] = temp;
  }
  // Перемещаем элемент назад
  if (direction === 'backward' && index > 0) {
    const temp = newArray[index];
    newArray[index] = newArray[index - 1];
    newArray[index - 1] = temp;
  }

  return newArray;
}

export function moveItemsByCondition<T>(
  array: T[],
  condition: (element: T) => boolean,
  where: 'start' | 'end',
  sortConditional?: (a: T, b: T) => number
): T[] {
  let conditionalElements: T[] = [];

  const filteredArray = array.filter((item) => {
    const fitsCondition = condition(item);
    if (fitsCondition) {
      conditionalElements.push(item);
    }
    // Возвращаем те элементы, которые не подходят под условие
    return !fitsCondition;
  });

  if (sortConditional) {
    conditionalElements = conditionalElements.sort(sortConditional);
  }

  switch (where) {
    case 'start':
      return [...conditionalElements, ...filteredArray];
    case 'end':
      return [...filteredArray, ...conditionalElements];
  }
}
