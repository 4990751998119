<template>
  <AppModal v-if="isShowModal" :is-show-modal="isShowModal" @toggleModal="toggleModal">
    <div class="app-modal-recognized">
      <div v-if="isHaveAvatar" class="app-modal-recognized__avatar">
        <img src="@/assets/images/PersonalAccount/Avatar.png" alt="Avatar" />
        <div class="app-modal-recognized__avatar-icon">
          <img src="@/assets/images/PersonalAccount/hand.png" alt="Hand" />
        </div>
      </div>
      <div v-if="!isHaveAvatar" class="app-modal-recognized__welcome">
        <div class="app-modal-recognized__welcome-icon">
          <img src="@/assets/images/PersonalAccount/hand-svg.svg" alt="Hand" />
        </div>
      </div>
      <app-text class="app-modal-recognized__title" text="Мы вас узнали!" :type="'title'" />
      <app-text
        class="app-modal-recognized__subtitle"
        text="Вы уже зарегистрированы в Пространстве событий ММСО на платформе Коннект. Мы
    можем скопировать ваши регистрационные данные оттуда и пропустить регистрацию."
        :type="'main'"
      />
      <div class="app-modal-recognized__buttons">
        <app-button :text="'Скопировать данные'" />
        <app-button
          class="app-modal-recognized__buttons-again"
          :text="'Зарегистрироваться заново'"
        />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
// import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';

export default Vue.extend({
  name: 'AppModalRecoveryByEmail',
  components: { AppText, AppModal, AppButton },
  // components: { AppModal, AppText, AppButton },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      isHaveAvatar: true,
    };
  },
  methods: {
    toggleModal() {
      return this.$emit('toggleModal');
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
