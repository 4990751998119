import FingerprintJS from '@fingerprintjs/fingerprintjs';

const FINGERPRINT_LS_KEY = 'fp';

export class Fingerprint {
  public static async init(): Promise<void> {
    const currentFingerprint = this.get();
    if (!currentFingerprint) {
      await this.set();
    }
  }
  public static get(): string | null {
    return localStorage.getItem(FINGERPRINT_LS_KEY);
  }
  private static async set(): Promise<void> {
    const fpLib = await FingerprintJS.load();
    const fingerprint = await fpLib.get();
    localStorage.setItem(FINGERPRINT_LS_KEY, fingerprint.visitorId);
  }
}
