<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Добавление нового блока"
    type="thirdTitle"
    :is-align-text="true"
  >
    <div class="app-modal-add-block">
      <div
        v-for="item in dataOptions"
        v-show="blocksArray.includes(item.blockTitle)"
        :key="item.title"
        class="app-modal-add-block__option-container"
      >
        <div
          v-if="item.title === 'Реклама' || item.title === 'Опросы и тесты'"
          :style="item.title === 'Опросы и тесты' ? 'height: 130px' : 'height: 220px'"
          class="app-modal-add-block__options-blocked"
        />
        <event-constructor-option
          :is-blocked="item.title === 'Реклама' || item.title === 'Опросы и тесты'"
          :image="item.image"
          :is-active="item.blockTitle === activeElement ?? true"
          :title="item.title"
          @toggleActiveOptions="toggleActiveOptions(item.blockTitle)"
        />
      </div>
      <app-button text="Продолжить" :is-disabled="!activeElement.length" @click="saveChanges" />
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import { AppButton } from '@/ui/components';
import EventConstructorOption from '@/components/event-constructor/event-constructor-option';

export default Vue.extend({
  components: {
    AppModal,
    EventConstructorOption,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    blocksArray: {
      type: Array,
    },
  },
  data() {
    return {
      activeElement: '',
      dataOptions: {
        documents: {
          image: 'documentsModal.png',
          title: 'Документы',
          isActive: false,
          blockTitle: 'DOCUMENT',
        },
        programs: {
          image: 'businessProgramModal.png',
          title: 'Деловая программа',
          isActive: false,
          blockTitle: 'PROGRAM',
        },
        speakers: {
          image: 'speakerModal.png',
          title: 'Спикеры',
          isActive: false,
          blockTitle: 'SPEAKER',
        },
        recommends: {
          image: 'recommendedModal.png',
          title: 'Рекомендации',
          isActive: false,
          blockTitle: 'RECOMMENDATION',
        },
        info: {
          image: 'infoModelModal.png',
          title: 'Информационный модуль',
          isActive: false,
          blockTitle: 'INFO',
        },
        slider: {
          image: 'sliderModal.png',
          title: 'Слайдер',
          isActive: false,
          blockTitle: 'SLIDER',
        },
        video: {
          image: 'videoModal.png',
          title: 'Видео',
          isActive: false,
          blockTitle: 'VIDEO',
        },
        videoWithText: {
          image: 'videoWithTextModal.png',
          title: 'Видео с текстом',
          isActive: false,
          blockTitle: 'VIDEO_WITH_TEXT',
        },
        gallery: {
          image: 'galleryModal.png',
          title: 'Фото-галерея',
          isActive: false,
          blockTitle: 'GALLERY',
        },
        galleryWithText: {
          image: 'galleryWithTextModal.png',
          title: 'Фото-галерея с текстом',
          isActive: false,
          blockTitle: 'GALLERY_WITH_TEXT',
        },
        advertising: {
          image: 'advertisingModal.png',
          title: 'Рекламный баннер',
          isActive: false,
          blockTitle: 'ADS',
        },
        partners: {
          image: 'partnersModal.png',
          title: 'Партнеры',
          isActive: false,
          blockTitle: 'PARTNER',
        },
        news: {
          image: 'newsModal.png',
          title: 'Новости',
          isActive: false,
          blockTitle: 'NEWS',
        },
        survey: {
          image: 'surveyModal.png',
          title: 'Опросы и тесты',
          isActive: false,
          blockTitle: 'SURVEY',
        },
      },
      comparisonArray: [
        'description-block',
        'programs-block',
        'speakers-block',
        'recommendations-block',
        'advertising-block',
        'info-block',
        'news-block',
        'quiz-block',
      ],
    };
  },
  methods: {
    toggleActiveOptions(item: string) {
      this.activeElement = item;
    },
    toggleModal() {
      this.$emit('toggleModal');
    },
    saveChanges() {
      this.$emit('onSelect', this.activeElement);
      this.toggleModal();
    },
  },
  mounted() {
    this.activeElement = '';
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
