/**
 * @description Получаем картинку файла, в зависимости от расширения
 * @param {string} fileName
 */
export const getFileImages = function (fileName: string): string {
  const fileFormat = fileName.split('.');
  if (
    fileFormat[fileFormat.length - 1] === 'pptx' ||
    fileFormat[fileFormat.length - 1] === 'pptm' ||
    fileFormat[fileFormat.length - 1] === 'ppt'
  ) {
    return 'ppt.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'pdf') {
    return 'pdf.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'xls' || fileFormat[fileFormat.length - 1] === 'xlsx') {
    return 'xls.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'doc') {
    return 'doc.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'txt') {
    return 'txt.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'js') {
    return 'js-file.png';
  }
  if (
    fileFormat[fileFormat.length - 1] === 'mp4' ||
    fileFormat[fileFormat.length - 1] === 'mov' ||
    fileFormat[fileFormat.length - 1] === 'avi'
  ) {
    return 'video.png';
  }
  if (
    fileFormat[fileFormat.length - 1] === 'jpeg' ||
    fileFormat[fileFormat.length - 1] === 'png' ||
    fileFormat[fileFormat.length - 1] === 'jpg'
  ) {
    return 'image.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'mp3') {
    return 'music.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'zip' || fileFormat[fileFormat.length - 1] === 'rar') {
    return 'zip.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'docx') {
    return 'word.png';
  }
  if (fileFormat[fileFormat.length - 1] === 'rtf') {
    return 'word.png';
  }
  return 'word.png';
};
