<template>
  <div class="app-input-redactor">
    <div
      :class="[
        'app-input-redactor__inner',
        { 'with-err': errors.length, 'with-success': !!success && !errors.length },
      ]"
    >
      <input
        v-if="tag === 'input'"
        :value="value"
        v-mask="getMask"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        :style="`line-height: ${lineHeight}px`"
        :type="type"
        :maxlength="maxlength"
        class="app-input-redactor__inner_input"
        :class="`input-text ${getClass} color-${color}`"
        @input="input"
        @blur="$emit('onBlur')"
        @keyup.enter="$emit('onEnter')"
        @focusout="(event) => $emit('onFocusLeave', event.target.value)"
        @change="change"
      />
      <textarea
        v-if="tag === 'textarea'"
        :value="value"
        v-mask="getMask"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        :style="`line-height: ${lineHeight}px; color: ${specialTextColor}`"
        :type="type"
        :maxlength="maxlength"
        class="app-input-redactor__inner_input"
        :class="`input-text ${getClass} color-${color}`"
        @input="input"
        @blur="$emit('onBlur')"
        @keyup.enter="(event) => $emit('onEnter', event.target.value)"
        @focusout="(event) => $emit('onFocusLeave', event.target.value)"
      />
    </div>
    <span v-if="!!errors.length && isHaveBottomTextError" class="app-input__error">
      <app-text
        v-for="(err, i) in errors"
        :key="err"
        :text="`${err}${i < errors.length - 1 ? '.' : ''}`"
        type="small-book"
        :color="'error'"
      />
    </span>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { TAutocompleteState, TInputTypes } from '@/ui/components/inputs/app-input/AppInputTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import {
  IText,
  ITextColor,
  TextColorEnum,
  TextTypeEnum,
} from '@/ui/components/text/app-text/AppTextTypes';
interface InputEvent extends Event {
  target: HTMLInputElement;
}
export default Vue.extend({
  components: {
    AppText,
  },
  props: {
    isHaveBottomTextError: {
      type: Boolean,
      required: false,
      default: true,
    },
    tag: {
      type: String,
      default: 'input',
    },
    value: {
      type: [String, Number],
      default: '',
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    success: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<TInputTypes>,
      default: 'text',
    },
    autocomplete: {
      type: String as PropType<TAutocompleteState>,
      default: 'off',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    fontType: {
      type: String as PropType<IText>,
      default: 'main',
      validator: (value) => Object.values(TextTypeEnum).includes(value as IText),
    },
    color: {
      type: String as PropType<ITextColor>,
      default: 'main',
      validator: (value) => Object.values(TextColorEnum).includes(value as ITextColor),
    },
    lineHeight: {
      type: String,
    },
    specialTextColor: {
      type: String,
    },
  },
  methods: {
    input(event: InputEvent) {
      const value = event.target.value;
      this.$emit('onInput', value);
    },
    change(event: InputEvent) {
      const value = event.target.value;
      this.$emit('onChange', value);
    },
  },
  computed: {
    getMask(): string {
      let customMask = '';
      switch (this.type) {
        case 'phone':
          return '+7 (###) ###-##-##';
        case 'number':
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.maxlength; i++) {
            customMask += '#';
          }
          return customMask;
        case 'numeric':
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.maxlength; i++) {
            customMask += '#';
          }
          return customMask;
        case 'clock':
          return '##:##';
        default:
          return '';
      }
    },
    getClass(): string {
      return this.fontType?.replaceAll('-', '__');
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
