<template>
  <div v-if="isShowTip" class="edit-program-speakers-tip">
    <AppIcon name="info" size="16" />
    <AppText
      text="Используйте поиск, чтобы добавить нового спикера или отредактировать данные о спикере, который уже есть в системе. "
      type="secondary"
    />
    <AppButtonIcon name="x" @click.native="hideTip" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AppButtonIcon, AppText } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';

export default defineComponent({
  name: 'EditProgramSpeakersTip',
  components: {
    AppIcon,
    AppText,
    AppButtonIcon,
  },
  setup() {
    const tipLsKey = 'EDIT_PROGRAM_SPEAKERS_TIP';
    const isShowTip = ref(true);
    const fetchShowTip = () => {
      const isShowTipFromLs = localStorage.getItem(tipLsKey);
      if (isShowTipFromLs) isShowTip.value = JSON.parse(isShowTipFromLs);
    };
    fetchShowTip();

    const hideTip = () => {
      isShowTip.value = false;
      localStorage.setItem(tipLsKey, JSON.stringify(false));
    };

    return { isShowTip, hideTip };
  },
});
</script>

<style lang="scss" scoped>
.edit-program-speakers-tip {
  padding: 10px 15px;
  background: #faf6d5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
</style>
