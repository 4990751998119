// тип
export enum TextTypeEnum {
  'title',
  'subtitle',
  'subtitle-regular',
  'thirdTitle',
  'fourthTitle',
  'fourthTitle-medium',
  'main',
  'main-book',
  'main-medium',
  'main-semibold',
  'primary',
  'primary-book',
  'primary-semibold',
  'primary-medium',
  'secondary',
  'secondary-book',
  'secondary-semibold',
  'secondary-medium',
  'small',
  'small-book',
  'small-semibold',
  'tiny',
  'tiny-book',
  'tiny-semibold',
}
export enum TextColorEnum {
  'main',
  'secondary',
  'error',
  'success',
  'button-title',
  'text-link',
  'text-on-dark-theme',
  'hero-text',
  'primary',
}
export type IText = keyof typeof TextTypeEnum;
export type ITextColor = keyof typeof TextColorEnum;
