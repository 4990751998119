<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Ссылка на трансляцию"
  >
    <div class="app-modal-programs-broadcast">
      <app-input
        placeholder="Ссылка"
        :value="broadcastLink"
        :errors="errorBroadcastLink"
        :maxlength="10000"
        @onFocusLeave="saveBroadcastLink"
      >
        <template #prepend>
          <app-icon name="link" color="#83888F" />
        </template>
      </app-input>
      <!--      <app-check-box-->
      <!--        :is-checked="saveRecordedStream"-->
      <!--        text="Сохранить запись после окончания трансляции"-->
      <!--        @input="toggleIsRecordStream"-->
      <!--      />-->
      <div
        v-if="programInfo?.format !== 'OFFLINE'"
        class="app-modal-create-program__additional-options-item"
      >
        <app-switcher-box
          class="publicationVideo"
          text="Публикация видеозаписи"
          type="toggle"
          :is-checked="isPublishStream"
          @input="toggleIsPublishStream"
        />
      </div>
      <app-input
        v-if="isPublishStream && programInfo?.format !== 'OFFLINE'"
        placeholder="Вставьте ссылку на видеозапись"
        :maxlength="10000"
        @onInput="saveRecordedStreamLink"
        :errors="errorRecordedStreamLink"
        :is-have-bottom-text-error="false"
        :value="recordedStreamLink"
      >
        <template #prepend>
          <app-icon name="link" color="#83888F" />
        </template>
      </app-input>
      <app-switcher-box
        class="publicationPresentation"
        :is-checked="isPublishPresentation"
        text="Публикация презентации"
        type="toggle"
        @input="toggleIsPublishPresentation"
      />
      <app-switcher-box
        class="chat"
        text="Чат"
        type="toggle"
        :is-checked="isActiveChat"
        @input="toggleActiveChat"
      />
      <app-select
        v-if="isActiveChat"
        list-key="access"
        :items="[{ access: 'Публичный' }, { access: 'Приватный' }]"
        :default-value="selectedAccess ? { access: 'Публичный' } : { access: 'Приватный' }"
        title-text="Не выбрано"
        @onSelect="selectAccess"
      />
      <app-button text="Сохранить" @click="onSave" />
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppInput, AppSwitcherBox, AppButton } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppSelect from '@/ui/components/selects/app-select/AppSelect.vue';
import { mapActions, mapState } from 'vuex';
import { IChangeProgram } from '@/services/AdminService/AdminServiceTypes';
import { EX_$Toaster } from '@/classes/content';

export default Vue.extend({
  components: {
    AppModal,
    AppInput,
    AppSwitcherBox,
    AppButton,
    AppIcon,
    AppSelect,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      isActiveChat: false,
      saveRecordedStream: false,
      isPublishStream: false,
      isPublishPresentation: false,
      broadcastLink: '',
      recordedStreamLink: '',
      errorBroadcastLink: [] as Array<string>,
      errorRecordedStreamLink: [] as Array<string>,
      selectedAccess: false,
    };
  },
  methods: {
    ...mapActions('admin', ['ARedactorProgram', 'AGetAdminPrograms']),
    ...mapActions('program', ['AGetProgramInfo']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    toggleActiveChat() {
      this.isActiveChat = !this.isActiveChat;
    },
    toggleIsPublishStream() {
      this.isPublishStream = !this.isPublishStream;
    },
    toggleIsPublishPresentation() {
      this.isPublishPresentation = !this.isPublishPresentation;
    },
    saveBroadcastLink(value: string) {
      this.broadcastLink = value;
    },
    saveRecordedStreamLink(value: string) {
      this.recordedStreamLink = value;
      this.errorRecordedStreamLink = [];
    },
    selectAccess(value: any) {
      this.selectedAccess = value.access === 'Публичный';
    },
    validateBroadcastLink() {
      if (this.broadcastLink) {
        if (this.broadcastLink.includes('iframe')) {
          return true;
        } else {
          return this.broadcastLink === this.programInfo?.broadcastLink;
        }
      } else return true;
    },
    validateRecordedStreamLink() {
      if (this.isPublishStream) {
        if (this.recordedStreamLink) {
          if (this.recordedStreamLink.includes('iframe')) {
            return true;
          } else {
            return this.recordedStreamLink === this.programInfo?.recordedStreamLink;
          }
        } else return true;
      } else return true;
    },

    async onSave() {
      const payload: IChangeProgram = {
        eventUUID: this.eventInfo.uuid,
        uuid: this.selectedProgramId,
        haveChat: this.isActiveChat,
        publishPresentation: this.isPublishPresentation,
        publishStream: this.isPublishStream,
        recordedStreamLink: this.recordedStreamLink,
        broadcastLink: this.broadcastLink,
        isChatPublic: this.selectedAccess,
      };
      if (this.broadcastLink === this.programInfo?.broadcastLink || !this.broadcastLink) {
        delete payload.broadcastLink;
      }
      if (
        !this.isPublishStream ||
        this.programInfo?.format === 'Офлайн' ||
        this.recordedStreamLink === this.programInfo?.recordedStreamLink
      ) {
        delete payload.recordedStreamLink;
      }
      if (!this.isPublishStream) {
        delete payload.recordedStreamLink;
      }
      if (this.validateBroadcastLink() && this.validateRecordedStreamLink()) {
        await this.ARedactorProgram(payload);
        await this.AGetAdminPrograms({
          eventUUID: this.eventInfo.uuid,
        });
        this.toggleModal();
      } else {
        if (!this.validateRecordedStreamLink()) {
          this.errorRecordedStreamLink = ['Некорректная ссылка'];
          this.EX_Toaster.error('Некорректная ссылка');
        }
        if (!this.validateBroadcastLink()) {
          this.errorBroadcastLink = ['Некорректная ссылка'];
          this.EX_Toaster.error('Проверьте ссылку на трансляцию');
        }
      }
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    ...mapState('program', ['programInfo']),
    ...mapState('admin', ['selectedProgramId', 'selectedProgramId', 'redactorProgram']),
  },
  async mounted() {
    await this.AGetProgramInfo({
      eventUUID: this.eventInfo.uuid,
      programUUID: this.selectedProgramId,
    });
    if (this.selectedProgramId) {
      this.isActiveChat = this.programInfo?.haveChat;
      // this.saveRecordedStream = this.programInfo?.saveRecordedStream;
      this.recordedStreamLink = this.programInfo?.recordedStreamLink;
      this.isPublishStream = this.programInfo?.publishStream;
      this.isPublishPresentation = this.programInfo?.publishPresentation;
      this.broadcastLink = this.programInfo?.broadcastLink;
      this.selectedAccess = this.programInfo?.isChatPublic;
    } else {
      this.isActiveChat = false;
      // this.saveRecordedStream = false;
      this.isPublishStream = false;
      this.isPublishPresentation = false;
      this.broadcastLink = '';
      this.selectedAccess = false;
    }
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
.app-modal-programs-broadcast {
  .chat {
    width: 40px;
  }
  .publicationVideo {
    width: 230px;
  }
  .publicationPresentation {
    width: 230px;
  }
  .app-switcher-box {
    padding: 11px 15px 11px 73px;
    background: #f4f5f6;
    border-radius: 12px;
  }
  .app-switcher-box__checkmark.type-toggle {
    left: 15px;
  }
  .select-list {
    top: 73px;
  }
  .chat {
    .app-switcher-box {
      max-width: 40px !important;
    }
  }
}
</style>
