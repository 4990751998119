import { Commit } from 'vuex';
import { types } from './mutations';
import { ProgramService } from '@/services';
import router from '@/router';
import {
  IEventProgram,
  IProgramInfo,
  IProgramInfoRequestParams,
  IReadProgramDocumentRequestData,
  IToggleFavoriteProgramRequestData,
  IUserProgramDocumentRequestParams,
} from '@/services/ProgramService/ProgramServiceTypes';
import { AxiosError } from 'axios';
import { useAuthStore } from '@/store/pinia/auth/auth.store';

export default {
  async AGetUserProgramDocument(
    { commit }: { commit: Commit },
    params: IUserProgramDocumentRequestParams
  ): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      ProgramService.getUserProgramDocuments(params).then((res) => {
        commit(types.SET_USER_DOCUMENTS_READ, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  async AReadProgramDocument(
    { commit }: { commit: Commit },
    body: IReadProgramDocumentRequestData
  ): Promise<void> {
    try {
      const res = await ProgramService.readProgramDocument(body);
      commit(types.READ_PROGRAM_DOCUMENT, res);
    } catch (e) {
      console.log(e);
    }
  },

  async AAddProgramToFavoritePrograms(
    { commit }: { commit: Commit },
    body: IToggleFavoriteProgramRequestData
  ): Promise<void> {
    try {
      const res = await ProgramService.addProgramToFavoritePrograms(body);
      commit(types.ADD_FAVORITE_PROGRAM, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ARemoveProgramToFavoritePrograms(
    { commit }: { commit: Commit },
    programUUID: string
  ): Promise<void> {
    try {
      await ProgramService.removeProgramFromFavoritePrograms(programUUID);
      commit(types.DELETE_FAVORITE_PROGRAM, programUUID);
    } catch (e) {
      console.log(e);
    }
  },

  async AGetFavoritePrograms({ commit }: { commit: Commit }): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      await ProgramService.getFavoritePrograms().then((res: Array<IEventProgram>) => {
        commit(types.SET_FAVORITE_PROGRAMS, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  async AGetProgramInfo(
    { commit }: { commit: Commit },
    params: IProgramInfoRequestParams
  ): Promise<void> {
    try {
      await ProgramService.getProgramInfo(params).then((res: IProgramInfo) => {
        commit(types.SET_PROGRAM_INFO, res);
      });
    } catch (error) {
      const err = error as AxiosError;
      // @ts-ignore
      if (err.response.status === 403) {
        router.replace('/');
      }
      // @ts-ignore
      if (err.response.status === 404) {
        router.replace('/404');
      }
      console.log(error);
    }
  },

  ALogoutProgram({ commit }: { commit: Commit }): void {
    commit(types.LOGOUT_PROGRAM);
  },
};
