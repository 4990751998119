// import { Commit } from 'vuex';
// import { types } from '@/store/modules/user-management/mutations';
// import { UserManagementService } from '@/services';
// import {
//   ICreateReferralLinkRequestData,
//   IEditReferralLinkRequestData,
//   IExportUser,
//   IInviteUser,
//   IReferralLinksRequestParams,
//   IUsersInfoRequestParams,
// } from '@/services/UserManagementService/UserManagementServiceTypes';
// import { EX_$Toaster } from '@/classes/content';
// import fileDownload from 'js-file-download';
// import userManagementService from '@/services/UserManagementService';
// import { IState } from '@/store/modules/user-management/state';

export default {
  // async AGetEventUsers(
  //   { commit }: { commit: Commit },
  //   params: IUsersInfoRequestParams
  // ): Promise<void> {
  //   try {
  //     const res = await UserManagementService.getEventUsers(params);
  //     commit(types.SET_EVENT_USERS, res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async fetchEventUsers({ state, commit }: { state: IState; commit: Commit }): Promise<void> {
  //   try {
  //     // TODO Доработать передачу параметров
  //     // const res = await UserManagementService.getEventUsers(params);
  //     // TODO Убрать моки
  //     const usersCount = 301;
  //     const mockUsers = [
  //       {
  //         uuid: '253e4648-8e6b-47c2-9eaf-b3a88f80549d',
  //         fullName: 'Михаил Сергеевич Васюков',
  //         email: 'tiabc.work+admin@gmail.com',
  //         isEmailVerified: '-',
  //         geoData: 'Россия, Химки',
  //         registrationDate: '2023-09-21T06:03:55.917Z',
  //         commonSource: 'SELF_REGISTRATION',
  //         referralSource: null,
  //         lastActiveDate: '2023-03-24T07:27:36+0000',
  //         test1: 'Что-то тестовое',
  //         test2: 'Что-то тестовое',
  //         test3: 'Что-то тестовое',
  //       },
  //       {
  //         uuid: 'a2644500-e170-4df1-aa46-1bec40196b0a',
  //         fullName: 'Тест Тестович Тестовый',
  //         email: 'pochta@mail.com',
  //         isEmailVerified: '-',
  //         geoData: 'Россия, Иркутск',
  //         registrationDate: '2023-09-21T06:43:20.340Z',
  //         commonSource: 'SELF_REGISTRATION',
  //         referralSource: null,
  //         lastActiveDate: '2023-03-24T07:27:36+0000',
  //         test1: 'Что-то тестовое',
  //         test2: 'Что-то тестовое',
  //         test3: 'Что-то тестовое',
  //       },
  //       {
  //         uuid: 'b2273ffb-7c6d-4554-b166-df56e9852a8d',
  //         fullName: 'Cgjh Ghhhh Ghhgf',
  //         email: 'kirg@fhh.ru',
  //         isEmailVerified: '-',
  //         geoData: 'Россия, Кострома',
  //         registrationDate: '2023-09-28T14:52:48.363Z',
  //         commonSource: 'SELF_REGISTRATION',
  //         referralSource: null,
  //         lastActiveDate: '2023-03-24T07:27:36+0000',
  //         test1: 'Что-то тестовое',
  //         test2: 'Что-то тестовое',
  //         test3: 'Что-то тестовое',
  //       },
  //     ];
  //     const generateUsers = () => {
  //       const offsetPage =
  //         state.eventUsersPageParams.page === 1
  //           ? state.eventUsersPageParams.page
  //           : state.eventUsersPageParams.page - 1;
  //       const offset = offsetPage * state.eventUsersPageParams.showBy;
  //       const rest = usersCount - offset;
  //       if (rest > state.eventUsersPageParams.showBy) {
  //         return Array.from({ length: state.eventUsersPageParams.showBy }, () => {
  //           const randomIndex = Math.floor(Math.random() * 3);
  //           return mockUsers[randomIndex];
  //         });
  //       } else {
  //         return Array.from({ length: rest }, () => {
  //           const randomIndex = Math.floor(Math.random() * 3);
  //           return mockUsers[randomIndex];
  //         });
  //       }
  //     };
  //     const res = {
  //       eventUsers: generateUsers(),
  //       currentPage: state.eventUsersPageParams.page,
  //       totalPages: Math.ceil(usersCount / state.eventUsersPageParams.showBy),
  //       totalCount: usersCount,
  //       eventUsersHeaders: [
  //         {
  //           key: 'email',
  //           name: 'E-mail',
  //           position: 1,
  //           isShown: true,
  //         },
  //         {
  //           key: 'registrationDate',
  //           name: 'Дата и время регистрации',
  //           position: 2,
  //           isShown: true,
  //         },
  //         {
  //           key: 'commonSource',
  //           name: 'Источник регистрации',
  //           position: 3,
  //           isShown: true,
  //         },
  //         {
  //           key: 'lastActiveDate',
  //           name: 'Последняя активность',
  //           position: 4,
  //           isShown: true,
  //         },
  //         {
  //           key: 'fullName',
  //           name: 'ФИО',
  //           position: 5,
  //           isShown: true,
  //         },
  //         {
  //           key: 'geoData',
  //           name: 'Страна',
  //           position: 6,
  //           isShown: true,
  //         },
  //         {
  //           key: 'test1',
  //           name: 'Тест 1',
  //           position: 6,
  //           isShown: true,
  //         },
  //         {
  //           key: 'test2',
  //           name: 'Тест 2',
  //           position: 6,
  //           isShown: true,
  //         },
  //         {
  //           key: 'test3',
  //           name: 'Тест 3',
  //           position: 6,
  //           isShown: true,
  //         },
  //       ],
  //     };
  //     commit(types.SET_EVENT_USERS, res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async AGetInvitedUsers(
  //   { commit }: { commit: Commit },
  //   params: IUsersInfoRequestParams
  // ): Promise<void> {
  //   try {
  //     const res = await UserManagementService.getInvitedUsers(params);
  //     commit(types.SET_INVITED_USERS, res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async AExportEventUsers({ commit }: { commit: Commit }, params: IExportUser): Promise<void> {
  //   try {
  //     const response = await UserManagementService.exportEventUsers(params);
  //     // @ts-ignore
  //     fileDownload(response, 'filename.csv');
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async AExportInvitedUsers({ commit }: { commit: Commit }, params: IExportUser): Promise<void> {
  //   try {
  //     const response = await UserManagementService.exportInvitedUsers(params);
  //     // @ts-ignore
  //     fileDownload(response, 'filename.csv');
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async AGetReferralLinks(
  //   { commit }: { commit: Commit },
  //   params: IReferralLinksRequestParams
  // ): Promise<void> {
  //   try {
  //     const response = await UserManagementService.getReferralLinks(params);
  //     commit(types.SET_REFERRAL_LIST, response);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  // async AGetUserProfile(
  //   { commit }: { commit: Commit },
  //   params: { eventUUID: string; userUUID: string }
  // ): Promise<void> {
  //   try {
  //     const res = await userManagementService.getUserProfile(params);
  //     commit(types.SET_USER_PROFILE, res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
};
