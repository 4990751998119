<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Загрузка видео"
  >
    <div class="app-modal-event-video">
      <app-text
        text="После загрузки нового видео старое перестанет отображаться."
        line-height="25"
        type="primary"
      />
      <app-input
        v-if="
          (modalName === 'appModalEventVideo' && getOptionForBlock('INFO') === 'variant_2') ||
          modalName === 'appModalEventVideoWithTextFullBlock'
        "
        class="app-modal-event-video__title"
        label="Текст"
        placeholder="Введите текст описания блока"
        :note="`${inputValueDescription.length}/10000`"
        :errors="errorInputValueDescription"
        :maxlength="10010"
        :value="inputValueDescription"
        tag="textarea"
        @onInput="onInputChangeDescription"
      />
      <app-input
        placeholder="Вставьте ссылку на видеозапись"
        :value="inputValueVideoUrl"
        @onInput="onInputChangeVideoUrl"
        :errors="errorInputValueVideoUrl"
        :maxlength="10000"
      >
        <template #prepend>
          <app-icon name="link" color="#83888F" />
        </template>
      </app-input>
    </div>
    <app-button class="app-modal-event-video__button" text="Продолжить" @click="onSave" />
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppInput, AppButton } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { mapActions, mapState } from 'vuex';
import { TBlockName } from '@/views/admin/constructor-event/ConstructorEventPageTypes';
import { ICustomizationBlock } from '@/services/AdminService/AdminServiceTypes';
import { EX_$Toaster } from '@/classes/content';

export default Vue.extend({
  components: {
    AppModal,
    AppText,
    AppInput,
    AppIcon,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      inputValueDescription: '',
      inputValueVideoUrl: '',
      errorInputValueDescription: [] as Array<string>,
      errorInputValueVideoUrl: [] as Array<string>,
    };
  },
  methods: {
    ...mapActions('event', ['AGetEventInfo']),
    ...mapActions('admin', [
      'AChangeEventVideo',
      'AChangeInfoBlockDescription',
      'changeBlockVideo',
      'changeBlockVideoWithText',
    ]),
    toggleModal() {
      this.$emit('toggleModal');
    },
    onInputChangeDescription(value: string) {
      this.inputValueDescription = value;
      if (value.length > 10000) {
        this.errorInputValueDescription = [`${value.length} / 10000`];
      } else {
        this.errorInputValueDescription = [];
      }
    },
    onInputChangeVideoUrl(value: string) {
      this.inputValueVideoUrl = value;
      if (value.length === 0) {
        this.errorInputValueVideoUrl = ['Вставьте ссылку на видеозапись'];
      } else {
        this.errorInputValueVideoUrl = [];
      }
    },
    getOptionForBlock(blockName: TBlockName) {
      let option: string | null = '';
      this.eventInfo?.eventBlock?.forEach((item: ICustomizationBlock) => {
        if (item.blockName === blockName) {
          option = item.option;
        }
      });
      return option;
    },

    onSave() {
      switch (this.modalName) {
        case 'appModalEventVideo':
          {
            if (this.getOptionForBlock('INFO') === 'variant_2') {
              if (
                this.inputValueVideoUrl &&
                (this.inputValueVideoUrl.includes('iframe') ||
                  this.inputValueVideoUrl === this.eventInfo?.infoBlock?.videoUrl) &&
                this.inputValueDescription &&
                this.inputValueDescription.length <= 10000
              ) {
                this.AChangeInfoBlockDescription(this.inputValueDescription);
                this.AChangeEventVideo(this.inputValueVideoUrl);
                this.toggleModal();
              } else {
                if (!this.inputValueVideoUrl) {
                  this.errorInputValueVideoUrl = ['Обязательное поле'];
                }
                if (
                  !this.inputValueVideoUrl.includes('iframe') &&
                  this.inputValueVideoUrl !== this.eventInfo?.infoBlock?.videoUrl &&
                  this.inputValueVideoUrl
                ) {
                  this.errorInputValueVideoUrl = ['Некорректная ссылка'];
                  this.EX_Toaster.error('Некорректная ссылка');
                }
                if (!this.inputValueDescription) {
                  this.errorInputValueDescription = ['Обязательное поле'];
                  this.EX_Toaster.error('Проверьте описание');
                }
                if (this.inputValueDescription.length > 10000) {
                  this.errorInputValueDescription = [
                    'Недопустимое количество символов (не более 10000)',
                  ];
                }
              }
            } else {
              if (
                (this.inputValueVideoUrl && this.inputValueVideoUrl.includes('iframe')) ||
                this.inputValueVideoUrl.includes('iframe') ||
                this.inputValueVideoUrl === this.eventInfo?.infoBlock?.videoUrl
              ) {
                this.AChangeEventVideo(this.inputValueVideoUrl);
                this.toggleModal();
              } else {
                this.EX_Toaster.error('Некорректная ссылка');
              }
            }
          }
          break;

        case 'appModalEventVideoFullBlock':
          {
            if (
              (this.inputValueVideoUrl && this.inputValueVideoUrl.includes('iframe')) ||
              this.inputValueVideoUrl.includes('iframe') ||
              this.inputValueVideoUrl === this.eventInfo?.infoBlock?.listOfInfo?.videoBlockUrl
            ) {
              this.changeBlockVideo(this.inputValueVideoUrl);
              this.toggleModal();
            } else {
              this.EX_Toaster.error('Некорректная ссылка');
            }
          }
          break;

        case 'appModalEventVideoWithTextFullBlock':
          {
            if (
              this.inputValueVideoUrl &&
              (this.inputValueVideoUrl.includes('iframe') ||
                this.inputValueVideoUrl ===
                  this.eventInfo?.infoBlock?.listOfInfo?.videoWithTextBlockUrl) &&
              this.inputValueDescription &&
              this.inputValueDescription.length <= 10000
            ) {
              this.changeBlockVideoWithText({
                text: this.inputValueDescription,
                url: this.inputValueVideoUrl,
              });
              this.toggleModal();
            } else {
              if (!this.inputValueVideoUrl) {
                this.errorInputValueVideoUrl = ['Обязательное поле'];
              }
              if (
                !this.inputValueVideoUrl.includes('iframe') &&
                this.inputValueVideoUrl !==
                  this.eventInfo?.infoBlock?.listOfInfo?.videoWithTextBlockUrl &&
                this.inputValueVideoUrl
              ) {
                this.errorInputValueVideoUrl = ['Некорректная ссылка'];
                this.EX_Toaster.error('Некорректная ссылка');
              }
              if (!this.inputValueDescription) {
                this.errorInputValueDescription = ['Обязательное поле'];
                this.EX_Toaster.error('Проверьте описание');
              }
              if (this.inputValueDescription.length > 10000) {
                this.errorInputValueDescription = [
                  'Недопустимое количество символов (не более 10000)',
                ];
              }
            }
          }
          break;
      }
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    ...mapState('admin', [
      'changedEventVideo',
      'changedInfoBlockDescription',
      'changedBlockVideo',
      'changedBlockVideoWithTextUrl',
      'changedBlockVideoWithTextText',
    ]),
  },
  mounted() {
    switch (this.modalName) {
      case 'appModalEventVideo':
        {
          if (this.changedEventVideo) {
            this.inputValueVideoUrl = this.changedEventVideo;
          } else this.inputValueVideoUrl = this.eventInfo?.infoBlock?.videoUrl ?? '';
          if (this.changedInfoBlockDescription) {
            this.inputValueDescription = this.changedInfoBlockDescription;
          } else this.inputValueDescription = this.eventInfo?.infoBlock?.description ?? '';
        }
        break;

      case 'appModalEventVideoFullBlock':
        {
          if (this.changedBlockVideo) {
            this.inputValueVideoUrl = this.changedBlockVideo;
          } else
            this.inputValueVideoUrl = this.eventInfo?.infoBlock?.listOfInfo?.videoBlockUrl ?? '';
        }
        break;

      case 'appModalEventVideoWithTextFullBlock':
        {
          if (this.changedBlockVideoWithTextUrl) {
            this.inputValueVideoUrl = this.changedBlockVideoWithTextUrl;
          } else
            this.inputValueVideoUrl =
              this.eventInfo?.infoBlock?.listOfInfo?.videoWithTextBlockUrl ?? '';

          if (this.changedBlockVideoWithTextText) {
            this.inputValueDescription = this.changedBlockVideoWithTextText;
          } else
            this.inputValueDescription =
              this.eventInfo?.infoBlock?.listOfInfo?.videoWithTextBlockText ?? '';
        }
        break;
    }
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
.app-modal-event-video {
  &__title {
    .app-input__inner_input {
      resize: none;
      min-height: 100px;
      &::-webkit-scrollbar {
        width: 10px;

        &-thumb {
          border-radius: 6px;
          background-color: var(--client-color);
          border: 1px solid transparent;
          background-clip: content-box;
        }

        &-track {
          background: white;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
