<template>
  <div class="app-button-link__wrap">
    <!--КАК РОУТЕР-->
    <router-link
      v-if="isRoute"
      :to="link"
      :target="target"
      :class="classes"
      :disabled="isDisabled"
      @click="emitClick"
    >
      <slot name="prepend" />
      <app-text
        v-if="title"
        :text="title"
        :class="titleClasses"
        :color="isDisabled ? 'main' : 'button-title'"
        :type="fontType"
      />
      <slot name="append" />
    </router-link>
    <!--КАК ССЫЛКА-->
    <a
      v-else
      :href="link"
      :target="target"
      rel="noopener noreferrer nofollow"
      :class="classes"
      @click="emitClick"
    >
      <slot name="prepend" />
      <app-text
        v-if="title"
        :text="title"
        :class="titleClasses"
        :color="color"
        :special-text-color="specialTextColor"
        :type="fontType"
        :line-height="lineHeight"
      />
      <slot name="append" />
    </a>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { AppText } from '@/ui/components/text';
import {
  ButtonSize,
  ButtonType,
  TButtonSize,
  TButtonText,
  TButtonType,
} from '@/ui/components/buttons/app-button/AppButtonTypes';
import { ITextColor, TextTypeEnum } from '@/ui/components/text/app-text/AppTextTypes';

export default Vue.extend({
  name: 'AppButtonLink',
  components: { AppText },
  props: {
    type: {
      type: String as PropType<TButtonType>,
      default: 'link',
      validator: (value) => Object.values(ButtonType).includes(value as ButtonType),
    },
    size: {
      type: String as PropType<TButtonSize>,
      default: 'medium',
      validator: (value) => Object.values(ButtonSize).includes(value as ButtonSize),
    },
    fontType: {
      type: String as PropType<TButtonText>,
      default: 'main',
      validator: (value) => Object.values(TextTypeEnum).includes(value as TextTypeEnum),
    },
    title: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRoute: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: '_self',
    },
    color: {
      type: String as PropType<ITextColor>,
      default: 'main',
    },
    specialTextColor: {
      type: String,
      required: false,
    },
    lineHeight: {
      type: String,
    },
  },
  computed: {
    classes() {
      return [
        'app-button-link',
        `app-button-link--${this.type}`,
        `app-button-link--${this.size}`,
        { disabled: this.isDisabled },
      ];
    },
    titleClasses() {
      return ['app-button-link__title'];
    },
  },
  methods: {
    emitClick(): void {
      this.$emit('click');
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
