<template>
  <div class="box-border">
    <DefaultHeaderDesktop
      v-if="windowSizeStore.isWindow.desktop"
      variant="NAV_INLINE"
      color-variant="WHITE"
      :is-header-hidden="isHeaderHidden"
      :nav-items="navItems"
    />
    <HeaderMobile
      v-if="!windowSizeStore.isWindow.desktop"
      color-variant="WHITE"
      :nav-items="navItems"
    />
  </div>
</template>

<script lang="ts">
export default { name: 'Header' };
</script>

<script setup lang="ts">
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import HeaderMobile from '@/layouts/Header/Mobile/HeaderMobile.vue';
import DefaultHeaderDesktop from '@/layouts/Header/Desktop/HeaderDesktop.vue';
import store from '@/store';
import { computed } from 'vue';

defineProps<{
  isHeaderHidden: boolean;
}>();

const windowSizeStore = useWindowSizeStore();

const navItems = computed(() => {
  return store.state.event.eventInfo?.pageCustomization?.headerLinks ?? [];
});
</script>

<style scoped lang="scss"></style>
