import { EX_$Observer } from '@/classes/base';

interface ICookies {
  get(name: string): string;
  set(name: string, value: any): any;
  remove(name: string): void;
  check(name: string): boolean;
}

interface ICookiesOption {
  path?: string;
  domain?: string;
  expires?: string | number | Date;
  secure?: boolean;
}

/**
 * @class $Cookies - класс для работы с куками
 * @implements ICookies
 * */
class $Cookies implements ICookies {
  get(name: string): string {
    return EX_$Observer.context.$cookies.get(name);
  }

  set(name: string, value: any = true, options?: ICookiesOption): void {
    if (options) {
      if (!options.path) {
        options.path = '/';
      }
      // Убираем флаг, так как не работает в сафари при разработке
      if (process.env.NODE_ENV === 'development' && options.secure) {
        options.secure = false;
      }
    }

    return EX_$Observer.context.$cookies.set(name, value, options);
  }

  remove(name: string): void {
    EX_$Observer.context.$cookies.remove(name);
  }

  check(name: string): boolean {
    return !!this.get(name);
  }
}

const EX_$Cookies: $Cookies = new $Cookies();

export default EX_$Cookies;
