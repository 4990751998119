import clientHttp from '@/utils/custom/http/СlientHttp';
import {
  ICancelInvitationEventRequestData,
  IEvent,
  IEventInfo,
  IMyEventList,
  IReadEventDocumentRequestData,
  ISearchProgramRequestData,
  ISpeakerCard,
  IToggleFavoriteRequestData,
  IUserEventDocumentRequestParams,
} from '@/services/EventService/EventServiceTypes';
import MMCOHttp from '@/utils/custom/http/MMCOHttp';

export const getEventList = (search?: string) => {
  return clientHttp.get(`/event${search ? `?search=${search}` : ''}`) as Promise<Array<IEvent>>;
};

export const getMyEventList = () => {
  return clientHttp.get('/event/my') as Promise<IMyEventList>;
};
export const getEventInfo = (slug: string) => {
  return clientHttp.get(`/event/${slug}`) as Promise<IEventInfo>;
};

export const updateEventSpeakersOrder = (speakers: ISpeakerCard[], slug: string) => {
  return clientHttp.patch(`/event/${slug}/speakers`, { speakers });
};

export const addEventToFavoriteEvents = (data: IToggleFavoriteRequestData) => {
  return clientHttp.post('/favorite-event', {
    eventUUID: data.eventUUID,
  });
};
export const removeEventFromFavoriteEvents = (eventUUID: string) => {
  return clientHttp.delete(`/favorite-event/${eventUUID}`);
};
export const getFavoriteEvents = () => {
  return clientHttp.get(`/favorite-event`);
};

export const getUserEventDocuments = (params: IUserEventDocumentRequestParams) => {
  return clientHttp.get(`/event/${params.eventId}/user-event-document`);
};

export const readEventDocument = (data: IReadEventDocumentRequestData) => {
  return clientHttp.post(`/event/${data.eventUUID}/user-event-document`, {
    eventDocumentUUID: data.eventDocumentUUID,
  });
};

export const getInvitationsEvent = () => {
  return clientHttp.get(`/event/user-invitation/my`);
};

export const cancelInvitationEvent = (data: ICancelInvitationEventRequestData) => {
  return clientHttp.post(`/event/user-invitation/${data.invitationId}/decline`);
};

export const getRecommended = () => {
  return clientHttp.get(`/event/recommended`);
};

export const SearchProgram = (data: ISearchProgramRequestData) => {
  return clientHttp.get(
    `/events/${data.eventUUID}/programs?${data.search ? `search=${data.search}` : ''}`,
    {
      onlyMyFavorite: data.onlyMyFavorite,
    }
  );
};
export const saveSpeaker = (data: Pick<ISpeakerCard, 'info'>) => {
  return clientHttp.post('/speakers', data);
};
export const editSpeaker = (data: Pick<ISpeakerCard, 'info'>, uuid: string) => {
  return clientHttp.put(`/speakers/${uuid}`, data);
};

export const uploadImageEvent = (formData: FormData) => {
  return MMCOHttp.postFile(`/upload/image`, formData);
};
