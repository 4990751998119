var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-pagination"},[_c('div',{staticClass:"app-pagination__btn-prev app-pagination__btn",class:{ 'btn-disabled': _vm.EX_$AppPaginationClass.isDisabledPrev }},[_c('div',{on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToFirst()}}},[_c('app-icon',{attrs:{"color":_vm.EX_$AppPaginationClass.isDisabledPrev ? '#83888F' : '#111111',"name":"arrow-left"}})],1),_c('div',{on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToPrev()}}},[_c('app-text',{attrs:{"color":_vm.EX_$AppPaginationClass.isDisabledPrev ? 'secondary' : 'main',"type":"primary","text":"Назад"}})],1)]),_c('div',{staticClass:"app-pagination__pages"},[_c('span',{class:['app-pagination__pages_dots', { visible: _vm.EX_$AppPaginationClass.currentPage > 2 }],on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToFirst()}}},[_vm._v(" ... ")]),_c('div',{staticClass:"app-pagination__pages_list"},_vm._l((_vm.EX_$AppPaginationClass.paginationController),function(item){return _c('div',{key:item,class:[
          'app-pagination__pages_list-item',
          { active: item === _vm.EX_$AppPaginationClass.currentPage },
        ],on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToPage(item)}}},[_c('app-text',{attrs:{"text":item}})],1)}),0),_c('span',{class:[
        'app-pagination__pages_dots',
        { visible: _vm.EX_$AppPaginationClass.currentPage < _vm.EX_$AppPaginationClass.getMaxPage - 1 },
      ],on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToLast()}}},[_vm._v(" ... ")])]),_c('div',{staticClass:"app-pagination__btn-next app-pagination__btn",class:{ 'btn-disabled': _vm.EX_$AppPaginationClass.isDisabledNext }},[_c('div',{on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToNext()}}},[_c('app-text',{attrs:{"color":_vm.EX_$AppPaginationClass.isDisabledNext ? 'secondary' : 'main',"type":"primary","text":"Далее"}})],1),_c('div',{on:{"click":function($event){return _vm.EX_$AppPaginationClass.goToLast()}}},[_c('app-icon',{attrs:{"color":_vm.EX_$AppPaginationClass.isDisabledNext ? '#83888F' : '#111111',"name":"arrow-right"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }