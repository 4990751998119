<template>
  <div class="flex flex-col">
    <!--    <BannerAdsPageCustomization-->
    <!--      v-if="isShowBannerAdvertisement"-->
    <!--      :advertisement="$store.state.event.eventInfo?.pageCustomization?.advertisement"-->
    <!--    />-->
    <!--    <BannerAdsMmcoTechPartner v-if="isShowBannerMmco" />-->
    <FooterBase v-if="variant === 'BASE'" :nav-items="navItems" :documents="documents" />
  </div>
</template>

<script lang="ts">
export default { name: 'Footer' };
</script>

<script setup lang="ts">
import FooterBase from '@/layouts/Footer/FooterBase.vue';
import { FooterVariant, LayoutNavItem } from '@/helpers/types/layouts.types';
import { TDocument } from '@/helpers/types/shared.types';

const props = defineProps<{
  variant: FooterVariant;
  navItems: LayoutNavItem[];
  documents: TDocument[];
}>();
</script>

<style scoped lang="scss"></style>
