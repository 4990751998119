import { IState } from './state';

import { formattedDate } from '@/utils/custom';
import { IEventProgramFavorite } from '@/services/ProgramService/ProgramServiceTypes';
import router from '@/router';

export default {
  programDate(state: IState): string {
    const dateStart = `${formattedDate(state.programInfo.startDate, 'onlyDay')} ${formattedDate(
      state.programInfo.startDate,
      'onlyMonth'
    )}`;
    const dateEnd = `${formattedDate(state.programInfo.endDate, 'onlyDay')} ${formattedDate(
      state.programInfo.endDate,
      'onlyMonth'
    )}`;
    if (dateStart === dateEnd) {
      return dateStart;
    } else {
      return `${dateStart} - ${dateEnd}`;
    }
  },
  programTime(state: IState): string {
    return `${formattedDate(state.programInfo.startDate, 'onlyTime')} - ${formattedDate(
      state.programInfo.endDate,
      'onlyTime'
    )}`;
  },
  isFavoriteProgram(state: IState): boolean {
    return state.favoritePrograms?.some(
      (item: IEventProgramFavorite) => item.programUUID === router.currentRoute.params.id_program
    );
  },
};
