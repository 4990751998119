import defaultTheme from 'tailwindcss/defaultTheme';

export default {
  theme: {
    fontFamily: {
      base: ['Suisse-Intl', 'sans-serif'],
    },
    colors: {
      black: {
        50: '#111',
        100: '#000',
      },
      white: '#fff',
      gray: '#83888F',
      neutral: {
        100: '#F4F5F6',
        200: '#DEDEDE',
      },
      emerald: '#D6EEDD',
      green: '#34A853',
      indigo: '#EAF2FF',
      blue: '#3282FF',
      red: {
        50: '#FBF0F0',
        500: '#EA4335',
      },
      amber: '#FFC93E',
      orange: '#FAF6D5',
      yellow: '#957400',
      client: 'rgb(from var(--client-color) r g b / <alpha-value>)',
      current: 'currentColor',
    },
    fontSize: {
      xs: ['12px', { lineHeight: '16px' }],
      sm: ['14px', { lineHeight: '18px' }],
      base: ['16px', { lineHeight: '22px' }],
      lg: ['18px', { lineHeight: '26px' }],
      xl: ['20px', { lineHeight: '26px' }],
      '2xl': ['24px', { lineHeight: '32px' }],
      '3xl': ['32px', { lineHeight: '40px' }],
      '4xl': ['40px', { lineHeight: '50px' }],
      '5xl': ['45px', { lineHeight: '55px' }],
    },
    lineHeight: {
      1: '16px',
      2: '18px',
      3: '20px',
      4: '22px',
      5: '26px',
      6: '30px',
      7: '32px',
      8: '40px',
      9: '50px',
      10: '55px',
    },
    // leading: {
    //   1: '16px',
    //   2: '18px',
    //   3: '20px',
    //   4: '22px',
    //   5: '26px',
    //   6: '30px',
    //   7: '32px',
    //   8: '40px',
    //   9: '50px',
    //   10: '55px',
    // },
    spacing: {
      0.5: '2px',
      1: '4px',
      1.5: '6px',
      2: '8px',
      2.5: '10px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      7.5: '30px',
      8: '32px',
      9: '36px',
      10: '40px',
      11: '44px',
      12: '48px',
      16: '64px',
      20: '80px',
      32: '128px',
      40: '160px',
    },
    borderRadius: {
      none: '0px',
      sm: '5px',
      DEFAULT: '8px',
      md: '12px',
      lg: '20px',
      xl: '50px',
      full: '50%',
    },
    shadow: {
      sm: 'box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);',
      DEFAULT: 'box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);',
      md: 'box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);',
      lg: 'box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);',
      xl: 'box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);',
      '2xl': 'box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);',
      inner: 'box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;',
      none: defaultTheme.boxShadow.none,
    },
    // TODO сделать новый компосабл/стор вместо миксина под эти значения
    screens: {
      xs: '375px',
      sm: '768px',
      md: '1024px',
      lg: '1440px',
    },
  },
  content: ['./src/**/*.{vue,js,ts,jsx,tsx}'],
  corePlugins: {
    preflight: false,
  },
  plugins: [],
};
