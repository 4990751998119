<template>
  <div ref="target" class="w-full relative">
    <div @click.prevent="isVisiblePopup = !isVisiblePopup" class="dropdown-open-btn">
      <span class="whitespace-nowrap text-[20px]">{{ activeCountry?.emoji || '🏳️' }}</span>
      <AppIcon name="chevron-down" size="20" :class="isVisiblePopup ? 'rotate-180' : ''" />
    </div>

    <div v-if="isVisiblePopup" class="dropdown dropdown-shadow w-[560px]">
      <input type="text" placeholder="Поиск стран" class="dropdown-input" v-model="countryInput" />

      <div v-if="filteredCountries.length" class="max-h-[150px] overflow-y-auto px-2 py-1">
        <div
          v-for="country in filteredCountries"
          :key="country.code"
          class="dropdown-country-item"
          @click="handleSelectCountry(country)"
        >
          <div class="flex space-x-[10px]">
            <span>{{ country?.emoji }}</span>
            <span>{{ country?.name }} ({{ country?.dial_code }})</span>
          </div>

          <span>
            <app-icon v-if="selectedCountry === country.code" name="check" size="20" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'CountryDropdown' };
</script>

<script setup lang="ts">
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { ICountryNumber } from '@/services/AuthService/AuthServiceTypes';
import { CountryCode } from 'libphonenumber-js/types';

const emit = defineEmits(['select-country']);
const props = defineProps<{
  isVisible: boolean;
  countries: ICountryNumber[];
  selectedCountry?: CountryCode;
}>();

const isVisiblePopup = ref(props.isVisible);
const countryInput = ref('');
const countriesList = ref(props.countries);

const target = ref(null);

onClickOutside(target, () => (isVisiblePopup.value = false));

const handleSelectCountry = (value: ICountryNumber) => {
  isVisiblePopup.value = false;
  countryInput.value = '';
  emit('select-country', value);
};

const activeCountry = computed(() => {
  if (props.selectedCountry) {
    return props.countries.find((item: ICountryNumber) => item.code === props.selectedCountry);
  }

  return { emoji: '' };
});

const sortedCountries = computed(() => {
  const copyArray = cloneDeep(countriesList.value);
  copyArray.sort((a, b) => {
    if (a.code === props.selectedCountry) {
      return -1;
    } else if (b.code === props.selectedCountry) {
      return 1;
    }
    return 0;
  });

  return copyArray;
});

const filteredCountries = computed(() => {
  return sortedCountries.value.filter((country) =>
    country.name.toLowerCase().includes(countryInput.value.toLowerCase())
  );
});
</script>

<style scoped lang="scss">
.dropdown {
  @apply absolute top-[50px] left-[-16px] p-[0px] rounded-[10px] box-border border-[1px] border-solid border-neutral-100 bg-white overflow-hidden;

  &-input {
    @apply px-4 py-3 w-full text-base bg-none box-border outline-none border-[0] border-b-2 border-solid border-neutral-100;
  }

  &-country-item {
    @apply flex justify-between w-full px-[7px] py-[10px] rounded-[10px] drop-shadow-sm hover:bg-neutral-100 box-border text-base cursor-pointer;
  }
}
.dropdown-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.dropdown-open-btn {
  @apply flex items-center space-x-1 pr-1 cursor-pointer;
}
</style>
