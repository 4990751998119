<template>
  <div class="box-border bg-black-50">
    <div class="container text-xs" :style="{ height: ADMIN_LAYOUT_SIZES.footerHeight }">
      <div class="flex justify-between py-5">
        <div class="flex gap-16">
          <div class="flex flex-col gap-1.5 items-start">
            <img
              class="h-[16px]"
              src="@/assets/images/shared/mmco-logo/mmco-white-logo.svg"
              alt="Логотип MMCO"
            />

            <div class="text-white/50">
              {{ FOOTER_COPYRIGHT }}
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="text-white">Техническая поддержка</div>
            <a :href="`mailto:${email}`" target="_blank" class="admin-footer-link">
              {{ email }}
            </a>
          </div>
        </div>

        <div class="flex justify-between items-end gap-12">
          <a
            v-if="userAgreement"
            :href="userAgreement.fileUrl"
            target="_blank"
            class="admin-footer-link"
          >
            {{ userAgreement.name }}
          </a>

          <a
            v-if="privacyPolicy"
            :href="privacyPolicy.fileUrl"
            target="_blank"
            class="admin-footer-link"
          >
            {{ privacyPolicy.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'AdminFooter' };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import store from '@/store';
import { TDocument } from '@/helpers/types/shared.types';
import { ADMIN_LAYOUT_SIZES, FOOTER_COPYRIGHT } from '@/helpers/constants/layout.constants';

const userAgreement = computed<TDocument | null>(() => {
  return store.state.client?.clientInfo?.footer?.documents[0] ?? null;
});
const privacyPolicy = computed<TDocument | null>(() => {
  return store.state.client?.clientInfo?.footer?.documents[1] ?? null;
});
const email = computed(() => {
  return store.state.client?.clientInfo?.footer?.contacts?.email;
});
</script>

<style scoped lang="scss">
.admin-footer-link {
  @apply text-white/50 cursor-pointer hover:text-blue active:text-blue visited:text-white/50;
}
</style>
