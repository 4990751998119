<template>
  <div class="box-border bg-white">
    <!--    DESKTOP-->
    <div v-if="windowSizeStore.isWindow.desktop" class="container pt-5 pb-10 flex flex-col gap-6">
      <div
        class="flex"
        :class="{
          'justify-between': navItems.length,
          'justify-center': !navItems.length,
        }"
      >
        <div>
          <HeaderClientLogo max-height="46px" />
        </div>

        <div v-if="navItems.length" class="flex items-center gap-5">
          <NavItem
            v-for="navItem in navItems"
            :key="navItem.name"
            :nav-item="navItem"
            font-weight="400"
          />
        </div>
      </div>

      <div class="flex justify-between items-center">
        <div class="flex items-center gap-5">
          <img
            class="h-[16px]"
            src="../../assets/images/shared/mmco-logo/mmco-logo.svg"
            alt="Логотип MMCO"
          />

          <div class="text-sm text-gray">{{ FOOTER_COPYRIGHT }}</div>
        </div>

        <a
          v-for="document in documents"
          :key="document.name"
          :href="document.fileUrl"
          target="_blank"
          class="default-footer-link"
        >
          {{ document.name }}
        </a>
      </div>
    </div>
    <!--    DESKTOP-->

    <!--    MOBILE-->
    <div v-if="!windowSizeStore.isWindow.desktop" class="flex flex-col p-5 gap-6">
      <div class="w-full h-[1px] bg-neutral-200" />

      <div
        class="flex"
        :class="{
          'justify-start': navItems.length,
          'justify-center': !navItems.length,
        }"
      >
        <HeaderClientLogo max-height="40px" />
      </div>

      <div v-if="navItems.length" class="flex flex-wrap gap-6">
        <NavItem
          v-for="navItem in navItems"
          :key="navItem.name"
          :nav-item="navItem"
          font-weight="500"
        />
      </div>

      <div class="w-full h-[1px] bg-neutral-200" />

      <div class="flex flex-col gap-2.5">
        <div class="flex justify-start">
          <img
            class="h-[16px]"
            src="../../assets/images/shared/mmco-logo/mmco-logo.svg"
            alt="Логотип MMCO"
          />
        </div>

        <div class="text-sm text-gray">{{ FOOTER_COPYRIGHT }}</div>

        <a
          v-for="document in documents"
          :key="document.name"
          :href="document.fileUrl"
          target="_blank"
          class="default-footer-link"
        >
          {{ document.name }}
        </a>
      </div>
    </div>
    <!--    MOBILE-->
  </div>
</template>

<script lang="ts">
export default { name: 'FooterBase' };
</script>

<script setup lang="ts">
import NavItem from '@/layouts/shared/NavItem.vue';
import HeaderClientLogo from '@/layouts/Header/shared/HeaderClientLogo.vue';
import { TDocument } from '@/helpers/types/shared.types';
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import { LayoutNavItem } from '@/helpers/types/layouts.types';
import { FOOTER_COPYRIGHT } from '@/helpers/constants/layout.constants';

defineProps<{
  navItems: LayoutNavItem[];
  documents: TDocument[];
}>();

const windowSizeStore = useWindowSizeStore();
</script>

<style scoped lang="scss">
.default-footer-link {
  @apply text-sm lg:text-sm/3 text-gray cursor-pointer hover:text-blue active:text-blue visited:text-gray;
}
</style>
