import { IState } from '@/store/modules/profile/state';
export default {
  sortedCareer: (state: IState) => {
    const copyArray = [...(state.profileInfo?.profile?.careerPath ?? [])];
    copyArray.sort((a, b) => {
      const dateTampA = new Date(a.startDate);
      const dateTampB = new Date(b.startDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    return copyArray;
  },

  sortedEducation: (state: IState) => {
    const copyArray = [...(state.profileInfo?.profile?.educationPath ?? [])];
    copyArray.sort((a, b) => {
      const dateTampA = new Date(a.endDate);
      const dateTampB = new Date(b.endDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    return copyArray;
  },
};
