import router from '@/router';

import { TRouterScrollBehaviour } from '@/common/types/router.types';

/** Набор утилит для работы с роутером */
export class RouterUtil {
  /** Позволяет прокидывать параметры в query для сохранения значений. (Например, при пагинации) */
  public static async changeQuery(
    query: Record<string, string | undefined>,
    scrollBehaviour: TRouterScrollBehaviour = 'default',
    routeName: string | null = null
  ) {
    if (router.currentRoute.name) {
      await router.push({
        name: routeName ?? router.currentRoute.name,
        query: {
          ...router.currentRoute.query,
          ...query,
        },
        params: {
          scrollBehaviour,
        },
      });
    }
  }
}
