<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="fourthTitle"
    text-head="Персональная информация"
    :is-confirm-exit="true"
  >
    <div class="app-modal-profile-edit-info">
      <div class="app-modal-profile-edit-info__item">
        <app-input
          placeholder="Введите имя"
          label="* Имя"
          :value="inputValueFirstName"
          :errors="errorFirstName"
          @onInput="
            (val) => {
              inputValueFirstName = val;
              errorFirstName = [];
            }
          "
        />
        <app-input
          placeholder="Введите фамилию"
          label="* Фамилия"
          :value="inputValueLastName"
          :errors="errorLastName"
          @onInput="
            (val) => {
              inputValueLastName = val;
              errorLastName = [];
            }
          "
        />
        <app-input
          placeholder="Введите отчество"
          label="Отчество"
          :value="inputValueMiddleName"
          @onInput="
            (val) => {
              inputValueMiddleName = val;
            }
          "
        />
        <app-input
          placeholder="Организация"
          label="Место работы"
          :value="inputValueOrganizationName"
          @onInput="
            (val) => {
              inputValueOrganizationName = val;
            }
          "
        />
        <app-input-dropdown
          :list-key="'title'"
          label="* Страна"
          placeholder="Введите страну"
          :is-loading="isCountriesLoading"
          @search="getCountries"
          :default-value="inputValueCountry"
          :list="searchCountries"
          :errors="errorCountry"
          @onSelect="setCountry"
        />
        <app-input-dropdown
          :list-key="'title'"
          label="* Регион"
          placeholder="Введите регион"
          :errors="errorRegion"
          :is-loading="isRegionsLoading"
          @search="getRegions"
          :default-value="inputValueRegion"
          :list="searchRegions"
          @onSelect="setRegion"
          :disabled="!inputValueCountry.title"
          :note="!inputValueCountry.title && 'Для заполнения введите Страну'"
        />
        <app-input-dropdown
          :list-key="'title'"
          :label="'* Населённый пункт'"
          placeholder="Введите населённый пункт"
          :is-loading="isCitiesLoading"
          :errors="errorCity"
          @search="getCities"
          :default-value="inputValueCity"
          :list="searchCities"
          @onSelect="setCity"
          :disabled="!inputValueRegion.title"
          :note="!inputValueRegion.title && 'Для заполнения введите Регион'"
        />
        <app-select
          class="app-modal-profile-edit-info__timezone"
          :items="timezonesEvent"
          title-text="Не выбрано"
          list-key="timezone"
          label="Часовой пояс"
          :default-value="selectedTimezone"
          @onSelect="selectTimezoneEvent"
        />
      </div>
      <app-text text="Социальные сети" type="fourthTitle" color="main" />
      <div class="app-modal-profile-edit-info__socials">
        <app-input
          placeholder="https://vk.com/example"
          label="VK"
          :errors="errorInputValueVk"
          :is-have-bottom-text-error="false"
          :value="inputValueProfileVk"
          @onInput="setInputValueVk"
          @onFocusLeave="saveInputValueProfileVk"
        />
        <app-input
          placeholder="https://ok.ru/example"
          label="Одноклассники"
          :errors="errorInputValueOk"
          :is-have-bottom-text-error="false"
          :value="inputValueProfileOk"
          @onInput="setInputValueOk"
          @onFocusLeave="saveInputValueProfileOk"
        />
        <app-input
          placeholder="@name"
          label="Telegram"
          type="telegram"
          :value="inputValueProfileTg"
          @onInput="setInputValueProfileTg"
          @onFocusLeave="saveInputValueProfileTg"
        />
        <app-input
          placeholder="example@mail.ru"
          :errors="errorInputValueMail"
          :is-have-bottom-text-error="false"
          label="Почта"
          :value="inputValueEmail"
          @onInput="setInputValueMail"
          @onFocusLeave="saveInputValueProfileEmail"
        />
        <app-input
          placeholder="+7 ( ___ ) ___ __ __"
          label="Контактный телефон"
          :value="inputValuePhone"
          :errors="errorInputValuePhone"
          :is-have-bottom-text-error="false"
          type="phone"
          @onInput="setInputValuePhoneNumber"
        />
      </div>
      <app-button text="Сохранить изменения" @click="saveChanges" />
      <div class="app-modal-profile-edit-info__footer">
        <app-text
          type="secondary"
          :text="`${clientInfo?.client?.name} ни при каких обстоятельствах не предоставляет третьим лицам персональную информацию своих пользователей, кроме случаев, прямо предусмотренных в`"
          color="secondary"
        />
        <app-text
          class="link"
          type="secondary"
          color="text-link"
          text="Пользовательском соглашении."
          @click="goTo(clientInfo?.footer?.termsOfUse ?? '')"
        />
      </div>
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppButton, AppInput } from '@/ui/components';
import AppInputDropdown from '@/ui/components/inputs/app-input-dropdown/AppInputDropdown.vue';
import { mapActions, mapState } from 'vuex';
import AppSelect from '@/ui/components/selects/app-select/AppSelect.vue';
import { IPayloadProfileInfo } from '@/services/ProfileService/ProfileServiceTypes';
import { EX_$Toaster } from '@/classes/content';
export default Vue.extend({
  components: {
    AppModal,
    AppText,
    AppButton,
    AppInput,
    AppSelect,
    AppInputDropdown,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      selectedTimezone: {} as { value: string; timezone: string },
      inputValueFirstName: '',
      inputValueLastName: '',
      inputValueMiddleName: '',
      inputValueOrganizationName: '',
      inputValueProfileVk: '',
      inputValueProfileOk: '',
      inputValueProfileTg: '',
      inputValueEmail: '',
      inputValuePhone: '',
      idTimeCountry: 0 as any,
      idTimeRegion: 0 as any,
      idTimeCity: 0 as any,
      inputValueCity: {} as { id: number; title: string },
      inputValueRegion: {} as { id: number; title: string },
      inputValueCountry: {} as { id: number; title: string },
      errorFirstName: [] as Array<string>,
      errorLastName: [] as Array<string>,
      errorCountry: [] as Array<string>,
      errorRegion: [] as Array<string>,
      errorCity: [] as Array<string>,
      errorInputValueVk: [] as Array<string>,
      errorInputValueOk: [] as Array<string>,
      errorInputValueTg: [] as Array<string>,
      errorInputValueMail: [] as Array<string>,
      errorInputValuePhone: [] as Array<string>,
      timezonesEvent: [
        {
          timezone: '-11, Ниуэ/Алофи',
          value: 'Pacific/Niue',
        },
        {
          timezone: '-10, США/Гавайи',
          value: 'US/Hawaii',
        },
        {
          timezone: '-9, США/Аляска',
          value: 'US/Alaska',
        },
        {
          timezone: '-8, США/Лос-Анджелес',
          value: 'US/Pacific',
        },
        {
          timezone: '-7, США/Денвер',
          value: 'US/Mountain',
        },
        {
          timezone: '-6, США/Чикаго',
          value: 'US/Central',
        },
        {
          timezone: '-5, США/Нью-йорк',
          value: 'US/Michigan',
        },
        {
          timezone: '-4, Америка/Ангилья',
          value: 'America/Anguilla',
        },
        {
          timezone: '-3, Америка/Сантьяго',
          value: 'America/Santiago',
        },
        {
          timezone: '-2, Америка/Норонья',
          value: 'America/Noronha',
        },
        {
          timezone: '-1, Азорские острова',
          value: 'Atlantic/Azores',
        },
        {
          timezone: '0, Европа/Лондон',
          value: 'Europe/London',
        },
        {
          timezone: '+1, Франция/Париж',
          value: 'Europe/Paris',
        },
        {
          timezone: '+2, Азия/Тель-Авив',
          value: 'Asia/Tel_Aviv',
        },
        {
          timezone: '+3, Россия/Москва',
          value: 'Europe/Moscow',
        },
        {
          timezone: '+4, Россия/Самара',
          value: 'Europe/Samara',
        },
        {
          timezone: '+5, Азия/Ташкент',
          value: 'Asia/Tashkent',
        },
        {
          timezone: '+6, Россия/Омск',
          value: 'Asia/Omsk',
        },
        {
          timezone: '+7, Россия/Красноярск',
          value: 'Asia/Krasnoyarsk',
        },
        {
          timezone: '+8, Россия/Иркутск',
          value: 'Asia/Irkutsk',
        },
        {
          timezone: '+9, Россия/Якутск',
          value: 'Asia/Yakutsk',
        },
        {
          timezone: '+10, Россия/Магадан',
          value: 'Asia/Magadan',
        },
        {
          timezone: '+11, Россия/Сахалин',
          value: 'Asia/Sakhalin',
        },
        {
          timezone: '+12, Россия/Камчатка',
          value: 'Asia/Kamchatka',
        },
      ],
      socialNetworks: [
        { url: '', network: 'VK' },
        { url: '', network: 'OK' },
        { url: '', network: 'TELEGRAM' },
        { url: '', network: 'MAIL' },
      ],
      countryValue: '',
    };
  },
  methods: {
    ...mapActions('auth', [
      'AGetSearchCities',
      'AGetSearchRegions',
      'AGetSearchCountries',
      'AClearRegions',
      'AClearCities',
      'AClearCountries',
    ]),
    ...mapActions('client', ['AGetInfo']),
    ...mapActions('profile', ['AChangeProfilePersonalInfo', 'AGetProfile']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    goTo(url: string) {
      window.open(url, '_blank');
    },

    /**
     * @description Проверяем , если страна Россия, то регион и город обязательные поля
     */
    checkCountryId() {
      if (this.inputValueCountry?.id === 1) {
        if (this.inputValueCity?.id && this.inputValueRegion?.id) {
          return true;
        } else return false;
      } else return true;
    },

    scrollToError() {
      const scrollTarget = document.querySelector('.with-err');
      const relativeTarget = scrollTarget?.parentElement;
      relativeTarget?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    /**
     * @description Валидация ссылки на ВКонтакте
     */
    validateSocialNetworkVk() {
      if (this.inputValueProfileVk) {
        return !!this.inputValueProfileVk.includes('https://vk.com');
      }
      return true;
    },
    /**
     * @description Валидация ссылки на Одноклассники
     */
    validateSocialNetworkOk() {
      if (this.inputValueProfileOk) {
        return !!this.inputValueProfileOk.includes('https://ok.ru');
      }
      return true;
    },
    /**
     * @description Валидация электронной почты
     */
    validateSocialNetworkMail() {
      if (this.inputValueEmail) {
        return !!this.inputValueEmail.includes('@') && !!this.inputValueEmail.includes('.');
      }
      return true;
    },

    async saveChanges() {
      const profilePayload: IPayloadProfileInfo = {
        firstName: this.inputValueFirstName,
        middleName: this.inputValueMiddleName,
        lastName: this.inputValueLastName,
        countryOfResidence: { id: this.inputValueCountry.id, name: this.inputValueCountry.title },
        regionOfResidence: { id: this.inputValueRegion.id, name: this.inputValueRegion.title },
        cityOfResidence: { id: this.inputValueCity.id, name: this.inputValueCity.title },
        userLiveTimeZone: this.selectedTimezone.timezone,
        socialNetworks: this.socialNetworks,
        organizationName: this.inputValueOrganizationName,
        personalPhoneNumber: this.inputValuePhone,
      };

      if (!this.socialNetworks?.length) {
        delete profilePayload.socialNetworks;
      }
      if (!profilePayload.cityOfResidence?.id) {
        //@ts-ignore
        profilePayload.cityOfResidence! = null;
      }
      if (!profilePayload.regionOfResidence?.id) {
        //@ts-ignore
        profilePayload.regionOfResidence = null;
      }
      if (!profilePayload.middleName) {
        //@ts-ignore
        profilePayload.middleName = null;
      }
      if (
        this.inputValueFirstName &&
        this.inputValueLastName &&
        this.inputValueCountry?.id &&
        this.checkCountryId() &&
        this.validateSocialNetworkVk() &&
        this.validateSocialNetworkOk() &&
        this.validateSocialNetworkMail()
      ) {
        await this.AChangeProfilePersonalInfo(profilePayload);
        await this.AGetProfile();
        this.errorLastName = [];
        this.errorFirstName = [];
        this.errorCountry = [];
        this.errorRegion = [];
        this.errorCity = [];
        if (this.patchProfileErrorStatusCode === 400) {
          this.errorInputValuePhone = ['Невалидный номер телефона'];
          this.EX_Toaster.error('Проверьте правильность заполнения полей');
        } else {
          this.toggleModal();
        }
      } else {
        if (!this.inputValueFirstName) {
          this.errorFirstName = ['Обязательное поле'];
        } else this.errorFirstName = [];
        if (!this.inputValueLastName) {
          this.errorLastName = ['Обязательное поле'];
        } else this.errorLastName = [];
        if (!this.inputValueCountry?.id) {
          this.errorCountry = ['Обязательное поле'];
        } else this.errorCountry = [];
        if (this.inputValueCountry?.id === 1) {
          if (!this.inputValueRegion?.id) {
            this.errorRegion = ['Обязательное поле'];
          } else this.errorRegion = [];
          if (!this.inputValueCity?.id) {
            this.errorCity = ['Обязательное поле'];
          } else this.errorCity = [];
        }
        this.EX_Toaster.error('Проверьте правильность заполнения полей');

        if (!this.validateSocialNetworkVk()) {
          this.errorInputValueVk = ['Некорректная ссылка'];
        }
        if (!this.validateSocialNetworkOk()) {
          this.errorInputValueOk = ['Некорректная ссылка'];
        }
        if (!this.validateSocialNetworkMail()) {
          this.errorInputValueMail = ['Некорректная ссылка'];
        }
      }
      setTimeout(() => {
        this.scrollToError();
      }, 0);
    },
    /**
     * @description Получаем страну с бэка по значению
     * @param {string} value
     */
    getCountries(value: string) {
      clearInterval(this.idTimeCountry);
      if (value.length >= 3) {
        this.idTimeCountry = setTimeout(() => {
          this.AGetSearchCountries(value);
        }, 300);
      } else {
        this.inputValueCountry = {} as { id: number; title: string };
        this.inputValueRegion = {} as { id: number; title: string };
        this.inputValueCity = {} as { id: number; title: string };
        this.AClearCountries();
      }
    },
    /**
     * @description Получаем регион с бэка по значению
     * @param {string} value
     */
    getRegions(value: string) {
      clearInterval(this.idTimeRegion);
      if (value.length >= 3) {
        this.idTimeRegion = setTimeout(() => {
          this.AGetSearchRegions({ countryId: this.inputValueCountry.id, search: value });
        }, 300);
      } else {
        this.inputValueRegion = {} as { id: number; title: string };
        this.inputValueCity = {} as { id: number; title: string };
        this.AClearRegions();
      }
    },
    /**
     * @description Получаем город с бэка по значению
     * @param {string} value
     */
    getCities(value: string) {
      clearTimeout(this.idTimeCity);
      if (value.length >= 3) {
        this.idTimeCity = setTimeout(() => {
          this.AGetSearchCities({
            regionId: this.inputValueRegion.id,
            regionName: this.inputValueRegion.title,
            search: value,
            countryId: this.inputValueCountry.id,
          });
        }, 300);
      } else {
        this.inputValueCity = {} as { id: number; title: string };
        this.AClearCities();
      }
    },
    setCountry(value: { id: number; title: string }) {
      this.inputValueCountry = value;
      this.errorCountry = [];
      this.setRegion({ id: 0, title: '' });
      this.setCity({ id: 0, title: '' });
    },
    setRegion(value: { id: number; title: string }) {
      this.setCity({ id: 0, title: '' });
      if (Object.keys(value).length) {
        this.inputValueRegion = value;
      } else this.inputValueRegion = {} as { id: number; title: string };
      this.errorRegion = [];
    },
    setCity(value: { id: number; title: string }) {
      if (Object.keys(value).length) {
        this.inputValueCity = value;
      } else this.inputValueCity = {} as { id: number; title: string };
      this.errorCity = [];
    },
    selectTimezoneEvent(value: any) {
      this.selectedTimezone = value;
    },
    setInputValueVk(value: string) {
      this.inputValueProfileVk = value;
      this.errorInputValueVk = [];
    },
    saveInputValueProfileVk(value: string) {
      this.socialNetworks[0] = { url: value, network: 'VK' };
    },
    setInputValueOk(value: string) {
      this.inputValueProfileOk = value;
      this.errorInputValueOk = [];
    },
    saveInputValueProfileOk(value: string) {
      this.socialNetworks[1] = { url: value, network: 'OK' };
    },
    setInputValueProfileTg(value: string) {
      this.inputValueProfileTg = value;
    },
    saveInputValueProfileTg(value: string) {
      this.socialNetworks[2] = { url: value, network: 'TELEGRAM' };
    },
    setInputValueMail(value: string) {
      this.inputValueEmail = value;
      this.errorInputValueMail = [];
    },
    saveInputValueProfileEmail(value: string) {
      this.socialNetworks[3] = { url: value, network: 'MAIL' };
    },

    /**
     * @description Формируем массив с сылками на социальные сети
     */
    setSocialNetworks() {
      for (let i = 0; i < this.socialNetworks.length; i++) {
        if (this.socialNetworks[i]?.network.toLowerCase() === 'vk') {
          this.inputValueProfileVk = this.socialNetworks[i].url;
        }
        if (this.socialNetworks[i]?.network.toLowerCase() === 'ok') {
          this.inputValueProfileOk = this.socialNetworks[i].url;
        }
        if (this.socialNetworks[i]?.network.toLowerCase() === 'telegram') {
          this.inputValueProfileTg = this.socialNetworks[i].url;
        }
        if (this.socialNetworks[i]?.network.toLowerCase() === 'mail') {
          this.inputValueEmail = this.socialNetworks[i].url;
        }
      }
    },
    setInputValuePhoneNumber(val: string | null) {
      if (val) {
        this.inputValuePhone = val;
        //@ts-ignore
      } else this.inputValuePhone = null;
      this.errorInputValuePhone = [];
    },
  },
  computed: {
    ...mapState('auth', [
      'searchCities',
      'isCitiesLoading',
      'searchRegions',
      'isRegionsLoading',
      'searchCountries',
      'isCountriesLoading',
    ]),
    ...mapState('client', ['clientInfo']),
    ...mapState('profile', ['profileInfo', 'patchProfileErrorStatusCode']),
  },
  mounted() {
    this.inputValueFirstName = this.profileInfo?.profile?.firstName;
    this.inputValueMiddleName = this.profileInfo?.profile?.middleName;
    this.inputValueLastName = this.profileInfo?.profile?.lastName;
    this.inputValuePhone = this.profileInfo?.profile?.personalPhoneNumber;
    this.inputValueOrganizationName = this.profileInfo?.profile?.organizationName;
    this.socialNetworks = this.profileInfo?.profile?.socialNetworks ?? [
      { url: '', network: 'VK' },
      { url: '', network: 'OK' },
      { url: '', network: 'TELEGRAM' },
      { url: '', network: 'MAIL' },
    ];
    if (this.socialNetworks.length) {
      this.setSocialNetworks();
    }

    this.inputValueRegion = {
      id: this.profileInfo?.profile?.regionOfResidence?.id,
      title: this.profileInfo?.profile?.regionOfResidence?.name,
    };
    this.inputValueCountry = {
      id: this.profileInfo?.profile?.countryOfResidence?.id,
      title: this.profileInfo?.profile?.countryOfResidence?.name,
    };
    this.inputValueCity = {
      id: this.profileInfo?.profile?.cityOfResidence?.id,
      title: this.profileInfo?.profile?.cityOfResidence?.name,
    };

    let actualTimeZone = '';
    for (let i = 0; i < this.timezonesEvent?.length; i++) {
      if (this.profileInfo?.profile?.userLiveTimeZone === this.timezonesEvent[i]?.timezone) {
        actualTimeZone = this.timezonesEvent[i]?.timezone;
        break;
      }
    }
    this.selectedTimezone = {
      value: this.profileInfo?.profile?.userLiveTimeZone,
      timezone: actualTimeZone,
    };
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
