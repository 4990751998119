<template>
  <div class="page-spinner">
    <app-spinner />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';

export default Vue.extend({
  name: 'PageSpinner',
  components: { AppSpinner },
});
</script>

<style scoped lang="scss">
@use 'style';
</style>
