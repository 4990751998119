// import { IState } from './state';
// import {
//   IEventUsersResponseData,
//   IInvitedUsersResponseData,
//   IReferralLink,
// } from '@/services/UserManagementService/UserManagementServiceTypes';
// import { IProfile } from '@/services/ProfileService/ProfileServiceTypes';

// export const types = {
//   SET_EVENT_USERS: 'SET_EVENT_USERS',
//   SET_INVITED_USERS: 'SET_INVITED_USERS',
//   SET_REFERRAL_LIST: 'SET_REFERRAL_LIST',
//   ADD_REFERRAL_LINK: 'ADD_REFERRAL_LINK',
//   SET_USER_PROFILE: 'SET_USER_PROFILE',
// };

export default {
  // [types.SET_EVENT_USERS](state: IState, eventUsers: IEventUsersResponseData) {
  //   state.eventUsers = eventUsers;
  // },
  // [types.SET_INVITED_USERS](state: IState, invitedUsers: IInvitedUsersResponseData) {
  //   state.invitedUsers = invitedUsers;
  // },
  // [types.SET_REFERRAL_LIST](state: IState, referralSource: Array<IReferralLink>) {
  //   state.referralList = referralSource;
  // },
  // [types.ADD_REFERRAL_LINK](state: IState, referral: IReferralLink) {
  //   state.referralList.push(referral);
  // },
  // [types.SET_USER_PROFILE](state: IState, userProfile: IProfile) {
  //   state.userProfile = userProfile;
  // },
};
