<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="$emit('toggleModal')"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Спикеры"
  >
    <div class="calendar-speakers">
      <template v-if="!isLoadingSpeakers && !speakersError">
        <EditProgramSpeakersTip />
        <EditProgramSpeakers
          :speakers="speakers"
          :show-search-label="false"
          @update="speakers = $event"
        />
        <AppButton text="Сохранить" @click="save()" />
      </template>

      <div class="loader" v-if="isLoadingSpeakers">
        <UILoader />
      </div>

      <div class="loader" v-if="speakersError">
        <div>{{ speakersError }}</div>
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton } from '@/ui/components';
import { ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import { EX_$Toaster } from '@/classes/content';
import EditProgramSpeakers from '@/components/admin/ProgramsControlPanel/EditProgram/EditProgramSpeakers/EditProgramSpeakers.vue';
import UILoader from '@/components/UI/UILoader.vue';
import { ProgramService } from '@/services';
import store from '@/store';
import EditProgramSpeakersTip from '@/components/admin/ProgramsControlPanel/EditProgram/EditProgramSpeakers/EditProgramSpeakersTip.vue';

export default defineComponent({
  components: {
    EditProgramSpeakersTip,
    UILoader,
    EditProgramSpeakers,
    AppModal,
    AppButton,
  },
  emits: ['toggleModal'],
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoadingSpeakers = ref(true);
    const speakers = ref<ISpeakerCard[]>([]);
    const speakersError = ref('');
    const fetchSpeakers = async () => {
      try {
        const res = await ProgramService.getProgramInfo({
          eventUUID: (store.state as any).event.eventInfo.uuid,
          programUUID: (store.state as any).admin.selectedProgramId,
        });
        speakers.value = res.speakers;
      } catch (e) {
        speakersError.value = 'Произошла ошибка';
      } finally {
        isLoadingSpeakers.value = false;
      }
    };
    fetchSpeakers();

    const save = async () => {
      try {
        await store.dispatch('admin/AUpdateProgram', {
          eventUUID: (store.state as any).event.eventInfo.uuid,
          programUUID: (store.state as any).admin.selectedProgramId,
          speakers: speakers.value,
        });
        await store.dispatch('admin/AGetAdminPrograms', {
          eventUUID: (store.state as any).event.eventInfo.uuid,
        });
        emit('toggleModal');
        EX_$Toaster.success('Успешно сохранено');
      } catch (e) {
        EX_$Toaster.error('Произошла ошибка');
      }
    };

    return { isLoadingSpeakers, speakers, speakersError, save };
  },
});
</script>

<style lang="scss">
@import '~@/ui/styles/colors/index.scss';
@import '~@/ui/styles/font/style.scss';
@import '~@/ui/styles/theme/_color_theme.scss';

.calendar-speakers {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.loader {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-Suisse-Intl-SemiBold, sans-serif;
}
</style>
