<template>
  <app-button
    :type="type"
    :fontType="fontType"
    :size="size"
    :text="text"
    :isDisabled="isDisabled"
    :colorText="colorText"
    :color-button="colorButton"
    @click="$emit('click')"
  >
    <template v-if="iconNameLeft" v-slot:prepend>
      <app-icon
        class="icon-left"
        :name="iconNameLeft"
        :color="iconColor"
        :colorHover="iconColorHover"
        :size="iconSize"
      />
    </template>
    <template v-if="iconNameRight" v-slot:append>
      <app-icon
        class="icon-right"
        :name="iconNameRight"
        :color="iconColor"
        :colorHover="iconColorHover"
        :size="iconSize"
      />
    </template>
  </app-button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import {
  TButtonType,
  TButtonSize,
  TButtonText,
  ButtonSize,
  ButtonType,
} from '../app-button/AppButtonTypes';
import AppButton from '@/ui/components/buttons/app-button';
import AppIcon from '@/ui/components/icons/app-icon';
import {
  ITextColor,
  TextColorEnum,
  TextTypeEnum,
} from '@/ui/components/text/app-text/AppTextTypes';
import { TIconName } from '@/ui/components/icons/app-icon/AppIconTypes';

export default Vue.extend({
  name: 'AppButtonWithIcon',
  components: { AppButton, AppIcon },
  props: {
    type: {
      type: String as PropType<TButtonType>,
      default: 'primary',
      validator: (value) => Object.values(ButtonType).includes(value as ButtonType),
    },
    fontType: {
      type: String as PropType<TButtonText>,
      default: 'main',
      validator: (value) => Object.values(TextTypeEnum).includes(value as TextTypeEnum),
    },
    size: {
      type: String as PropType<TButtonSize>,
      default: 'medium',
      validator: (value) => Object.values(ButtonSize).includes(value as ButtonSize),
    },
    text: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    iconNameLeft: {
      type: String as PropType<TIconName>,
      required: false,
    },
    iconNameRight: {
      type: String as PropType<TIconName>,
      required: false,
    },
    iconColor: {
      type: String,
      default: '#000000',
    },
    iconColorHover: {
      type: String,
      required: false,
    },
    iconSize: {
      type: [String, Number],
      default: '24',
    },
    colorButton: {
      type: String,
    },
    colorText: {
      type: String as PropType<ITextColor>,
      default: 'button-title',
      validator: (value) => Object.values(TextColorEnum).includes(value as ITextColor),
    },
  },
  computed: {
    classes() {
      return [
        'button',
        `button--${this.type}`,
        `button--${this.size}`,
        { disabled: this.isDisabled },
      ];
    },
    titleClasses() {
      return ['button__title'];
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
