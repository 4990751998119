import clientHttp from '@/utils/custom/http/СlientHttp';
import {
  IChatInfoRequestParams,
  ICheckUserInChatRequestParams,
  ICheckUserInChatResponseData,
  IListOfMessagesRequestParams,
  IListOfMessagesResponseData,
  IMessagesByTypeRequestParams,
  IMessagesInThreadRequestParams,
  IMessagesInThreadResponseData,
  IRespondInThreadRequestData,
  ISendMessageRequestData,
  IToggleLikeMessageRequestData,
} from '@/services/ChatService/ChatServiceTypes';

export const getChatInfo = (params: IChatInfoRequestParams) => {
  return clientHttp.get(`/chats/${params.eventUUID}/programs/${params.programUUID}`);
};
export const checkUserInChat = (params: ICheckUserInChatRequestParams) => {
  return clientHttp.get(
    `/chats/${params.chatId}/user-in-chat`
  ) as Promise<ICheckUserInChatResponseData>;
};

export const getListOfMessage = (
  params: IListOfMessagesRequestParams
): Promise<IListOfMessagesResponseData> => {
  return clientHttp.get(`/chats/${params.chatId}/messages/list`, {
    lastMessageDate: params.lastMessageDate,
    limit: params.limit,
  }) as Promise<IListOfMessagesResponseData>;
};

export const getMessagesByType = (
  params: IMessagesByTypeRequestParams
): Promise<IListOfMessagesResponseData> => {
  return clientHttp.get(`/chats/${params.chatId}/messages`, {
    limit: params.limit,
    lastMessageDate: params.lastMessageDate,
    type: params.type,
  }) as Promise<IListOfMessagesResponseData>;
};

export const getMessagesInThread = (
  params: IMessagesInThreadRequestParams
): Promise<IMessagesInThreadResponseData> => {
  return clientHttp.get(
    `/chats/${params.chatId}/messages/${params.messageId}/threads/`
  ) as Promise<IMessagesInThreadResponseData>;
};

export const addLikeMessage = (data: IToggleLikeMessageRequestData) => {
  return clientHttp.post(`/messages/${data.messageUUID}/likes`);
};

export const removeLikeMessage = (data: IToggleLikeMessageRequestData) => {
  return clientHttp.delete(`/messages/${data.messageUUID}/likes`);
};

export const sendMessage = (data: ISendMessageRequestData) => {
  return clientHttp.post(`/chats/${data.chatId}/messages`, {
    type: data.type,
    content: {
      message: data.content.message,
    },
  });
};

export const respondInThread = (data: IRespondInThreadRequestData) => {
  return clientHttp.post(`/chats/${data.chatId}/messages/${data.messageId}/threads`, {
    type: data.type,
    content: {
      message: data.content.message,
    },
  });
};
