<template>
  <AppModal v-if="isShowModal" :is-show-modal="isShowModal" @toggleModal="toggleModal">
    <div class="app-modal-user-profile">
      <div class="app-modal-user-profile__header">
        <div v-if="isRedactor && false" class="app-modal-user-profile__add-image-container">
          <input
            id="add-file"
            type="file"
            name="add-file"
            :accept="acceptedFileTypes"
            class="app-modal-user-profile__add-image-input"
            multiple
            @change="uploadImage"
          />
          <app-icon class="app-modal-user-profile__icon" name="edit" />
        </div>
        <img :src="getAvatar" />
        <div class="app-modal-user-profile__header-info">
          <app-text
            :text="`${userProfile?.firstName ?? ''} ${userProfile?.lastName ?? ''}`"
            type="main-semibold"
            line-height="30"
          />
          <app-text
            :text="isRedactor ? 'Приглашен' : `Зарегистрирован ${getRegistrationDate}`"
            type="small"
            line-height="20"
          />
          <div v-if="!isRedactor" class="app-modal-user-profile__header-info-userID">
            <app-text
              :text="`UID:\r\n${user.uuid} `"
              color="secondary"
              type="small"
              line-height="20"
            />
            <app-button-icon
              name="copy"
              @click="copyToClipboard(user.uuid)"
              color="#83888F"
              icon-size="16"
            />
          </div>
        </div>
      </div>
      <div class="app-modal-user-profile__tabs">
        <div
          class="app-modal-user-profile__tabs-item"
          :class="{ 'app-modal-user-profile__tabs-item--active': activeIndexTab === 0 }"
          @click="setActiveTab(0)"
        >
          <app-text
            text="Карточка"
            line-height="25"
            type="primary"
            :color="activeIndexTab === 0 ? 'text-link' : 'secondary'"
          />
        </div>
        <!--        <div-->
        <!--          class="app-modal-user-profile__tabs-item"-->
        <!--          :class="{ 'app-modal-user-profile__tabs-item&#45;&#45;active': activeIndexTab === 1 }"-->
        <!--          @click="setActiveTab(1)"-->
        <!--        >-->
        <!--          <app-text-->
        <!--            text="Активность"-->
        <!--            line-height="25"-->
        <!--            type="primary"-->
        <!--            :color="activeIndexTab === 1 ? 'text-link' : 'secondary'"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div-->
        <!--          class="app-modal-user-profile__tabs-item"-->
        <!--          :class="{ 'app-modal-user-profile__tabs-item&#45;&#45;active': activeIndexTab === 2 }"-->
        <!--          @click="setActiveTab(2)"-->
        <!--        >-->
        <!--          <app-text-->
        <!--            text="Сертификаты"-->
        <!--            line-height="25"-->
        <!--            type="primary"-->
        <!--            :color="activeIndexTab === 2 ? 'text-link' : 'secondary'"-->
        <!--          />-->
        <!--        </div>-->
      </div>
      <div class="app-modal-user-profile__personal-info-block">
        <app-text text="Личные данные" type="main-semibold" line-height="25" />
        <div class="app-modal-user-profile__personal-info">
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Имя"
              :value="inputValueFirstName"
              @onInput="(val) => (inputValueFirstName = val)"
            />
            <app-text
              v-else-if="userProfile?.firstName"
              :text="userProfile?.firstName ?? ''"
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Почта"
              :value="invitationEmail"
              @onInput="(val) => (invitationEmail = val)"
            />
            <app-text v-else :text="user.email ?? ''" color="secondary" />
            <app-icon
              v-if="false"
              name="check-circle"
              :color="'userInfo.isEmailVerified' ? '#34A853' : '#83888F'"
              size="20"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Отчество"
              :value="inputValueMiddleName"
              @onInput="(val) => (inputValueMiddleName = val)"
            />
            <app-text v-else :text="userProfile?.middleName ?? ''" color="secondary" />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Фамилия"
              :value="inputValueLastName"
              @onInput="(val) => (inputValueLastName = val)"
            />
            <app-text v-else :text="userProfile?.lastName ?? ''" color="secondary" />
          </div>
        </div>
      </div>
      <div class="app-modal-user-profile__job-title-block">
        <app-text text="Должность" type="main-semibold" line-height="25" />
        <div class="app-modal-user-profile__job-title">
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Должность"
              :value="inputValueJobTitle"
              @onInput="(val) => (inputValueJobTitle = val)"
            />
            <app-text
              v-else-if="userProfile?.careerPath?.length"
              :text="userProfile?.careerPath[0]?.jobTitle ?? ''"
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Организация"
              :value="inputValueOrganizationName"
              @onInput="(val) => (inputValueOrganizationName = val)"
            />
            <app-text
              v-else-if="userProfile?.careerPath?.length"
              :text="userProfile?.careerPath[0]?.organizationName ?? ''"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <div class="app-modal-user-profile__geodata-block">
        <app-text text="Геоданные" type="main-semibold" line-height="25" />
        <div class="app-modal-user-profile__geodata">
          <div class="app-modal-user-profile__item">
            <app-input-dropdown
              v-if="isRedactor"
              :disabled="true"
              :list-key="'title'"
              placeholder="Введите страну"
              :is-loading="isCountriesLoading"
              @search="getCountries"
              :default-value="inputValueCountry"
              :list="searchCountries"
              @onSelect="setCountry"
            />
            <app-text
              v-else
              :text="userProfile?.countryOfResidence?.['name'] ?? ''"
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input-dropdown
              v-if="isRedactor"
              :disabled="true"
              :list-key="'title'"
              placeholder="Введите регион"
              :is-loading="isRegionsLoading"
              @search="getRegions"
              :default-value="inputValueRegion"
              :list="searchRegions"
              @onSelect="setRegion"
            />
            <app-text
              v-else
              :text="userProfile?.regionOfResidence?.['name'] ?? ''"
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input-dropdown
              v-if="isRedactor"
              :disabled="true"
              :list-key="'title'"
              placeholder="Введите город"
              :is-loading="isCitiesLoading"
              @search="getCities"
              :default-value="inputValueCity"
              :list="searchCities"
              @onSelect="setCity"
            />
            <app-text
              v-else
              :text="userProfile?.cityOfResidence?.['name'] ?? ''"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <div class="app-modal-user-profile__source-registration-block">
        <app-text text="Источник регистрации" type="main-semibold" line-height="25" />
        <div class="app-modal-user-profile__source-registration">
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Источник регистрации"
              :value="inputValueRegistrationSource"
              @onInput="(val) => (inputValueRegistrationSource = val)"
            />
            <app-text
              v-else-if="user['commonSource']"
              :text="checkRegistrationSource ?? ''"
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Дата и время регистрации"
              :value="inputValueRegistrationDate"
              @onInput="(val) => (inputValueRegistrationDate = val)"
            />
            <app-text
              v-else-if="user['registrationDate']"
              :text="getRegistrationDate ?? ''"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <div class="app-modal-user-profile__social-networks-block">
        <app-text
          text="Социальные сети и контактные данные"
          type="main-semibold"
          line-height="25"
        />
        <div class="app-modal-user-profile__social-networks">
          <div class="app-modal-user-profile__item">
            <img src="@/assets/images/socialNetworks/vk.svg" />
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Профиль VK"
              :value="inputValueProfileVk"
              @onInput="(val) => (inputValueProfileVk = val)"
            />
            <app-text
              v-else
              :text="
                userProfile?.socialNetworks?.[indexVK]?.network?.toLowerCase() === 'vk'
                  ? userProfile?.socialNetworks?.[indexVK]?.url
                  : ''
              "
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <img src="@/assets/images/socialNetworks/classmates.svg" />
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Профиль OK"
              :value="inputValueProfileOk"
              @onInput="(val) => (inputValueProfileOk = val)"
            />
            <app-text
              v-else
              :text="
                userProfile?.socialNetworks?.[indexOK]?.network?.toLowerCase() === 'ok'
                  ? userProfile?.socialNetworks?.[indexOK]?.url
                  : ''
              "
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <img src="@/assets/images/socialNetworks/telegram.svg" />
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Профиль телеграм"
              :value="inputValueProfileTg"
              @onInput="(val) => (inputValueProfileTg = val)"
            />
            <app-text
              v-else
              :text="
                userProfile?.socialNetworks?.[indexTG]?.network?.toLowerCase() === 'telegram'
                  ? userProfile?.socialNetworks?.[indexTG]?.url
                  : ''
              "
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <img src="@/assets/images/socialNetworks/e-mail.svg" />
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Ваша почта"
              :value="inputValueEmail"
              @onInput="(val) => (inputValueEmail = val)"
            />
            <app-text
              v-else
              :text="
                userProfile?.socialNetworks?.[indexMail]?.network?.toLowerCase() === 'mail'
                  ? userProfile?.socialNetworks?.[indexMail]?.url
                  : ''
              "
              color="secondary"
            />
          </div>
          <div class="app-modal-user-profile__item">
            <img src="@/assets/images/socialNetworks/phone.svg" />
            <app-input
              v-if="isRedactor"
              :disabled="true"
              placeholder="Контактный телефон"
              type="phone"
              :value="inputValuePhone"
              @onInput="(val) => (inputValuePhone = val)"
            />
            <app-text v-else :text="userProfile?.personalPhoneNumber ?? ''" color="secondary" />
          </div>
        </div>
      </div>
      <app-button v-if="isRedactor && false" text="Сохранить" @click="saveProfileInfo" />
    </div>
  </AppModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppButtonIcon, AppButton, AppInput } from '@/ui/components';
import AppInputDropdown from '@/ui/components/inputs/app-input-dropdown/AppInputDropdown.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { formattedDate } from '@/utils/custom';
import {
  IUserEvent,
  IUserInvited,
} from '@/services/UserManagementService/UserManagementServiceTypes';
import { mapActions, mapState } from 'vuex';

import { IPayloadProfileInfo, IProfile } from '@/services/ProfileService/ProfileServiceTypes';
import { EX_$Toaster } from '@/classes/content';
import { UserManagementApi } from '@/api/apis/admin/UserManagementApi';

export default defineComponent({
  name: 'AppModalManagementUserProfile',
  components: {
    AppIcon,
    AppModal,
    AppText,
    AppButtonIcon,
    AppButton,
    AppInput,
    AppInputDropdown,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isRedactor: {
      type: Boolean,
      required: true,
      default: false,
    },
    eventUuid: {
      type: String,
      required: true,
    },
    user: {
      type: Object as PropType<IUserInvited | IUserEvent>,
      required: true,
    },
  },
  data() {
    return {
      activeIndexTab: 0,
      acceptedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      inputValueFirstName: '',
      inputValueLastName: '',
      inputValueMiddleName: '',
      inputValueJobTitle: '',
      inputValueOrganizationName: '',
      inputValueProfileVk: '',
      indexVK: 0,
      indexTG: 0,
      indexOK: 0,
      indexMail: 0,
      inputValueProfileOk: '',
      inputValueProfileTg: '',
      inputValueEmail: '',
      inputValuePhone: '',
      inputValueRegistrationSource: '',
      inputValueRegistrationDate: '',
      idTimeCountry: 0 as any,
      idTimeRegion: 0 as any,
      idTimeCity: 0 as any,
      inputValueCity: {} as { id: number; title: string },
      inputValueRegion: {} as { id: number; title: string },
      inputValueCountry: {} as { id: number; title: string },
      invitationEmail: '',
      EX_Toaster: EX_$Toaster,
      userProfile: {} as IProfile,
    };
  },
  methods: {
    ...mapActions('profile', ['APatchProfileChange', 'AUploadImageProfile']),
    ...mapActions('auth', [
      'AGetSearchCities',
      'AGetSearchRegions',
      'AGetSearchCountries',
      'AClearRegions',
      'AClearCities',
      'AClearCountries',
    ]),
    copyToClipboard(value: string) {
      navigator.clipboard.writeText(value);
      this.EX_Toaster.success('Скопировано в буфер обмена');
    },
    toggleModal() {
      this.$emit('toggleModal');
    },
    async uploadImage(event: Event): Promise<any> {
      event.preventDefault();
      //@ts-ignore
      let list = [...event.target!.files];
      const formData = new FormData();
      formData.append('image', list[0]);
      await this.AUploadImageProfile(formData);
    },
    getCountries(value: string) {
      clearInterval(this.idTimeCountry);
      if (value.length >= 3) {
        this.idTimeCountry = setTimeout(() => {
          this.AGetSearchCountries(value);
        }, 500);
      } else this.AClearCountries();
    },
    getRegions(value: string) {
      clearInterval(this.idTimeRegion);
      if (value.length >= 3) {
        this.idTimeRegion = setTimeout(() => {
          this.AGetSearchRegions({ countryId: this.inputValueCountry.id, search: value });
        }, 500);
      } else this.AClearRegions();
    },
    getCities(value: string) {
      clearTimeout(this.idTimeCity);
      if (value.length >= 3) {
        this.idTimeCity = setTimeout(() => {
          this.AGetSearchCities({
            regionId: this.inputValueRegion.id,
            search: value,
            countryId: this.inputValueCountry.id,
          });
        }, 500);
      } else this.AClearCities();
    },
    setCountry(value: { id: number; name: string }) {
      this.inputValueCountry = { id: value?.id, title: value?.name };
    },
    setRegion(value: { id: number; name: string }) {
      this.inputValueRegion = { id: value?.id, title: value?.name };
    },
    setCity(value: { id: number; name: string }) {
      this.inputValueCity = { id: value?.id, title: value?.name };
    },
    setActiveTab(value: number) {
      this.activeIndexTab = value;
    },
    async saveProfileInfo() {
      const payload: IPayloadProfileInfo = {
        avatarUrl: this.imageUploadProfile,
        firstName: this.inputValueFirstName,
        middleName: this.inputValueMiddleName,
        lastName: this.inputValueLastName,
        countryOfResidence: { id: this.inputValueCountry.id, name: this.inputValueCountry.title },
        regionOfResidence: { id: this.inputValueRegion.id, name: this.inputValueRegion.title },
        cityOfResidence: { id: this.inputValueCity.id, name: this.inputValueCity.title },
        socialNetworks: [
          { url: this.inputValueProfileVk, network: 'VK' },
          { url: this.inputValueProfileOk, network: 'OK' },
          { url: this.inputValueProfileTg, network: 'TELEGRAM' },
          { url: this.inputValueEmail, network: 'MAIL' },
        ],
        organizationName: this.inputValueOrganizationName,
        personalPhoneNumber: this.inputValuePhone,
        inputValueRegistrationSource: this.inputValueRegistrationSource,
        inputValueRegistrationDate: this.inputValueRegistrationDate,
      };
      if (!this.imageUploadProfile) {
        delete payload.avatarUrl;
      }
      if (!this.inputValueFirstName) {
        delete payload.firstName;
      }
      if (!this.inputValueMiddleName) {
        delete payload.middleName;
      }
      if (!this.inputValueLastName) {
        delete payload.lastName;
      }
      if (!this.inputValueJobTitle) {
        delete payload.currentJobTitle;
      }
      if (!this.inputValueCountry.id) {
        delete payload.countryOfResidence;
      }
      if (!this.inputValueRegion.id) {
        delete payload.regionOfResidence;
      }
      if (!this.inputValueCity.id) {
        delete payload.cityOfResidence;
      }
      if (
        !this.inputValueProfileVk &&
        !this.inputValueProfileOk &&
        !this.inputValueProfileTg &&
        !this.inputValueEmail
      ) {
        delete payload.socialNetworks;
      }
      if (!this.inputValueOrganizationName) {
        delete payload.organizationName;
      }
      if (!this.inputValuePhone) {
        delete payload.personalPhoneNumber;
      }
      if (!this.inputValueRegistrationSource) {
        delete payload.inputValueRegistrationSource;
      }
      if (!this.inputValueRegistrationDate) {
        delete payload.inputValueRegistrationDate;
      }
      // await this.APatchProfileChange(payload);
    },
  },
  computed: {
    ...mapState('profile', ['profileInfo', 'imageUploadProfile', 'isFetchingImageUploadProfile']),
    ...mapState('auth', [
      'searchCities',
      'isCitiesLoading',
      'searchRegions',
      'isRegionsLoading',
      'searchCountries',
      'isCountriesLoading',
    ]),
    getAvatar(): string {
      return this.userProfile?.avatarUrl
        ? this.userProfile?.avatarUrl
        : require(`@/assets/images/chat/user.svg`);
    },
    getRegistrationDate(): string {
      if ('registrationDate' in this.user) {
        return `${formattedDate(this.user.registrationDate, 'dateFullYear')} ${formattedDate(
          this.user.registrationDate,
          'onlyTime'
        )}`;
      } else {
        return '';
      }
    },
    checkRegistrationSource(): string {
      if ('commonSource' in this.user) {
        switch (this.user.commonSource) {
          case 'SELF_REGISTRATION':
            return 'Зарегистрировался сам';
          case 'INVITATION':
            return 'По приглашению';
          case 'REFERRAL_LINK':
            return 'По реферальной ссылке';
          default:
            return '';
        }
      } else {
        return '';
      }
    },
  },
  async mounted() {
    // TODO компонент покрыт игнорами и костылями, потому что требует полного рефакторинга
    this.userProfile = await UserManagementApi.getUserProfile({
      eventUUID: this.eventUuid,
      userUUID: this.user.uuid,
    }).catch((e) => e);

    this.inputValueFirstName = this.userProfile?.firstName ?? '';
    this.inputValueMiddleName = this.userProfile?.middleName ?? '';
    this.inputValueLastName = this.userProfile?.lastName ?? '';
    this.inputValueJobTitle = this.userProfile?.careerPath?.[0]?.jobTitle ?? '';
    this.inputValueOrganizationName = this.userProfile?.careerPath?.[0]?.organizationName ?? '';
    // @ts-ignore
    this.inputValueRegistrationSource = this.userProfile?.registrationSource ?? '';
    // @ts-ignore
    this.inputValueRegistrationDate = this.userProfile?.registrationDate ?? '';
    this.inputValuePhone = this.userProfile?.personalPhoneNumber ?? '';
    this.inputValueRegion = {
      // @ts-ignore
      id: this.userProfile?.regionOfResidence?.id,
      // @ts-ignore
      title: this.userProfile?.regionOfResidence?.name,
    };
    this.inputValueCountry = {
      // @ts-ignore
      id: this.userProfile?.countryOfResidence?.id,
      // @ts-ignore
      title: this.userProfile?.countryOfResidence?.name,
    };
    this.inputValueCity = {
      // @ts-ignore
      id: this.userProfile?.cityOfResidence?.id,
      // @ts-ignore
      title: this.userProfile?.cityOfResidence?.name,
    };
    // @ts-ignore
    for (let i = 0; i < this.userProfile?.socialNetworks?.length; i++) {
      // @ts-ignore
      if ('vk' === this.userProfile?.socialNetworks[i].network?.toLowerCase()) {
        this.indexVK = i;
        this.inputValueProfileVk = this.userProfile?.socialNetworks[i].url;
        // @ts-ignore
      } else if ('ok' === this.userProfile?.socialNetworks[i].network?.toLowerCase()) {
        this.indexOK = i;
        this.inputValueProfileOk = this.userProfile?.socialNetworks[i].url;
        // @ts-ignore
      } else if ('telegram' === this.userProfile?.socialNetworks[i].network?.toLowerCase()) {
        this.indexTG = i;
        this.inputValueProfileTg = this.userProfile?.socialNetworks[i].url;
        // @ts-ignore
      } else if ('mail' === this.userProfile?.socialNetworks[i].network?.toLowerCase()) {
        this.indexMail = i;
        this.inputValueEmail = this.userProfile?.socialNetworks[i].url;
      }
    }

    this.invitationEmail = this.user.email;
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss">
.app-modal-user-profile__item {
  position: relative;
  &:nth-child(2) {
    .app-input__inner {
      width: 235px !important;
    }
  }
  .app-input__inner {
    box-sizing: border-box;
    width: 259px !important;
    height: 45px !important;
    padding: 17.5px 0;
    border-radius: 8px !important;
  }
  .app-input__empty {
    display: none;
  }
  .app-icon {
    position: absolute;
    z-index: 99;
    right: 10px;
  }
}
.app-modal-user-profile__social-networks {
  .app-input__inner {
    box-sizing: border-box;
    width: 239px !important;
    border-radius: 8px !important;
  }
}
</style>
