import clientHttp from '@/utils/custom/http/СlientHttp';
import { IChangeProfileInfo, IProfileInfo } from '@/services/ProfileService/ProfileServiceTypes';

export const getProfileInfo = () => {
  return clientHttp.get('/profile/user/my') as Promise<IProfileInfo>;
};

export const changeProfilePersonalInfo = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-personal-info', data);
};
export const changeProfileAvatar = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-avatar-url', data);
};
export const changeProfileDescription = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-short-bio', data);
};
export const changeProfileCareer = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-career-path', data);
};
export const changeProfileEducation = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-education-career-path', data);
};
export const changeProfilePortfolio = (data: IChangeProfileInfo) => {
  return clientHttp.patch('/profile/user/my/edit-portfolio', data);
};
