<template>
  <div class="all-modals">
    <AppModalAuth
      v-if="EX_$ModalClass.getIsShowModal('appModalAuth')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalAuth')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalAuth')"
    />
    <app-modal-registration
      v-if="EX_$ModalClass.getIsShowModal('appModalRegistration')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRegistration')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRegistration', true)"
    />
    <AppModalRecoveryByEmail
      v-if="EX_$ModalClass.getIsShowModal('appModalRecoveryByEmail')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRecoveryByEmail')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRecoveryByEmail')"
    />
    <app-modal-recovery-password
      v-if="EX_$ModalClass.getIsShowModal('appModalRecoveryPassword')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRecoveryPassword')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRecoveryPassword')"
    />
    <app-modal-start
      v-if="EX_$ModalClass.getIsShowModal('appModalStart')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalStart')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalStart')"
    />
    <app-modal-registration-phone
      v-if="EX_$ModalClass.getIsShowModal('appModalRegistrationPhone')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRegistrationPhone')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRegistrationPhone')"
    />
    <app-modal-recovery-by-phone
      v-if="EX_$ModalClass.getIsShowModal('appModalRecoveryByPhone')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRecoveryByPhone')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRecoveryByPhone')"
    />
    <app-modal-recognized
      v-if="EX_$ModalClass.getIsShowModal('appModalRecognized')"
      :isShowModal="EX_$ModalClass.getIsShowModal('appModalRecognized')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRecognized')"
    />
    <app-modal-registration-success
      v-if="EX_$ModalClass.getIsShowModal('appModalRegistrationSuccess')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalRegistrationSuccess')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalRegistrationSuccess')"
    />
    <app-modal-edit-user
      v-if="EX_$ModalClass.getIsShowModal('appModalEditUser')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEditUser')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEditUser')"
    />
    <app-modal-edit-description
      v-if="EX_$ModalClass.getIsShowModal('appModalEditDescription')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEditDescription')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEditDescription')"
    />
    <app-modal-event-image
      v-if="EX_$ModalClass.getIsShowModal('appModalEventImage')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventImage')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventImage')"
    />
    <app-modal-event-speakers
      v-if="EX_$ModalClass.getIsShowModal('appModalEventSpeakers')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventSpeakers')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventSpeakers')"
    />
    <app-modal-event-video
      v-if="EX_$ModalClass.getIsShowModal('appModalEventVideo')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventVideo')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventVideo')"
      modal-name="appModalEventVideo"
    />
    <app-modal-event-video
      v-if="EX_$ModalClass.getIsShowModal('appModalEventVideoFullBlock')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventVideoFullBlock')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventVideoFullBlock')"
      modal-name="appModalEventVideoFullBlock"
    />
    <app-modal-event-video
      v-if="EX_$ModalClass.getIsShowModal('appModalEventVideoWithTextFullBlock')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventVideoWithTextFullBlock')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventVideoWithTextFullBlock')"
      modal-name="appModalEventVideoWithTextFullBlock"
    />
    <AppModalEventImages
      v-if="EX_$ModalClass.getIsShowModal('appModalEventImages')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventImages')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventImages')"
      modal-name="appModalEventImages"
    />
    <AppModalEventImages
      v-if="EX_$ModalClass.getIsShowModal('appModalEventGalleryFullBlock')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventGalleryFullBlock')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventGalleryFullBlock')"
      modal-name="appModalEventGalleryFullBlock"
    />
    <AppModalEventImages
      v-if="EX_$ModalClass.getIsShowModal('appModalEventGalleryWithTextFullBlock')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventGalleryWithTextFullBlock')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventGalleryWithTextFullBlock')"
      modal-name="appModalEventGalleryWithTextFullBlock"
    />
    <app-modal-program-presentations
      v-if="EX_$ModalClass.getIsShowModal('appModalProgramPresentations')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalProgramPresentations')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalProgramPresentations')"
    />
    <app-modal-calendar-broadcast
      v-if="EX_$ModalClass.getIsShowModal('appModalCalendarBroadcast')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalCalendarBroadcast')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalCalendarBroadcast')"
    />
    <app-modal-calendar-record
      v-if="EX_$ModalClass.getIsShowModal('appModalCalendarRecord')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalCalendarRecord')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalCalendarRecord')"
    />
    <AppModalCalendarSpeakers
      v-if="EX_$ModalClass.getIsShowModal('appModalCalendarSpeakers')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalCalendarSpeakers')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalCalendarSpeakers')"
    />
    <app-modal-event-slider
      v-if="EX_$ModalClass.getIsShowModal('appModalEventSlider')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventSlider')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventSlider')"
      modal-name="appModalEventSlider"
    />
    <app-modal-event-slider
      v-if="EX_$ModalClass.getIsShowModal('appModalEventSliderFullBlock')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalEventSliderFullBlock')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalEventSliderFullBlock')"
      modal-name="appModalEventSliderFullBlock"
    />
    <app-modal-confirm-exit
      v-if="EX_$ModalClass.getIsShowModal('appModalConfirmExit')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalConfirmExit')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalConfirmExit')"
    />
    <app-modal-technical-work
      v-if="EX_$ModalClass.getIsShowModal('appModalTechnicalWork')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalTechnicalWork')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalTechnicalWork')"
    />
    <app-modal-access-denied
      v-if="EX_$ModalClass.getIsShowModal('appModalAccessDenied')"
      :is-show-modal="EX_$ModalClass.getIsShowModal('appModalAccessDenied')"
      @toggleModal="EX_$ModalClass.toggleModal('appModalAccessDenied')"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { EX_$Modal } from '@/classes/content';
import {
  AppModalAuth,
  AppModalCalendarBroadcast,
  AppModalCalendarRecord,
  AppModalCalendarSpeakers,
  AppModalConfirmExit,
  AppModalEditDescription,
  AppModalEditUser,
  AppModalEventImage,
  AppModalEventImages,
  AppModalEventSlider,
  AppModalEventSpeakers,
  AppModalEventVideo,
  AppModalProgramPresentations,
  AppModalRecognized,
  AppModalRecoveryByEmail,
  AppModalRecoveryByPhone,
  AppModalRecoveryPassword,
  AppModalRegistration,
  AppModalRegistrationPhone,
  AppModalRegistrationSuccess,
  AppModalStart,
} from '@/ui/components/modals';
import AppModalTechnicalWork from '@/ui/components/modals/app-modal-technical-work/AppModalTechnicalWork.vue';
import AppModalAccessDenied from '@/ui/components/modals/app-modal-access-denied/AppModalAccessDenied.vue';

export default Vue.extend({
  name: 'HomeView',
  components: {
    AppModalAccessDenied,
    AppModalTechnicalWork,
    // AppModalReferralLinks,
    AppModalRecoveryPassword,
    AppModalAuth,
    AppModalRegistration,
    // AppModalRegistration2,
    AppModalRecoveryByEmail,
    AppModalStart,
    AppModalRegistrationPhone,
    AppModalRecoveryByPhone,
    AppModalRecognized,
    AppModalRegistrationSuccess,
    AppModalEditUser,
    AppModalEditDescription,
    AppModalEventImage,
    AppModalEventSpeakers,
    AppModalEventVideo,
    AppModalEventImages,
    // AppModalPickDateRegistration,
    AppModalProgramPresentations,
    // AppModalInviteUser,
    AppModalCalendarBroadcast,
    AppModalCalendarSpeakers,
    AppModalCalendarRecord,
    AppModalConfirmExit,
    AppModalEventSlider,
  },
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
    };
  },
});
</script>

<style lang="scss" scoped>
@use 'style';
</style>
