import {
  IChatInfo,
  ICheckUserInChatResponseData,
  IMessageType,
} from '@/services/ChatService/ChatServiceTypes';
import { IMessagesInThreadResponseData } from '@/services/ChatService/ChatServiceTypes';

export interface IState {
  chatInfo: IChatInfo;
  firstMessageSentAt?: string;
  messages?: Array<IMessageType>;
  threadMessages: Array<IMessagesInThreadResponseData>;
  chatAccess: ICheckUserInChatResponseData;
  isFetchingMoreMessage: boolean;
}
export default (): IState => ({
  chatInfo: {} as IChatInfo,
  firstMessageSentAt: '',
  messages: [],
  threadMessages: [],
  chatAccess: {},
  isFetchingMoreMessage: false,
});
