import clientHttp from '@/utils/custom/http/СlientHttp';
import MMCOHttp from '@/utils/custom/http/MMCOHttp';
import { INewsItem } from '@/helpers/types/admin/admin-news.types';
import router from '@/router';
import { omit } from 'lodash';

export class NewsApi {
  public static async getNewsList(publish?: boolean): Promise<{ news: INewsItem[] }> {
    const slug = router.currentRoute.params.slug ?? '';
    return clientHttp.get(`/events/${slug}/news/`, { published: publish });
  }
  public static async getNewsInfo(uuid: string): Promise<INewsItem> {
    const slug = router.currentRoute.params.slug ?? '';
    return clientHttp.get(`/events/${slug}/news/${uuid}`);
  }
  public static async createNews(data: INewsItem): Promise<void> {
    const slug = router.currentRoute.params.slug ?? '';
    await clientHttp.post(`/events/${slug}/news/`, data);
  }
  public static async updateNews(uuid: string, data: INewsItem): Promise<void> {
    const slug = router.currentRoute.params.slug ?? '';
    const changedData = omit(data, 'visits');

    await clientHttp.put(`/events/${slug}/news/${uuid}`, changedData);
  }

  public static async deleteNews(uuid: string): Promise<void> {
    const slug = router.currentRoute.params.slug ?? '';
    await clientHttp.delete(`/events/${slug}/news/${uuid}`);
  }

  public static async uploadImage(formData: FormData): Promise<{ url: string }> {
    return MMCOHttp.postFile(`/upload/image`, formData);
  }

  public static async addVisit(uuid: string): Promise<void> {
    const slug = router.currentRoute.params.slug ?? '';
    await clientHttp.post(`/events/${slug}/news/${uuid}/add-visit`);
  }
}
