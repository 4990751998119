// import {
//   IEventUsersResponseData,
//   IInvitedUsersResponseData,
//   IReferralLink,
// } from '@/services/UserManagementService/UserManagementServiceTypes';
// import { IProfile } from '@/services/ProfileService/ProfileServiceTypes';

// export interface IState {
//   invitedUsers: IInvitedUsersResponseData;
//   eventUsers: IEventUsersResponseData;
//   referralList: Array<IReferralLink>;
//   userProfile: IProfile;
// }
export default () => ({
  // invitedUsers: {},
  // eventUsers: {} as IEventUsersResponseData,
  // referralList: [],
  // userProfile: {},
  // eventUsersPageParams: {
  //   eventUUID: '',
  //   page: 0,
  //   showBy: 0,
  // },
});
