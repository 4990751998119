<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Изображения"
  >
    <div class="app-modal-event-images">
      <app-input
        v-if="
          (modalName === 'appModalEventImages' && getOptionForBlock('INFO') === 'variant_5') ||
          modalName === 'appModalEventGalleryWithTextFullBlock'
        "
        label="Текст"
        placeholder="Введите текст описания блока"
        :note="`${inputValueTitle.length}/10000`"
        :errors="errorInputValueTitle"
        :maxlength="10010"
        :value="inputValueTitle"
        tag="textarea"
        @onInput="onInputChangeTitle"
      />
      <div :key="countKey" class="app-modal-event-images__container">
        <div
          v-for="(item, index) in eventImagesList"
          :key="item.id"
          class="app-modal-event-images__item"
          :class="{ error: item.error && !item.image }"
          :style="`background: url(${backgroundImages[index]})  center center/cover no-repeat`"
        >
          <div
            class="app-modal-event-images__input-container"
            :class="{ without_background: item.image }"
          >
            <div
              v-if="isFetchingInfoBlockImageUpload"
              class="app-modal-event-images__spinner-container"
            >
              <app-spinner size="small" />
            </div>
            <img class="app-modal-event-images__image" v-else-if="item.image" :src="item.image" />
            <input
              v-else
              id="add-file"
              type="file"
              name="add-file"
              :accept="acceptedFileTypes"
              class="app-modal-event-images__add-image-input"
              multiple
              @change="uploadImage($event, index, item.id)"
            />
            <app-button-icon
              v-if="item.image"
              name="x"
              color="#FFFFFF"
              icon-size="16"
              @click="deleteImage(item.id)"
            />
            <div
              v-if="!item.image && !isFetchingInfoBlockImageUpload"
              class="app-modal-event-images__info"
            >
              <app-icon name="plus" size="18" color="#FFFFFF" />
              <app-text
                text="Переместите фото с вашего пк в эту область или нажмите “+”"
                type="small"
                line-height="20"
                color="text-on-dark-theme"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-text
      class="app-modal-event-images__notification"
      v-if="failedUploadImageErrorCode === 422"
      text="Загрузите изображение в формате: jpg, jpeg, png, не
            более 10 МБ"
      type="small"
      color="error"
    />
    <app-button text="Сохранить" @click="saveChanges" />
  </AppModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppButton, AppInput } from '@/ui/components';
import AppButtonIcon from '@/ui/components/buttons/app-button-icon/AppButtonIcon.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';
import { mapActions, mapState } from 'vuex';
import { EX_$Toaster } from '@/classes/content';
import { TBlockName } from '@/views/admin/constructor-event/ConstructorEventPageTypes';
import { ICustomizationBlock } from '@/services/AdminService/AdminServiceTypes';
import { IInfoBlock } from '@/services/EventService/EventServiceTypes';

export default defineComponent({
  name: 'AppModalEventImages',
  components: {
    AppModal,
    AppText,
    AppIcon,
    AppButton,
    AppSpinner,
    AppInput,
    AppButtonIcon,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      acceptedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      inputValueTitle: '',
      errorInputValueTitle: [] as Array<string>,
      eventImagesList: [
        { id: Math.random(), image: '' },
        { id: Math.random(), image: '' },
        { id: Math.random(), image: '' },
        { id: Math.random(), image: '' },
        { id: Math.random(), image: '' },
      ] as Array<{ id: number; image: string; error?: Array<string> }>,
      countKey: 0,
    };
  },
  mounted() {
    switch (this.modalName) {
      case 'appModalEventImages':
        {
          if (this.changedInfoBlockDescription) {
            this.inputValueTitle = this.changedInfoBlockDescription;
          } else this.inputValueTitle = this.eventInfo?.infoBlock?.description ?? '';
          if (this.changedEventImages.length) {
            this.eventImagesList = this.changedEventImages.map((item: string) => {
              return {
                id: Math.random(),
                image: item,
              };
            });
          } else if (this.eventInfo?.infoBlock?.imageUrls?.length) {
            this.eventImagesList = this.eventInfo?.infoBlock?.imageUrls.map((item: string) => {
              return {
                id: Math.random(),
                image: item,
              };
            });
          } else {
            this.eventImagesList = [
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
            ] as Array<{ id: number; image: string; error?: Array<string> }>;
          }
        }
        break;
      case 'appModalEventGalleryFullBlock':
        {
          if (this.changedBlockGalleryImages.length) {
            this.eventImagesList = this.changedBlockGalleryImages.map((item: string) => {
              return {
                id: Math.random(),
                image: item,
              };
            });
          } else if (this.eventInfo?.infoBlock?.listOfInfo?.galleryBlockImageUrls?.length) {
            this.eventImagesList =
              this.eventInfo?.infoBlock?.listOfInfo?.galleryBlockImageUrls?.map((item: string) => {
                return {
                  id: Math.random(),
                  image: item,
                };
              });
          } else {
            this.eventImagesList = [
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
            ] as Array<{ id: number; image: string; error?: Array<string> }>;
          }
        }
        break;
      case 'appModalEventGalleryWithTextFullBlock':
        {
          if (this.changedBlockGalleryWithTextText) {
            this.inputValueTitle = this.changedBlockGalleryWithTextText;
          } else
            this.inputValueTitle =
              this.eventInfo?.infoBlock?.listOfInfo?.galleryWithTextBlockText ?? '';
          if (this.changedBlockGalleryWithTextImages.length) {
            this.eventImagesList = this.changedBlockGalleryWithTextImages.map((item: string) => {
              return {
                id: Math.random(),
                image: item,
              };
            });
          } else if (this.eventInfo?.infoBlock?.listOfInfo?.galleryWithTextBlockImageUrls?.length) {
            this.eventImagesList =
              this.eventInfo?.infoBlock?.listOfInfo?.galleryWithTextBlockImageUrls?.map(
                (item: string) => {
                  return {
                    id: Math.random(),
                    image: item,
                  };
                }
              );
          } else {
            this.eventImagesList = [
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
              { id: Math.random(), image: '' },
            ] as Array<{ id: number; image: string; error?: Array<string> }>;
          }
        }
        break;
    }
  },
  methods: {
    ...mapActions('admin', [
      'AChangeEventImages',
      'AChangeInfoBlockDescription',
      'ClearFailedUploadImageErrorCode',
      'AUploadImageEvent',
      'changeBlockGallery',
      'changeBlockGalleryWithText',
    ]),
    toggleModal() {
      this.$emit('toggleModal');
    },
    onInputChangeTitle(value: string) {
      this.inputValueTitle = value;
      if (value.length > 10000) {
        this.errorInputValueTitle = [`${value.length} / 10000`];
      } else {
        this.errorInputValueTitle = [];
      }
    },
    deleteImage(id: number) {
      this.eventImagesList.forEach((item) => {
        if (item.id === id) {
          return (item.image = '');
        }
      });
    },
    async uploadImage(event: Event, uploadIndex: number, id: number): Promise<any> {
      event.preventDefault();
      //@ts-ignore
      let list = [...event.target!.files];
      const formData = new FormData();
      formData.append('image', list[0]);
      await this.AUploadImageEvent(formData);
      if (this.failedUploadImageErrorCode === 422) {
        this.eventImagesList[uploadIndex].error = ['Некорректная картинка'];
      } else {
        this.eventImagesList.forEach((item) => {
          if (item.id === id) {
            return (item.image = this.infoBlockImage);
          }
        });
        delete this.eventImagesList[uploadIndex].error;
      }
      this.countKey++;
    },

    getOptionForBlock(blockName: TBlockName) {
      let option: string | null = '';
      this.eventInfo?.eventBlock?.forEach((item: ICustomizationBlock) => {
        if (item.blockName === blockName) {
          option = item.option;
        }
      });
      return option;
    },
    checkOnError() {
      for (let i = 0; i < this.eventImagesList.length; i++) {
        if (this.eventImagesList[i]?.image !== '') {
          delete this.eventImagesList[i].error;
        } else {
          this.eventImagesList[i].error = ['Добавьте картинку'];
        }
      }
    },
    saveChanges() {
      this.checkOnError();
      this.ClearFailedUploadImageErrorCode();
      const errorList = this.eventImagesList.map((item) => Object.keys(item).includes('error'));
      const payload = this.eventImagesList.map((item) => item.image);
      switch (this.modalName) {
        case 'appModalEventImages':
          {
            if (this.getOptionForBlock('INFO') === 'variant_5') {
              if (
                this.inputValueTitle &&
                this.inputValueTitle.length <= 10000 &&
                !errorList.includes(true)
              ) {
                this.AChangeInfoBlockDescription(this.inputValueTitle);
                this.AChangeEventImages(payload);
                this.toggleModal();
              } else {
                if (!this.inputValueTitle) {
                  this.errorInputValueTitle = ['Обязательное поле'];
                } else if (this.inputValueTitle.length > 10000) {
                  this.errorInputValueTitle = ['Недопустимое количество символов (не более 10000)'];
                }
              }
            } else {
              if (!errorList.includes(true)) {
                this.AChangeEventImages(payload);
                this.toggleModal();
              }
            }
          }
          break;

        case 'appModalEventGalleryFullBlock':
          {
            if (!errorList.includes(true)) {
              this.changeBlockGallery(payload);
              this.toggleModal();
            }
          }
          break;

        case 'appModalEventGalleryWithTextFullBlock':
          {
            if (
              this.inputValueTitle &&
              this.inputValueTitle.length <= 10000 &&
              !errorList.includes(true)
            ) {
              this.changeBlockGalleryWithText({ urls: payload, text: this.inputValueTitle });
              this.toggleModal();
            } else {
              if (!this.inputValueTitle) {
                this.errorInputValueTitle = ['Обязательное поле'];
              } else if (this.inputValueTitle.length > 10000) {
                this.errorInputValueTitle = ['Недопустимое количество символов (не более 10000)'];
              }
            }
          }
          break;
      }
      this.countKey++;
    },
  },
  computed: {
    ...mapState('admin', [
      'changedEventImages',
      'changedInfoBlockDescription',
      'failedUploadImageErrorCode',
      'isFetchingInfoBlockImageUpload',
      'infoBlockImage',
      'changedBlockGalleryImages',
      'changedBlockGalleryWithTextText',
      'changedBlockGalleryWithTextImages',
    ]),
    ...mapState('event', ['eventInfo']),
    backgroundImages() {
      const infoBlock = this.eventInfo?.infoBlock as IInfoBlock | undefined;
      console.log(infoBlock);
      switch (this.modalName) {
        case 'appModalEventImages':
          return infoBlock?.imageUrls ?? [];
        case 'appModalEventGalleryFullBlock':
          return infoBlock?.listOfInfo?.galleryBlockImageUrls ?? [];
        case 'appModalEventGalleryWithTextFullBlock':
          return infoBlock?.listOfInfo?.galleryWithTextBlockImageUrls ?? [];
        default:
          return [];
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
.app-modal-event-images {
  .app-input__inner_input {
    resize: none;
    min-height: 100px;
    &::-webkit-scrollbar {
      width: 10px;

      &-thumb {
        border-radius: 6px;
        background-color: var(--client-color);
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &-track {
        background: white;
        border-radius: 6px;
      }
    }
  }
  &__item {
    .app-button-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute !important;
      background: #83888f !important;
      right: -5px;
      top: -5px;
      padding-left: 1px;
      box-sizing: border-box;
      border: 2px solid #ffffff;
    }
  }
}
</style>
