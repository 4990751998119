import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import router from '@/router';
import { getUserUUID } from '@/utils/custom/roles/Roles';

export class YandexMetrikaUtil {
  // TODO убрать ям
  public static reachGoal(goalName: string, params: { [key: string]: any }): void {
    const $metrika = (Vue as any).$metrika;
    if ($metrika) {
      $metrika.reachGoal(goalName, params);
    }
  }
  // TODO убрать в отдельную папку inits
  public static init(): void {
    if (process.env.NODE_ENV !== 'production') return;
    Vue.use(VueYandexMetrika, {
      id: process.env.VUE_APP_YM_ID,
      router: router,
      env: 'production',
      // debug: true,
      options: {
        clickmap: true,
        trackLinks: true,
        webvisor: true,
        accurateTrackBounce: true,
      },
    });
  }

  // TODO убрать ям
  public static reachGoalVisitEventPage(): void {
    const userUUID = getUserUUID();
    this.reachGoal('visitEventPage', {
      userUUID,
    });
  }
  // TODO убрать ям
  public static reachGoalProgramRecordedStreamInteraction(interactionSeconds: number): void {
    const userUUID = getUserUUID();
    this.reachGoal('programRecordedStreamInteraction', {
      userUUID,
      interactionSeconds: String(interactionSeconds),
    });
  }
  // TODO убрать ям
  public static reachGoalProgramActiveStreamInteraction(interactionSeconds: number): void {
    const userUUID = getUserUUID();
    // Используется для записи в статистику на странице "Зарегистрированные пользователи"
    this.reachGoal('programRecordedStreamInteraction', {
      userUUID,
      interactionSeconds: String(interactionSeconds),
    });
    this.reachGoal('programActiveStreamInteraction', {
      userUUID,
      interactionSeconds: String(interactionSeconds),
    });
  }
}
