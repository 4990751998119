import clientHttp from '@/utils/custom/http/СlientHttp';
import { IAuthPayload } from '@/services/AuthService/AuthServiceTypes';

export class AuthApi {
  public static async loginByEmailAndPassword(body: IAuthPayload): Promise<any> {
    return clientHttp.post('auth/login-by-email-and-password', body);
  }
  public static async logout(): Promise<void> {
    await clientHttp.post('/auth/logout');
  }
}
