<template>
  <i class="app-icon" :class="`icon-${name}`" :style="iconStyle" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { TIconName } from './AppIconTypes';

export default Vue.extend({
  name: 'AppIcon',
  props: {
    name: {
      type: String as PropType<TIconName>,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '24',
    },
    color: {
      type: String,
      default: '#111111',
    },
    colorHover: {
      type: String,
    },
  },
  computed: {
    iconStyle(): object {
      return {
        '--fontSize': `${this.size}px`,
        '--color': `${this.color}`,
        '--hoverColor': this.colorHover ? this.colorHover : this.color,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
