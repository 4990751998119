let observers: any[] = [];

class $Observer {
  context: any;

  setContext(context: object) {
    this.context = context;
  }

  subscribe(name: string, fn: any) {
    observers.push({
      name,
      fn,
    });
  }

  unsubscribe(name: string): void {
    observers = observers.filter((subscriber) => subscriber.name !== name);
  }

  broadcast(name: string, data?: any): void {
    observers.forEach((subscriber) => {
      if (subscriber.name === name) {
        subscriber.fn(data);
      }
    });
  }

  checkObservers(): void {
    //
  }
}

export default new $Observer();
