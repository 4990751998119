import { ISpeakerCardInfo } from '@/services/EventService/EventServiceTypes';

export const DEFAULT_SPEAKER_INFO: ISpeakerCardInfo = {
  image: '',
  email: '',
  name: '',
  jobTitle: '',
  description: '',
};
export const DEFAULT_SPEAKER_IMAGE_SCALE = 1;

export const SPEAKER_FORM_MAX_LENGTH = {
  email: 254,
  firstName: 20,
  lastName: 20,
  middleName: 20,
  description: 120,
  jobTitle: 130,
};
