export function usernameSplitter(userName?: string) {
  if (userName) {
    const splitUserName = userName.split(' ');
    return {
      firstName: splitUserName[1] ?? '',
      lastName: splitUserName[0] ?? '',
      middleName: splitUserName[2] ?? '',
    };
  } else {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
    };
  }
}
export function usernameJoiner(firstName?: string, lastName?: string, middleName?: string) {
  return [lastName?.trim(), firstName?.trim(), middleName?.trim()].filter(Boolean).join(' ');
}
