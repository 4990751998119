<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Презентации"
  >
    <div class="app-modal-event-presentations">
      <div
        v-for="(item, index) in presentationsList"
        :key="index"
        class="app-modal-event-presentations__item"
      >
        <div class="app-modal-event-presentations__file-image">
          <img :src="require(`@/assets/images/documents/${getFileImages(item.name ?? '')}`)" />
          <div class="close">
            <app-button-icon name="x" icon-size="16" color="#FFFFFF" @click="deleteFile(index)" />
          </div>
        </div>
        <div class="app-modal-event-presentations__item-text">
          <app-text :text="item.name" />
        </div>
      </div>

      <div class="app-modal-event-presentations__item">
        <div class="app-modal-event-presentations__add-file">
          <div class="app-modal-event-presentations__add-file-container">
            <input
              id="add-file"
              type="file"
              name="add-file"
              class="app-modal-event-presentations__add-file-input"
              multiple
              @change="uploadFile"
            />
          </div>
          <div v-if="isFetchingUploadFile" class="app-modal-event-documents__spinner-container">
            <app-spinner size="small" />
          </div>
          <app-icon
            v-else
            class="app-modal-event-presentations__add-file-icon"
            name="plus"
            color="#83888F"
            size="24"
          />
        </div>
        <div class="app-modal-event-presentations__item-new">
          <app-text text="Новый документ" line-height="25" />
        </div>
      </div>
    </div>

    <app-button text="Продолжить" @click="savePresentations" />
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppButtonIcon from '@/ui/components/buttons/app-button-icon/AppButtonIcon.vue';
import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';
import { AppButton, AppText } from '@/ui/components';
import { mapActions, mapState } from 'vuex';
import { IDocument } from '@/services/EventService/EventServiceTypes';
import { getFileImages } from '@/utils/custom';

export default Vue.extend({
  components: {
    AppModal,
    AppIcon,
    // AppInput,
    AppButtonIcon,
    AppButton,
    AppText,
    AppSpinner,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fileName: '',
      activeIndex: 0,
      presentationsList: [] as Array<{ name?: string; fileURL?: string }>,
      presentationsInputValue: '',
    };
  },
  methods: {
    getFileImages,
    ...mapActions('admin', [
      'ASetSpeakerPresentations',
      'AUploadFileCreateProgram',
      'AUpdateProgram',
    ]),
    ...mapActions('program', ['AGetProgramInfo']),
    ...mapActions('admin', ['AGetAdminPrograms']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    /**
     * @description Загружаем презентацию и добавляем в массив презентаций
     */
    async uploadFile(event: Event): Promise<any> {
      event.preventDefault();
      //@ts-ignore
      let list = [...event.target!.files];
      const formData = new FormData();
      formData.append('file', list[0], `${list[0].name}`);
      this.fileName = list[0].name;
      await this.AUploadFileCreateProgram(formData);
      this.presentationsList.push({
        name: this.fileName,
        fileURL: this.fileUpload,
      });
    },
    /**
     * @description Удаляем выбранную презентацию
     * @param {number} indexDelete
     */
    deleteFile(indexDelete: number) {
      this.presentationsList = this.presentationsList.filter(
        (item, index) => indexDelete !== index
      );
    },
    async savePresentations() {
      if (this.selectedProgramId) {
        await this.AUpdateProgram({
          eventUUID: this.eventInfo.uuid,
          programUUID: this.selectedProgramId,
          presentations: this.presentationsList,
        });
      } else this.ASetSpeakerPresentations(this.presentationsList);
      this.AGetAdminPrograms({
        eventUUID: this.eventInfo.uuid,
      });
      this.toggleModal();
    },
  },
  computed: {
    ...mapState('admin', [
      'speakerPresentations',
      'fileUpload',
      'isFetchingUploadFile',
      'selectedProgramId',
    ]),
    ...mapState('program', ['programInfo']),
    ...mapState('event', ['eventInfo']),
  },
  async mounted() {
    await this.AGetProgramInfo({
      eventUUID: this.eventInfo.uuid,
      programUUID: this.selectedProgramId,
    });
    if (this.selectedProgramId) {
      if (this.speakerPresentations.length) {
        this.presentationsList = this.speakerPresentations.map((item: IDocument) => {
          return { fileURL: item.fileURL, name: item.name };
        });
      } else {
        this.presentationsList = this.programInfo?.speakerPresentations.map((item: IDocument) => {
          return { fileURL: item.fileURL, name: item.name };
        });
      }
    } else this.presentationsList = [];
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
