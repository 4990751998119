type TTheme = 'default' | 'dark';

export class $Theme {
  private _currentTheme = 'default';

  get currentTheme(): string {
    return this._currentTheme;
  }

  public set(theme?: TTheme): void {
    this._currentTheme = theme ? theme : this.storageTheme();
  }
  public switch(): void {
    this._currentTheme = this.currentTheme === 'default' ? 'dark' : 'default';
    localStorage.setItem('_theme', this._currentTheme);
  }
  private storageTheme(): string {
    const _theme = localStorage.getItem('_theme');
    return _theme ? _theme : 'default';
  }
}
export default new $Theme();
