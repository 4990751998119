<template>
  <div class="app-spinner" :class="[size, type]">
    <svg class="svg-spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="3" />
    </svg>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'AppSpinner',
  props: {
    size: {
      type: String,
      default: 'extra-large',
    },
    type: {
      type: String,
      default: 'default',
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
