import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import client from './modules/client';
import event from './modules/event';
import program from './modules/program';
import chat from '@/store/modules/chat';
import profile from '@/store/modules/profile';
import admin from '@/store/modules/admin';
import userManagement from '@/store/modules/user-management';

Vue.use(Vuex);

export default new Vuex.Store<any>({
  modules: {
    auth,
    client,
    event,
    program,
    chat,
    profile,
    admin,
    userManagement,
  },
});
