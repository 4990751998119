import { EX_$Cookies } from '@/classes/base';
import { set } from 'cross-domain-cookie';
import store from '@/store';
import jwt_decode from 'jwt-decode';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const jwtDecode = require('jwt-decode');

enum EExpirationToken {
  'expire',
  'valid',
  'error',
}

type TExpirationToken = keyof typeof EExpirationToken;

interface ITokensAuth {
  setAccessToken(value: string): void;
  getAccessToken(): string;
  setRefreshToken(value: string): void;
  setRoleInfo(value: string): void;
  // getRefreshToken(): string;
  getStatusExpirationToken(token: string): TExpirationToken;
  removeCookiesTokens(): void;
}

/**
 * @class $TokensAuth - класс установки, удаления и получаения токенов авторизации в куки
 * @implements ITokensAuth
 * */
class $TokensAuth implements ITokensAuth {
  //Время жизни кук
  private readonly expires: number = 60 * 60 * 24 * 7;

  //Установка кук здана так, чтобы установить флаги безопасности
  public setAccessToken(value: string) {
    // Убираем флаг, так как не работает в сафари
    if (process.env.NODE_ENV === 'development') {
      document.cookie = `accessToken=${value}; max-age=${this.expires}; samesite=lax; path=/`;
    } else {
      document.cookie = `accessToken=${value}; max-age=${this.expires}; secure; samesite=lax; path=/`;
    }
  }
  public getAccessToken(): string {
    return EX_$Cookies.get('accessToken');
  }

  //Установка кук здана так, чтобы установить флаги безопасности
  public setRefreshToken(value: string) {
    const domain = new URL(process.env.VUE_APP_AUTH_DOMAIN ?? '').hostname; // Получаем домен из URL
    const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString(); // Срок действия куки (7 дней)

    // Устанавливаем куки
    document.cookie = `refreshToken=${encodeURIComponent(
      encodeURIComponent(value ?? '')
    )}; expires=${expires}; domain=${domain}; path=/; secure`;
    // set({
    //   iframeUrl: process.env.VUE_APP_AUTH_DOMAIN,
    //   dataKey: 'refreshToken',
    //   data: {
    //     refreshToken: value,
    //   },
    // });
    // document.cookie = `refreshToken=${value}; max-age=${this.expires}; secure; samesite=lax; path=/`;
  }

  //@ts-ignore
  // public async getRefreshToken(): string {
  //   return get({
  //     iframeUrl: process.env.VUE_APP_AUTH_DOMAIN,
  //     dataKey: 'refreshToken',
  //   });
  // }
  public setRoleInfo(token: string): void {
    // @ts-ignore
    const { roles } = jwt_decode(token);
    // @ts-ignore
    store.dispatch('auth/ASetListRoles', roles[store?.state?.client?.clientInfo?.clientUUID] || []);
  }
  /**
   * @description Метод проверки время жизни токена
   * @param {string} token - jwt
   * @return {TExpirationToken} 'valid' - токен жив | 'expire' - время жизни закончилось | 'error' - произошла ошибка
   */
  public getStatusExpirationToken(token: string): TExpirationToken {
    let statusJwtExpired: TExpirationToken = 'valid';

    if (typeof token !== 'string' || !token) {
      statusJwtExpired = 'error';
    }
    try {
      // @ts-ignore
      const { exp } = jwt_decode(token);
      const currentTime = new Date().getTime() / 1000;

      if (currentTime > exp) statusJwtExpired = 'expire';
    } catch (e) {
      statusJwtExpired = 'error';
    }

    return statusJwtExpired;
  }

  public removeCookiesTokens(): void {
    EX_$Cookies.remove('accessToken');
    this.setRefreshToken('');
    if (process.env.VUE_APP_ENV_STATE === 'LOCAL') EX_$Cookies.remove('refreshToken');
    store.dispatch('auth/ASetIsLogin', false);
    useAuthStore().setIsLoggedIn(false);
  }
}

const EX_$TokensAuth = new $TokensAuth();

export default EX_$TokensAuth;
