import {
  IChangePassword,
  ICitiesRequestData,
  IRegionsRequestData,
  IMunicipalityRequestData,
  IStep,
} from '@/services/AuthService/AuthServiceTypes';
import { Commit, Dispatch } from 'vuex';
import { types } from './mutations';
import { AuthService } from '@/services';
import EX_TokensAuth from '@/classes/base/token-cookies';
import { EX_$Observer } from '@/classes/base';
import { AuthApi } from '@/api/apis/Auth.api';

export default {
  // ВЫХОД ИЗ АККАУНТА
  async ALogout({ dispatch }: { dispatch: Dispatch }): Promise<void> {
    try {
      await AuthApi.logout();
    } catch (e) {
      console.log(e);
    } finally {
      EX_TokensAuth.removeCookiesTokens();
      EX_$Observer.context.$router.push('/');
      dispatch('ASetListRoles', []);
      dispatch('ASetIsLogin', false);
      dispatch('event/ALogoutEvent', {}, { root: true });
      dispatch('program/ALogoutProgram', {}, { root: true });
      dispatch('profile/AClearProfileInfo', {}, { root: true });
    }
    // dispatch('AResetState', {}, { root: true });
    // dispatch('ASetIndexSelectedPageInPreview', 1, { root: true });
  },

  // УСТАНОВКА ФЛАГА АВТОРИЗАЦИИ
  ASetIsLogin({ commit }: { commit: Commit }, isLogin: boolean): void {
    commit(types.SET_IS_LOGIN, isLogin);
  },

  ASetListRoles({ commit }: { commit: Commit }, listRoles: Array<string>): void {
    commit(types.SET_LIST_ROLES, listRoles);
  },

  // ПОЛУЧЕНИЕ ФОРМЫ РЕГИСТРАЦИИ
  async AGetRegistrationForm({ commit }: { commit: Commit }, slug: string): Promise<void> {
    try {
      await AuthService.getRegistrationForm(slug).then((res) => {
        commit(types.SET_REGISTRATION_FORM, res);
        let isFieldsExist = false;
        res?.steps?.map((item: IStep) => {
          if (item?.fields?.length ?? 0 > 0) isFieldsExist = true;
        });
        commit(types.SET_IS_FIELDS_EXIST, isFieldsExist);
      });
    } catch (e) {
      console.log(e);
    }
  },

  // РЕГИСТРАЦИЯ
  ARegistration({ dispatch }: { dispatch: Dispatch }, payload: any): Promise<object> {
    return AuthService.registration(payload);
  },

  // ВАЛИДАЦИЯ ПОЛЕЙ В ФОРМЕ РЕГИСТРАЦИИ
  AValidateRegistrationStep({ commit }: { commit: Commit }, payload: any): Promise<object> {
    return AuthService.validateRegistrationStep(payload);
  },

  // ОБНОВЛЕНИЕ АКСЕС ТОКЕНА
  ARefresh({ commit }: { commit: Commit }, refreshToken: string): Promise<object> {
    return AuthService.refreshTokens(refreshToken);
  },
  ASetIsRefreshing({ commit }: { commit: Commit }, isRefreshing: boolean): void {
    commit(types.SET_IS_REFRESHING, isRefreshing);
  },

  // ВОССТАНОВЛЕНИЕ ПАРОЛЯ
  async ARecover({ commit }: { commit: Commit }, email: string): Promise<void> {
    try {
      const payload = {
        email: email,
      };
      await AuthService.recover(payload);
      EX_$Observer.context.$toastr.success('Письмо выслано на указанный e-mail');
    } catch (e: any) {
      const errorData = e.response.data;
      if (errorData.message === 'validation.error') {
        throw new Error(errorData.errors.email[0]);
      }
    }
  },
  ASetRecoverToken({ commit }: { commit: Commit }, recoverToken: string) {
    commit(types.SET_RECOVER_TOKEN, recoverToken);
  },
  async AChangePassword({ commit }: { commit: Commit }, payload: IChangePassword): Promise<void> {
    try {
      await AuthService.changePassword(payload);
      EX_$Observer.context.$toastr.success('Пароль успешно изменен');
    } catch (e: any) {
      const errorData = e.response.data;
      //@ts-ignore
      if (errorData.errors === 'auth.recover_password_token_not_exist_for_this_user') {
        EX_$Observer.context.$toastr.error(
          'Ссылка для восстановления пароля уже была использована'
        );
        throw 'Error';
      }
      if (errorData.errors === 'auth.recover_password_token_hashes_not_same') {
        EX_$Observer.context.$toastr.error('Токен восстановления не совпадает с выданным');
        throw 'Error';
      }
      if (errorData.message === 'validation.error') {
        throw new Error(errorData.errors.password[0]);
      }
      throw 'Error';
    }
  },
  async AGetSearchCities(
    { commit }: { commit: Commit },
    params: ICitiesRequestData
  ): Promise<void> {
    try {
      commit(types.SET_IS_CITIES_LOADING, true);
      const res = await AuthService.getSearchCities(params);
      commit(types.SET_IS_CITIES_LOADING, false);
      commit(types.SET_SEARCH_CITIES, res);
    } catch (e) {
      console.log(e);
      commit(types.SET_IS_CITIES_LOADING, false);
    }
  },
  async AGetSearchRegions(
    { commit }: { commit: Commit },
    params: IRegionsRequestData
  ): Promise<void> {
    try {
      commit(types.SET_IS_REGIONS_LOADING, true);
      const res = await AuthService.getSearchRegions(params);
      commit(types.SET_IS_REGIONS_LOADING, false);
      commit(types.SET_SEARCH_REGIONS, res);
    } catch (e) {
      console.log(e);
      commit(types.SET_IS_REGIONS_LOADING, false);
    }
  },

  async AGetSearchCountries({ commit }: { commit: Commit }, search: string): Promise<void> {
    try {
      commit(types.SET_IS_COUNTRIES_LOADING, true);
      const res = await AuthService.getSearchCountries(search);
      commit(types.SET_IS_COUNTRIES_LOADING, false);
      commit(types.SET_SEARCH_COUNTRIES, res);
    } catch (e) {
      commit(types.SET_IS_COUNTRIES_LOADING, false);
      console.log(e);
    }
  },

  async AGetSearchMunicipalities(
    { commit }: { commit: Commit },
    params: IMunicipalityRequestData
  ): Promise<void> {
    try {
      commit(types.SET_IS_MUNICIPALITIES_LOADING, true);
      const res = await AuthService.getSearchMunicipalities(params);
      const newRes = res.map((item: { area: string }) => {
        return { id: Date.now(), title: item.area };
      });
      commit(types.SET_IS_MUNICIPALITIES_LOADING, false);
      commit(types.SET_SEARCH_MUNICIPALITIES, newRes);
    } catch (e) {
      commit(types.SET_IS_MUNICIPALITIES_LOADING, false);
      console.log(e);
    }
  },

  AClearRegions({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_REGIONS);
  },
  AClearCities({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_CITIES);
  },
  AClearCountries({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_COUNTRIES);
  },
  AClearMunicipalities({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_MUNICIPALITIES);
  },
};
