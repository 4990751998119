export const getCookie = function (name: string) {
  const cookie = document.cookie;
  const cookieCollection = cookie.split('; ');
  const cookieCollectionArr = [] as any;
  let result = '';

  if (cookieCollection.length) {
    for (let i = 0; i < cookieCollection.length; i++) {
      const cookieCollectionItems = cookieCollection[i].split('=');
      cookieCollectionArr[cookieCollectionItems[0]] = cookieCollectionItems[1];
    }

    result =
      typeof cookieCollectionArr[name] !== 'undefined' ? cookieCollectionArr[name] : undefined;
  }
  return result;
};
