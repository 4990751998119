<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-hide-icon-close="true"
  >
    <div class="app-modal-registration-success__card" :style="getBackground">
      <div class="app-modal-registration-success__card-shadow">
        <app-text
          class="app-modal-registration-success__event-name"
          type="fourthTitle"
          color="button-title"
          :text="eventInfo.title"
        />
        <div v-if="false" class="app-modal-registration-success__time">
          <app-icon :color="$isWindow.mobile ? '#FFFFFF' : '#111111'" name="check" />
          <app-text type="primary-book" :text="'До начала события 20:14:48 '" />
        </div>
      </div>
    </div>
    <div class="app-modal-registration-success__title">
      <app-text type="title" :text="'Вы успешно зарегистрированы на событие'" />
    </div>
    <div class="app-modal-registration-success__subtitle">
      <app-text
        type="main-book"
        color="secondary"
        :text="'Все события, на которое вы зарегистрировались, доступны в личном кабинете.'"
      />
    </div>
    <app-button text="Ура!" :is-disabled="false" @click="toggleModal" />
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppButton } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { MWidthWindow } from '@/mixins';
import { mapState } from 'vuex';
export default Vue.extend({
  name: 'appModalRegistrationSuccess',
  mixins: [MWidthWindow],
  components: {
    AppModal,
    AppText,
    AppIcon,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPassHidden: true,
    };
  },
  methods: {
    toggleModal() {
      return this.$emit('toggleModal');
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    getBackground() {
      return `background: url(${this.eventInfo.image}) center center/cover no-repeat;`;
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'style';
</style>
