import { IClientInfo, ISearchInfo } from '@/services/ClientService/ClientServiceTypes';

export interface IState {
  clientInfo: IClientInfo;
  searchInfo: ISearchInfo;
  clientColor: string;
  colorsButton: Array<{ color: string }>;
  colorsText: Array<{ color: string }>;
  isFetchClientInfo: boolean;
}
export default () => ({
  clientInfo: {} as IClientInfo,
  searchInfo: {},
  isFetchClientInfo: true,
  colorsButton: [],
  colorsText: [],
  clientColor: '',
});
