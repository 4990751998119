<template>
  <div class="app-select-change-active">
    <button
      class="selected-block"
      :class="{ active: activeItem[listKey] }"
      @click="toggleIsShowList"
    >
      <div class="selected-block__container">
        <app-text class="selected-block__name" type="main" :text="titleText" />
      </div>
      <app-icon
        class="app-select-wrap__chevron-down"
        name="chevron-down"
        :class="{ 'app-select-wrap__chevron-down_active': isShowList }"
      />
    </button>
    <div
      v-if="isShowList"
      class="select-list"
      :class="{ scroll: list.length > 7 }"
      v-click-outside="closeList"
    >
      <button
        v-for="(item, index) in list"
        :key="index"
        class="select-list__item"
        :class="{ activeItem: item[listKey] === activeItem[listKey] }"
        @click="changeActiveItem(item)"
      >
        <div
          v-if="isHaveColor"
          class="app-select-change-active__color"
          :style="`background-color:${item[colorKey]}`"
        />
        <app-text :text="item[listKey]" />
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { AppText } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';

export default Vue.extend({
  components: {
    AppText,
    AppIcon,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    titleText: {
      type: String,
    },
    listKey: {
      type: String,
      required: true,
    },
    colorKey: {
      type: String,
    },
    isHaveColor: {
      type: Boolean,
    },
  },
  data() {
    const activeItem: ISelectItem = {};
    return {
      isShowList: false,
      activeItem,
    };
  },
  methods: {
    closeList(): void {
      this.isShowList = false;
    },
    toggleIsShowList() {
      this.isShowList = !this.isShowList;
    },
    changeActiveItem(value: ISelectItem): void {
      this.closeList();
      if (this.activeItem[this.listKey] == value[this.listKey]) {
        this.activeItem = {};
      } else {
        this.activeItem = { ...value };
      }
      this.$emit('onSelect', this.activeItem);
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
