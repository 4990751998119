<template>
  <div class="app-breadcrumb">
    <ul v-if="list.length > 0">
      <li v-for="(item, index) in list" :key="index" class="app-breadcrumb__item">
        <app-button-link :title="item.name" :is-route="true" v-if="item.to" :link="item.to" />
        <app-text v-else :text="item.name" />
        <app-icon v-if="index < list.length - 1" name="chevron-right" :size="26" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { AppText } from '@/ui/components/text';
import { AppButtonLink } from '@/ui/components/buttons';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';

export default Vue.extend({
  name: 'AppBreadcrumb',
  components: {
    AppIcon,
    AppText,
    AppButtonLink,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
