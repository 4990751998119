var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"ui-input",class:{
    [_vm.size]: true,
    error: _vm.errorMessages?.length,
    round: _vm.round,
  }},[(_setup.checkIsSlotFilled(_vm.$slots['label']))?_c('span',{staticClass:"ui-input-label"},[_vm._t("label"),(_setup.checkIsSlotFilled(_vm.$slots['label-info']))?_c(_setup.UIPopover,{attrs:{"trigger":"hover","placement":"top"},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c(_setup.UIIcon,{staticClass:"stroke-gray hover:stroke-client fill-none",attrs:{"name":"info","size":20}})]},proxy:true}],null,false,1772661196)},[_c('div',{staticClass:"text-start"},[_vm._t("label-info")],2)]):_vm._e()],2):_vm._e(),_c('span',{staticClass:"w-full relative flex"},[(_setup.checkIsSlotFilled(_vm.$slots.leftPlace))?_c('span',{ref:"leftPlaceRef",staticClass:"absolute top-1/2 transform -translate-y-1/2 ui-input-left-place"},[_vm._t("leftPlace")],2):_vm._e(),(_vm.tag === 'input')?_c('input',_vm._b({staticClass:"ui-input-input",class:{
        'bg-neutral-100': !_vm.backgroundWhite,
        'bg-white': _vm.backgroundWhite,
      },style:(`${_setup.paddingLeft} ${_setup.paddingRight}`),attrs:{"id":_vm.inputId,"type":"text","placeholder":_vm.placeholder,"maxlength":_vm.maxLength,"inputmode":_setup.inputMode},domProps:{"value":_vm.value},on:{"input":_setup.onInput,"keydown":function($event){return _vm.$emit('keydown', $event)},"paste":function($event){return _vm.$emit('paste', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}},'input',_vm.$attrs,false)):_vm._e(),(_vm.tag === 'textarea')?_c('textarea',_vm._b({staticClass:"ui-input-input resize-y",class:{
        'bg-neutral-100': !_vm.backgroundWhite,
        'bg-white': _vm.backgroundWhite,
      },style:(`${_setup.paddingRight}`),attrs:{"id":_vm.inputId,"placeholder":_vm.placeholder,"maxlength":_vm.maxLength,"inputmode":_setup.inputMode,"rows":_vm.textAreaRows},domProps:{"value":_vm.value},on:{"input":_setup.onInput,"keydown":function($event){return _vm.$emit('keydown', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}},'textarea',_vm.$attrs,false)):_vm._e(),(_vm.errorMessages?.length)?_c('span',{ref:"iconsRefRight",staticClass:"absolute ui-input-right-place",class:{
        'top-1/2 transform -translate-y-1/2': _vm.tag === 'input',
        'top-5': _vm.tag === 'textarea',
      }},[_c(_setup.UIIcon,{staticClass:"stroke-red-500 fill-none",attrs:{"name":"info"}})],1):_vm._e(),(_setup.checkIsSlotFilled(_vm.$slots.rightPlace))?_c('span',{ref:"rightPlaceRef",staticClass:"absolute top-1/2 transform -translate-y-1/2 ui-input-right-place"},[_vm._t("rightPlace")],2):_vm._e()]),(_vm.maxLength !== undefined && _vm.isMaxLengthVisible)?_c('span',{staticClass:"ui-input-length"},[_vm._v(" "+_vm._s(String(_vm.value).length)+" / "+_vm._s(_vm.maxLength)+" ")]):_vm._e(),(_vm.errorMessages?.length)?_c('span',{staticClass:"ui-input-error-messages"},_vm._l((_vm.errorMessages),function(message){return _c('span',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }