<template>
  <transition name="fade">
    <div v-if="isShowBanner" class="cookies-banner">
      <app-icon name="close" size="18" class="cookies-banner__icon" @click.native="close" />
      <div class="cookies-banner__content">
        <img src="@/assets/images/banners/cookies.png" alt="cookies" />
        <div class="cookies-banner__column">
          <div>
            <app-text
              text="Мы используем cookie, чтобы сделать сайт лучше. Продолжая использовать сайт, вы принимаете"
              class="cookies-banner__text"
            />
            <app-text
              class="cookies-banner__text link"
              text="Пользовательское соглашение"
              @click="goTo(clientInfo?.footer?.termsOfUse ?? '')"
            />
            <app-text class="cookies-banner__text" tag="span" text="и" />
            <app-text
              class="cookies-banner__text link"
              text="Политику конфиденциальности"
              @click="goTo(clientInfo?.footer?.privacyPolicy ?? '')"
            />
          </div>
          <app-button text="Принять" class="cookies-banner__button" @click="agree" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';

import { AppText } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { MWidthWindow } from '@/mixins';
import { mapState } from 'vuex';
export default Vue.extend({
  name: 'CookiesBanner',
  components: {
    AppButton,
    AppIcon,
    AppText,
  },
  mixins: [MWidthWindow],
  data() {
    return {
      isShowBanner: false,
    };
  },
  methods: {
    close(): void {
      this.isShowBanner = false;
    },
    agree(): void {
      this.isShowBanner = false;
      localStorage.setItem('isShowBannerCookies', 'false');
    },
    goTo(url: string) {
      window.open(url, '_blank');
    },
  },
  computed: {
    ...mapState('client', ['clientInfo']),
    footer(): void {
      return this.clientInfo.footer ? this.clientInfo.footer : {};
    },
  },
  mounted() {
    setTimeout(() => {
      const isShowBannerCookies = localStorage.getItem('isShowBannerCookies');
      if (isShowBannerCookies) this.isShowBanner = JSON.parse(isShowBannerCookies);
      else this.isShowBanner = true;
    }, 3000);
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
