
import { defineComponent, PropType } from 'vue';
import { TPositionModal, TSizeModal } from '@/ui/components/modals/app-modal/ModalTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { MWidthWindow } from '@/mixins';
import { IText } from '@/ui/components/text/app-text/AppTextTypes';
import { EX_$Modal } from '@/classes/content';

const __default__ = defineComponent({
  name: 'AppModal',
  components: { AppIcon, AppText },
  mixins: [MWidthWindow],
  props: {
    textHead: {
      type: String,
      required: false,
    },
    textType: {
      type: String as PropType<IText>,
      default: 'thirdTitle',
    },
    isShowModal: {
      type: Boolean,
      required: true,
    },
    positionModal: {
      type: String as PropType<TPositionModal>,
      required: false,
      default: 'top',
    },
    isCanCloseByBackgroundClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHideIconClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAlignText: {
      type: Boolean,
      default: false,
    },
    isConfirmExit: {
      type: Boolean,
      default: false,
    },
    fullOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String as PropType<TSizeModal>,
      required: false,
    },
    innerWidth: {
      type: String,
      required: false,
      default: '560px',
    },
  },
  data() {
    return {
      EX_ModalClass: EX_$Modal,
    };
  },
  methods: {
    toggleModal(): void {
      if (this.isConfirmExit) {
        this.EX_ModalClass.toggleModal('appModalConfirmExit');
      } else this.$emit('toggleModal');
    },
    onBackGroundClick() {
      if (this.isCanCloseByBackgroundClick) {
        if (this.isConfirmExit) {
          this.EX_ModalClass.toggleModal('appModalConfirmExit');
        } else this.toggleModal();
      }
    },
    stopPropagation(e: Event) {
      e.stopPropagation();
    },
    lockBody() {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.classList.add('lock');
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    },
    unlockBody() {
      document.body.classList.remove('lock');
      document.body.style.paddingRight = '';
    },
  },
  mounted() {
    this.lockBody();
  },
  destroyed() {
    this.unlockBody();
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "67e9c365": (_vm.innerWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__