var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-border"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_setup.closeSearch),expression:"closeSearch"}],staticClass:"h-[62px] flex items-center bg-white px-5"},[_c('div',{staticClass:"w-full flex justify-between items-center gap-1.5"},[(!_setup.isSearchOpened)?_c('div',{staticClass:"flex-1 flex justify-between items-center"},[_c(_setup.HeaderClientLogo,{attrs:{"max-height":"46px"},nativeOn:{"click":function($event){return _setup.closeMenu.apply(null, arguments)}}})],1):_vm._e(),(_setup.isSearchOpened)?_c('div',{staticClass:"flex-1 flex items-center gap-2.5"},[_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _setup.doSearch.apply(null, arguments)}}},[_c(_setup.UIInput,{attrs:{"size":"small","placeholder":"Поиск","round":"","input-id":_setup.inputId},scopedSlots:_vm._u([{key:"leftPlace",fn:function(){return [_c(_setup.UIIcon,{staticClass:"stroke-gray fill-none mr-2.5",attrs:{"name":"search","size":18}})]},proxy:true},(_setup.searchValue)?{key:"rightPlace",fn:function(){return [_c(_setup.UIIcon,{staticClass:"stroke-gray fill-none active:stroke-blue ml-2.5",attrs:{"name":"x","size":24},on:{"click":_setup.clearSearch}})]},proxy:true}:null],null,true),model:{value:(_setup.searchValue),callback:function ($$v) {_setup.searchValue=$$v},expression:"searchValue"}})],1)]):_vm._e(),(!_setup.isSearchOpened)?_c('div',{staticClass:"flex items-center gap-1.5"},[_c('div',{staticClass:"w-[46px] h-[46px] flex justify-center items-center rounded-full transition-all duration-300",class:{
            'active:bg-neutral-100': !_setup.isMenuOpened,
            'bg-neutral-100 active:bg-neutral-200': _setup.isMenuOpened,
          },on:{"click":_setup.openSearch}},[_c(_setup.UIIcon,{staticClass:"stroke-black-50 fill-none",attrs:{"name":"search","size":24}})],1)]):_vm._e(),_c('div',{staticClass:"w-[46px] h-[46px] flex justify-center items-center rounded-full",class:{
          'active:bg-neutral-100': !_setup.isMenuOpened,
          'bg-neutral-100 active:bg-neutral-200': _setup.isMenuOpened,
        },on:{"click":_setup.toggleMenu}},[_c(_setup.UIIcon,{staticClass:"stroke-black-50 fill-none",attrs:{"name":_setup.isMenuOpened ? 'x' : 'menu',"size":24}})],1)])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_setup.isMenuOpened)?_c('div',{staticClass:"h-[calc(100vh-62px)] bg-neutral-100 flex flex-col gap-[25px] px-5 pt-[25px] border-t-2 border-neutral-200 border-solid"},[(_vm.navItems.length)?_c('nav',{staticClass:"w-full appearance-none flex flex-col"},_vm._l((_vm.navItems),function(item){return _c(_setup.MobileNavItem,{key:item.name,attrs:{"item":item}})}),1):_vm._e(),(_vm.navItems.length)?_c('div',{staticClass:"border-[0] border-t-2 border-neutral-200 border-solid"}):_vm._e(),_c(_setup.HeaderMobileUser,{on:{"close":_setup.toggleMenu}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }