import { Commit } from 'vuex';
import { types } from './mutations';
import { ClientService } from '@/services';
import { ISubscribeToNewsLetterRequestData } from '@/services/ClientService/ClientServiceTypes';
import { EX_$Cookies } from '@/classes/base';

export default {
  async AGetInfo({ commit }: { commit: Commit }): Promise<void> {
    try {
      commit(types.SET_IS_FETCH_CLIENT_INFO, true);
      await ClientService.getInfo().then((res) => {
        commit(types.SET_IS_FETCH_CLIENT_INFO, false);
        commit(types.SET_CLIENT_INFO, res);
        commit(types.SET_CLIENT_COLORS, res);
        EX_$Cookies.set('clientUUID', res.clientUUID, { secure: true });
      });
      // .catch(() => {
      //   commit(types.SET_IS_FETCH_CLIENT_INFO, true);
      // });
    } catch (e) {
      commit(types.SET_IS_FETCH_CLIENT_INFO, false);
      console.log(e);
    }
  },
  AGetSearchInfo({ commit }: { commit: Commit }, search: string): void {
    try {
      ClientService.getSearchInfo(search).then((res) => {
        commit(types.SET_SEARCH_INFO, res);
      });
    } catch (e) {
      console.log(e);
    }
  },

  // ПОДПИСКА НА НОВОСТИ
  ASubscribeToNewsLetter(
    { commit }: { commit: Commit },
    body: ISubscribeToNewsLetterRequestData
  ): Promise<void> {
    return ClientService.subscribeToNewsLetter(body);
  },
};
