<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Наполнение слайдера"
    :is-confirm-exit="true"
  >
    <div :key="countKey">
      <div class="event-images-slider" v-for="(item, index) in sliderListInfo" :key="item.id">
        <div v-if="sliderListInfo?.length > 1" class="event-images-slider__header">
          <app-text :text="`Карточка ${index + 1}`" type="primary" line-height="25" />
          <div class="event-images-slider__header-toolbar">
            <app-button-icon name="trash-2" color="#83888F" @click="removeSlider(item.id)" />
            <app-button-icon
              v-if="index !== sliderListInfo?.length - 1"
              name="arrow-down"
              color="#83888F"
              @click="moveDown(index)"
            />
            <app-button-icon
              v-if="index !== 0"
              name="arrow-up"
              color="#83888F"
              @click="moveUp(index)"
            />
          </div>
        </div>
        <div class="event-images-slider__image-input">
          <div class="event-images-slider__item">
            <div
              v-if="isFetchingInfoBlockImageUpload && activeIndex === index"
              class="event-images-slider__spinner-container"
            >
              <app-spinner size="small" />
            </div>
            <div v-else-if="item.image" class="event-images-slider__image-container">
              <img class="event-images-slider__image" :src="item.image" />
              <app-button-icon
                v-if="item.image"
                name="x"
                color="#FFFFFF"
                icon-size="20"
                @click="deleteImage(item.id)"
              />
            </div>
            <div
              v-else
              class="event-images-slider__input-container"
              :class="{
                'with-err': item.errorImage && errorIndexesList.includes(index),
              }"
            >
              <input
                id="add-file"
                type="file"
                name="add-file"
                :accept="acceptedFileTypes"
                class="event-images-slider__add-image-input"
                multiple
                @change="(event) => AUploadImage(event, item.id, index)"
              />
              <div class="event-images-slider__info">
                <app-icon name="plus" size="18" color="#83888F" />
                <app-text
                  text="Переместите фото с вашего пк в эту область или нажмите “+”"
                  type="small"
                  line-height="20"
                  special-text-color="#83888F"
                />
              </div>
            </div>

            <app-text
              v-if="
                failedUploadImageErrorCode === 422 &&
                !isFetchingInfoBlockImageUpload &&
                activeIndex === index
              "
              text="Загрузите изображение в формате: jpg, jpeg, png, не
            более 10 МБ"
              type="small"
              color="error"
            />
          </div>
        </div>

        <app-input
          label="Заголовок карточки"
          type="text"
          :maxlength="EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH"
          v-model="item.title"
          :value="item.title"
          placeholder="Заголовок"
          :note="`${item.title.length}/${EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH}`"
          :errors="item.errorTitle"
          @onInput="(value) => onInputValueTitle(value, item.id, index)"
        />

        <app-input
          class="event-images-slider__input-description"
          label="Текст"
          type="text"
          tag="textarea"
          :maxlength="EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION"
          v-model="item.description"
          :value="item.description"
          placeholder="Описание"
          :note="`${item.description.length}/${EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION}`"
          :errors="item.errorDescription"
          @onInput="(value) => onInputValueDescription(value, item.id, index)"
        />
      </div>
    </div>

    <app-button
      class="event-images-slider__add-button"
      text="Добавить еще одну карточку"
      type="secondary"
      font-type="primary"
      @click="addNewSlide"
    />
    <app-button text="Сохранить" @click="onSaveInfoBlock" />
  </AppModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AppText, AppButtonIcon, AppInput, AppButton } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import AppSpinner from '@/components/common/app-spinner';
import { mapActions, mapState } from 'vuex';
import { IInfoBlockSlide } from '@/services/AdminService/AdminServiceTypes';
import {
  EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION,
  EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH,
} from '@/helpers/constants/admin/event-redactor.constants';

export default defineComponent({
  name: 'AppModalEventSlider',
  components: {
    AppModal,
    AppText,
    AppIcon,
    AppButtonIcon,
    AppInput,
    AppButton,
    AppSpinner,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputValueTitle: '',
      inputValueDescription: '',
      errorInputValueTitle: [] as Array<string>,
      errorInputValueDescription: [] as Array<string>,
      sliderListInfo: [] as Array<IInfoBlockSlide>,
      activeIndex: -1,
      errorIndexesList: [] as Array<number>,
      countKey: 0,
      errorImage: [] as Array<string>,
      acceptedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH,
      EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION,
    };
  },
  methods: {
    ...mapActions('admin', [
      'AUploadEventInfoBlockSliderImage',
      'ClearEventInfoBlockImageById',
      'AChangeInfoBlockDescription',
      'AChangeEventImages',
      'AChangeInfoBlockSlider',
      'ClearFailedUploadImageErrorCode',
      'changeBlockSlider',
    ]),

    toggleModal() {
      this.$emit('toggleModal');
    },
    moveDown(indexMove: number) {
      if (this.sliderListInfo.length > 1 && indexMove !== this.sliderListInfo.length - 1) {
        const buffer = this.sliderListInfo[indexMove];
        this.sliderListInfo[indexMove] = this.sliderListInfo[indexMove + 1];
        this.sliderListInfo[indexMove + 1] = buffer;
        this.countKey++;
      }
    },
    moveUp(indexMove: number) {
      if (indexMove !== 0) {
        const buffer = this.sliderListInfo[indexMove];
        this.sliderListInfo[indexMove] = this.sliderListInfo[indexMove - 1];
        this.sliderListInfo[indexMove - 1] = buffer;
        this.countKey++;
      }
    },
    onInputValueTitle(value: string, id: number, index: number) {
      if (value.length > EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH) {
        this.sliderListInfo[index].errorTitle = [
          `Недопустимое количество символов (не более ${EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH})`,
        ];
        this.errorIndexesList[index] = index;
      } else if (value === '') {
        this.errorIndexesList[index] = index;
        this.sliderListInfo[index].errorTitle = ['Обязательное поле'];
      } else {
        delete this.sliderListInfo[index].errorTitle;
      }
      this.sliderListInfo.forEach((item) => {
        if (item.id === id) {
          return (item.title = value);
        }
      });
    },

    onInputValueDescription(value: string, id: number, index: number) {
      if (value.length > EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION) {
        this.sliderListInfo[index].errorDescription = [
          `Недопустимое количество символов (не более ${EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION})`,
        ];
        this.errorIndexesList[index] = index;
      } else if (value === '') {
        this.errorIndexesList[index] = index;
        this.sliderListInfo[index].errorDescription = ['Обязательное поле'];
      } else {
        delete this.sliderListInfo[index].errorDescription;
      }
      this.sliderListInfo.forEach((item) => {
        if (item.id === id) {
          return (item.description = value);
        }
      });
    },
    deleteImage(id: number) {
      this.sliderListInfo.forEach((item) => {
        if (item.id === id) {
          return (item.image = '');
        }
      });
    },
    async AUploadImage(event: Event, id: number, uploadImageIndex: number): Promise<any> {
      event.preventDefault();
      //@ts-ignore
      let list = [...event.target!.files];
      const formData = new FormData();
      formData.append('image', list[0]);
      this.activeIndex = uploadImageIndex;
      await this.AUploadEventInfoBlockSliderImage(formData);
      if (this.failedUploadImageErrorCode === 422) {
        this.sliderListInfo[uploadImageIndex].errorImage = ['Некорректная картинка'];
        this.errorIndexesList[uploadImageIndex] = uploadImageIndex;
      } else {
        this.sliderListInfo.forEach((item) => {
          if (item.id === id) {
            return (item.image = this.infoBlockSliderImage);
          }
        });
        delete this.sliderListInfo[uploadImageIndex].errorImage;
      }
      this.countKey++;
    },
    addNewSlide() {
      this.sliderListInfo.push({
        id: Date.now(),
        image: '',
        title: '',
        description: '',
      });
      this.inputValueTitle = '';
      this.inputValueDescription = '';
      this.countKey++;
    },
    removeSlider(id: number) {
      if (this.sliderListInfo.length > 1) {
        this.sliderListInfo = this.sliderListInfo.filter((item) => id !== item.id);
        this.sliderListInfo.forEach((item, index) => {
          if (item.id === id) {
            delete this.sliderListInfo[index];
          }
        });
      }
    },

    scrollToError() {
      const scrollTarget = document.querySelector('.with-err');
      const relativeTarget = scrollTarget?.parentElement;
      relativeTarget?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    checkOnError() {
      for (let i = 0; i < this.sliderListInfo?.length; i++) {
        if (
          this.sliderListInfo[i]?.image !== '' &&
          this.sliderListInfo[i]?.title !== '' &&
          this.sliderListInfo[i]?.title?.length <= EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH &&
          this.sliderListInfo[i]?.description !== '' &&
          this.sliderListInfo[i]?.description?.length <=
            EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION
        ) {
          delete this.sliderListInfo[i].errorImage;
          delete this.sliderListInfo[i].errorTitle;
          delete this.sliderListInfo[i].errorDescription;
        } else {
          if (this.sliderListInfo[i]?.image === '') {
            this.sliderListInfo[i].errorImage = ['Добавьте картинку'];
            this.errorIndexesList[i] = i;
          }
          if (this.sliderListInfo[i]?.title === '') {
            this.sliderListInfo[i].errorTitle = ['Обязательное поле'];
            this.errorIndexesList[i] = i;
          } else if (
            this.sliderListInfo[i]?.title.length > EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH
          ) {
            this.sliderListInfo[i].errorTitle = [
              `Недопустимое количество символов (не более ${EVENT_REDACTOR_INFO_SLIDER_TITLE_LENGTH})`,
            ];
            this.errorIndexesList[i] = i;
          }
          if (this.sliderListInfo[i]?.description === '') {
            this.sliderListInfo[i].errorDescription = ['Обязательное поле'];
            this.errorIndexesList[i] = i;
          } else if (
            this.sliderListInfo[i]?.description?.length >
            EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION
          ) {
            this.sliderListInfo[i].errorDescription = [
              `Недопустимое количество символов (не более ${EVENT_REDACTOR_INFO_SLIDER_TITLE_DESCRIPTION})`,
            ];
            this.errorIndexesList[i] = i;
          }
        }
      }
    },
    onSaveInfoBlock() {
      this.checkOnError();
      this.ClearFailedUploadImageErrorCode();
      const errorsList = this.sliderListInfo.map((item, index) => {
        if (
          Object.keys(item).includes('errorImage') ||
          Object.keys(item).includes('errorTitle') ||
          Object.keys(item).includes('errorDescription')
        ) {
          this.errorIndexesList[index] = index;
        }
        return (
          Object.keys(item).includes('errorImage') ||
          Object.keys(item).includes('errorTitle') ||
          Object.keys(item).includes('errorDescription')
        );
      });
      if (!errorsList.includes(true)) {
        switch (this.modalName) {
          case 'appModalEventSlider':
            this.AChangeInfoBlockSlider(this.sliderListInfo);
            break;
          case 'appModalEventSliderFullBlock':
            this.changeBlockSlider(this.sliderListInfo);
            break;
        }
        this.errorImage = [];
        this.errorInputValueTitle = [];
        this.errorInputValueDescription = [];
        this.toggleModal();
      } else {
        setTimeout(() => {
          this.scrollToError();
        }, 0);
      }
      this.countKey++;
    },
  },
  computed: {
    ...mapState('admin', [
      'isFetchingInfoBlockImageUpload',
      'infoBlockSliderImage',
      'changedEventImages',
      'changedInfoBlockDescription',
      'changedInfoBlockSlider',
      'changedBlockSlider',
      'failedUploadImageErrorCode',
    ]),
    ...mapState('event', ['eventInfo']),
  },
  mounted() {
    switch (this.modalName) {
      case 'appModalEventSlider':
        {
          if (this.changedInfoBlockSlider?.length) {
            this.sliderListInfo =
              this.changedInfoBlockSlider.map((item: IInfoBlockSlide) => {
                return {
                  id: item.id,
                  title: item.title,
                  image: item.image,
                  description: item.description,
                };
              }) ?? [];
          } else if (this.eventInfo?.infoBlock?.listOfInfo?.sliderInfo?.length) {
            this.sliderListInfo = this.eventInfo?.infoBlock?.listOfInfo?.sliderInfo.map(
              (item: IInfoBlockSlide) => {
                return {
                  id: item.id,
                  title: item.title,
                  image: item.image,
                  description: item.description,
                };
              }
            );
          } else {
            this.sliderListInfo = [{ id: 1, image: '', title: '', description: '' }];
          }
        }
        break;

      case 'appModalEventSliderFullBlock':
        {
          if (this.changedBlockSlider?.length) {
            this.sliderListInfo =
              this.changedBlockSlider.map((item: IInfoBlockSlide) => {
                return {
                  id: item.id,
                  title: item.title,
                  image: item.image,
                  description: item.description,
                };
              }) ?? [];
          } else if (this.eventInfo?.infoBlock?.listOfInfo?.sliderBlockList?.length) {
            this.sliderListInfo = this.eventInfo?.infoBlock?.listOfInfo?.sliderBlockList.map(
              (item: IInfoBlockSlide) => {
                return {
                  id: item.id,
                  title: item.title,
                  image: item.image,
                  description: item.description,
                };
              }
            );
          } else {
            this.sliderListInfo = [{ id: 1, image: '', title: '', description: '' }];
          }
        }
        break;
    }
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
.event-images-slider__input-description {
  .app-input__inner_input {
    height: 118px;
    resize: none;
    &::-webkit-scrollbar {
      width: 6px;

      &-thumb {
        cursor: pointer;
        border-radius: 6px;
        background-color: var(--client-color);
        border: 1px solid transparent;
        background-clip: content-box;
      }

      &-track {
        background: white;
        border-radius: 6px;
      }
    }
  }
}
</style>
