import {
  IAddress,
  IAdminPrograms,
  IAllEventsInfo,
  IAvailableField,
  IChangedEventDocuments,
  IChangeEvent,
  IChangeProgram,
  IChangeProgramsTracksOrAddress,
  IEditBlocksEvent,
  IInfoBlockSlide,
  IProgramByAddress,
  IProgramByTrack,
  ITrack,
} from '@/services/AdminService/AdminServiceTypes';
import { IDocument, ISpeakerCard } from '@/services/EventService/EventServiceTypes';

export interface IState {
  //event-redactor
  redactorEventInfo: IChangeEvent;
  changedEventDocuments: Array<IChangedEventDocuments>;
  infoBlockImage: string;
  isFetchingUploadInfoBlockImage: boolean;
  // Ссылка на изображение на странице редактирования события
  eventHeroImage: string;
  isFetchingEventHeroImage: boolean;
  infoBlockSliderImage: string;
  isFetchingInfoBlockImageUpload: boolean;
  changedEventVideo: string;
  changedInfoBlockDescription: string;
  changedEventImages: Array<string>;
  changedInfoBlockSlider: Array<IInfoBlockSlide>;
  allEvents: IAllEventsInfo;
  isFetchingAllEvents: boolean;
  isFetchingMoreEvent: boolean;
  fileUpload: string;
  isFetchingUploadFile: boolean;
  //create-event
  hasSlug: boolean;
  failedUploadImageErrorCode: number;
  redactorEventBlock: IEditBlocksEvent;
  //program-control-panel
  adminPrograms: Array<IAdminPrograms>;
  adminDeletedPrograms: Array<IAdminPrograms>;
  //create-program
  isFetchingImageUploadCreateProgram: boolean;
  programDocuments: Array<IDocument>;
  speakerPresentations: Array<IDocument>;
  programSpeakerPresentation: Array<IDocument>;
  imageUploadCreateProgram: string;
  allAddress: Array<IAddress>;
  allTracks: Array<ITrack>;
  speakerSearch: Array<ISpeakerCard>;
  isFetchSearchSpeaker: boolean;
  selectedTrackInfo: ITrack;
  selectedAddressInfo: IAddress;
  programsByTrack: Array<IProgramByTrack>;
  programsByAddress: Array<IProgramByAddress>;
  redactorProgram: IChangeProgram;
  redactorProgramsTracksOrAddress: IChangeProgramsTracksOrAddress;
  selectedProgramId: string;
  updateProgram: IChangeProgram;
  availableFields: Array<IAvailableField>;
  selectedFields: string[];
  eventStartDate: string;
  eventEndDate: string;
  isFetchingCreateEvent: boolean;
  isFetchingUploadAvatarImage: boolean;
  isSpeakerCreatingOrUpdating: boolean;
  // урл изображения внутри модалки редактирования изображения
  imageRedactorEventHeader: string;
  changedBlockSlider: any[];
  changedBlockVideo: string;
  changedBlockVideoWithTextUrl: string;
  changedBlockVideoWithTextText: string;
  changedBlockGalleryImages: string[];
  changedBlockGalleryWithTextText: string;
  changedBlockGalleryWithTextImages: string[];
  activeDateIndex: number;
}

export default () => ({
  redactorEventInfo: {},
  changedEventDocuments: [],
  allEvents: {},
  isFetchingAllEvents: false,
  isFetchingMoreEvent: false,
  infoBlockImage: '',
  isFetchingUploadInfoBlockImage: false,
  eventHeroImage: '',
  isFetchingEventHeroImage: false,
  infoBlockSliderImage: '',
  isFetchingInfoBlockImageUpload: false,
  changedEventVideo: '',
  changedInfoBlockDescription: '',
  changedEventImages: [],
  changedInfoBlockSlider: [],
  changedBlockSlider: [],
  changedBlockVideo: '',
  changedBlockVideoWithTextUrl: '',
  changedBlockVideoWithTextText: '',
  changedBlockGalleryImages: [],
  changedBlockGalleryWithTextText: '',
  changedBlockGalleryWithTextImages: [],
  fileUpload: '',
  isFetchingUploadFile: false,
  hasSlug: false,
  failedUploadImageErrorCode: 0,
  redactorEventBlock: {},
  imageRedactorEventHeader: '',
  // кажется бесполезное поле
  isFetchingImagesRedactorEvent: false,
  firstImageUpload: '',
  isFetchingRedactorEventHeaderImage: false,
  isFetchingFirstImage: false,
  secondImageUpload: '',
  isFetchingSecondImage: false,
  thirdImageUpload: '',
  isFetchingThirdImage: false,
  fourthImageUpload: '',
  isFetchingFourthImage: false,
  fifthImageUpload: '',
  isFetchingFifthImage: false,
  getAllEvents: {},
  adminPrograms: [],
  adminDeletedPrograms: [],
  programDocuments: [],
  speakerPresentations: [],
  programSpeakerPresentation: [],
  imageUploadCreateProgram: '',
  isFetchingImageUploadCreateProgram: false,
  allAddress: [],
  allTracks: [],
  speakerSearch: [],
  isFetchSearchSpeaker: false,
  selectedTrackInfo: {},
  selectedAddressInfo: {},
  programsByTrack: [],
  programsByAddress: [],
  redactorProgram: {},
  redactorProgramsTracksOrAddress: {},
  selectedProgramId: '',
  updateProgram: {},
  availableFields: [],
  selectedFields: [],
  eventStartDate: '',
  eventEndDate: '',
  isFetchingCreateEvent: false,
  isFetchingUploadAvatarImage: false,
  isSpeakerCreatingOrUpdating: false,
  activeDateIndex: 0,
});
