import { TextTypeEnum } from '@/ui/components/text/app-text/AppTextTypes';

enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
  outline = 'outline',
}

enum ButtonWebType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

type TButtonType = keyof typeof ButtonType;
type TButtonWebType = keyof typeof ButtonWebType;
type TButtonSize = keyof typeof ButtonSize;
type TButtonText = keyof typeof TextTypeEnum;

export {
  ButtonSize,
  ButtonType,
  ButtonWebType,
  TButtonType,
  TButtonSize,
  TButtonText,
  TButtonWebType,
};
