var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowModal)?_c('AppModal',{attrs:{"is-show-modal":_vm.isShowModal,"text-head":"Открытие регистрации","type":"thirdTitle","is-align-text":true},on:{"toggleModal":_vm.toggleModal}},[_c('div',{staticClass:"app-modal-pick-date-registration"},[_c('div',{staticClass:"app-modal-pick-date-registration__date"},[_c('app-calendar-range',{attrs:{"is-have-title-and-subtitle":false,"default-end-date":`${
          _vm.eventInfo?.registrationEndDate
            ? `${_vm.eventInfo?.registrationEndDate.split('T')[0]}T12:00:00.000Z`
            : ''
        }`,"default-start-date":`${
          _vm.eventInfo?.registrationStartDate
            ? `${_vm.eventInfo?.registrationStartDate.split('T')[0]}T12:00:00.000Z`
            : ''
        }`},on:{"getRangeDay":_vm.onDayClick}}),_c('div',[_c('app-text',{attrs:{"text":"* Время начала и окончания","type":"primary-book"}}),_c('div',{staticClass:"app-modal-pick-date-registration__calendar-block-time"},[_c('div',{staticClass:"app-modal-pick-date-registration__time-container"},[_c('app-input',{staticClass:"app-modal-pick-date-registration__time-input",attrs:{"is-have-bottom-text-error":false,"is-have-empty-block":false,"placeholder":"c 12:00","type":"clock","maxlength":5},on:{"onInput":_vm.onInputTimeStart},model:{value:(_vm.inputValueTimeStart),callback:function ($$v) {_vm.inputValueTimeStart=$$v},expression:"inputValueTimeStart"}}),_c('app-input',{staticClass:"app-modal-pick-date-registration__time-input",attrs:{"is-have-bottom-text-error":false,"is-have-empty-block":false,"placeholder":"до 12:00","type":"clock","maxlength":5},on:{"onInput":_vm.onInputTimeEnd},model:{value:(_vm.inputValueTimeEnd),callback:function ($$v) {_vm.inputValueTimeEnd=$$v},expression:"inputValueTimeEnd"}})],1)]),_c('app-text',{staticStyle:{"margin-top":"5px"},attrs:{"type":"secondary","color":"secondary","tag":"p","text":`Часовой пояс события: ${this.eventInfo.originalTimezone.slice(0, 3)}`}})],1)],1),_c('div',{staticClass:"app-modal-pick-date-registration__input"},[_c('app-input',{attrs:{"label":"Целевое количество участников","placeholder":"Введите количество","value":_vm.inputValueTargetNumOfParticipants,"type":"numeric"},on:{"onInput":_vm.changeTargetNumOfParticipants}})],1),_c('div',{staticClass:"app-modal-pick-date-registration__buttons"},[_c('app-button',{attrs:{"text":"Отменить","type":"secondary"},on:{"click":_vm.cancel}}),_c('app-button',{attrs:{"text":"Сохранить"},on:{"click":_vm.onSave}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }