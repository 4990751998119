import { IState } from './state';
import {
  IEventProgramFavorite,
  IProgramInfo,
  IUserProgramDocument,
} from '@/services/ProgramService/ProgramServiceTypes';
import { EventProgramBannerAdsBlockData } from '@/helpers/types/event-program/event-program.types';

export const types = {
  SET_USER_DOCUMENTS_READ: 'SET_USER_DOCUMENTS_READ',
  SET_FAVORITE_PROGRAMS: 'SET_FAVORITE_PROGRAMS',
  SET_PROGRAM_INFO: 'SET_PROGRAM_INFO',
  LOGOUT_PROGRAM: 'LOGOUT_PROGRAM',
  ADD_FAVORITE_PROGRAM: 'ADD_FAVORITE_PROGRAM',
  DELETE_FAVORITE_PROGRAM: 'DELETE_FAVORITE_PROGRAM',
  READ_PROGRAM_DOCUMENT: 'READ_PROGRAM_DOCUMENT',
};

export default {
  [types.SET_USER_DOCUMENTS_READ](state: IState, documentsRead: Array<IUserProgramDocument>) {
    state.programDocumentsRead = documentsRead;
  },
  [types.SET_FAVORITE_PROGRAMS](state: IState, favoritePrograms: Array<IEventProgramFavorite>) {
    state.favoritePrograms = favoritePrograms;
  },
  [types.SET_PROGRAM_INFO](state: IState, programInfo: IProgramInfo) {
    state.programInfo = programInfo;
  },
  [types.LOGOUT_PROGRAM](state: IState) {
    state.programDocumentsRead = [];
    state.favoritePrograms = [];
  },
  [types.ADD_FAVORITE_PROGRAM](state: IState, favoriteProgram: IEventProgramFavorite) {
    state.favoritePrograms.push(favoriteProgram);
  },
  [types.DELETE_FAVORITE_PROGRAM](state: IState, programUUID: string) {
    state.favoritePrograms = state.favoritePrograms.filter(
      (item) => item.programUUID !== programUUID
    );
  },
  [types.READ_PROGRAM_DOCUMENT](state: IState, readDocument: IUserProgramDocument) {
    state.programDocumentsRead.push(readDocument);
  },
  setAdBanners(state: IState, adBanners: EventProgramBannerAdsBlockData | null) {
    state.adBanners = adBanners;
  },
};
