<template>
  <div
    class="event-constructor-option"
    :class="{ 'event-constructor-option__is-blocked': isBlocked }"
    @click="toggleActive"
  >
    <div class="event-constructor-option__header">
      <div class="event-constructor-option__text-container">
        <app-text type="primary-semibold" :text="title" />
        <div v-if="isBlocked" class="event-constructor-option__blocked-container">
          <img :src="require('@/assets/images/create-event/nextRelease.svg')" />
          <app-text text="скоро релиз" type="primary" color="text-on-dark-theme" />
        </div>
      </div>
      <div
        v-if="!isBlocked"
        class="event-constructor-option__icon-container"
        :class="{ 'event-constructor-option__icon-container-active': isActive }"
      >
        <app-icon v-if="isActive" name="check" color="#FFFFFF" size="18" />
      </div>
    </div>
    <img
      style="height: auto; width: 100%"
      :src="require(`@/assets/images/event-constructor/${image}`)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { AppText } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
export default Vue.extend({
  name: 'CreateConstructorOption',
  components: {
    AppText,
    AppIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
    isBlocked: {
      type: Boolean,
    },
  },
  methods: {
    toggleActive() {
      if (!this.isBlocked) {
        this.$emit('toggleActiveOptions');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
