<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="thirdTitle"
    text-head="Загрузка изображения"
  >
    <div class="app-modal-event-image">
      <div class="app-modal-event-image__item">
        <app-text text="Загрузите изображение" type="primary-book" line-height="25" />
        <div class="app-modal-event-image__input-container">
          <div
            v-if="isFetchingImagesRedactorEvent"
            class="app-modal-event-image__spinner-container"
          >
            <app-spinner size="small" />
          </div>
          <img
            class="app-modal-event-image__image"
            v-else-if="imageRedactorEventHeader"
            :src="imageRedactorEventHeader"
          />
          <input
            v-else
            id="add-file"
            type="file"
            name="add-file"
            :accept="acceptedFileTypes"
            class="app-modal-event-image__add-image-input"
            multiple
            @change="uploadImage"
            :disabled="!isActiveUpload"
          />
          <div
            v-if="!imageRedactorEventHeader && !isFetchingImagesRedactorEvent"
            class="app-modal-event-image__info"
          >
            <app-icon name="plus" size="18" color="#83888F" />
            <app-text
              text="Переместите фото с вашего пк в эту область или нажмите “+”"
              type="small"
              line-height="20"
              color="secondary"
            />
          </div>
        </div>
      </div>
      <app-text
        text="Соотношение сторон фото 16:9. При загрузке в систему фото будут кадрированы"
        type="small"
        color="secondary"
      />
      <app-input
        class="app-modal-event-image__item-link"
        placeholder="Вставьте ссылку на изображение"
        @onChange="getImageUrl"
        :disabled="!isActiveInputUrl"
      >
        <template #prepend>
          <app-icon name="link" color="#83888F" />
        </template>
      </app-input>
      <app-button text="Продолжить" @click="saveChanges" />
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppText, AppInput, AppButton } from '@/ui/components';
import AppSpinner from '@/components/common/app-spinner';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  components: {
    AppModal,
    AppText,
    AppIcon,
    AppInput,
    AppButton,
    AppSpinner,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      acceptedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      isActiveUpload: true,
      isActiveInputUrl: true,
    };
  },
  methods: {
    ...mapActions('admin', [
      'AChangeEventHeaderImage',
      'AUploadHeaderImage',
      'AClearEventHeaderImage',
      'setEventHeaderImage',
    ]),
    toggleModal() {
      this.$emit('toggleModal');
    },
    async uploadImage(event: Event): Promise<any> {
      event.preventDefault();
      //@ts-ignore
      let list = [...event.target!.files];
      const formData = new FormData();
      formData.append('image', list[0]);
      await this.AUploadHeaderImage(formData);
    },
    getImageUrl(value: string) {
      this.AChangeEventHeaderImage(value);
    },
    saveChanges() {
      this.setEventHeaderImage(this.imageRedactorEventHeader);
      this.toggleModal();
    },
  },
  computed: {
    ...mapState('admin', ['imageRedactorEventHeader', 'isFetchingImagesRedactorEvent']),
  },
  mounted() {
    this.AClearEventHeaderImage();
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
