<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Восстановление пароля"
  >
    <form v-if="!isLetterSent" @submit.prevent="recover">
      <div class="app-modal-recovery-by-email__subtitle">
        <app-text
          type="primary-book"
          color="secondary"
          :text="'Введите E-mail, который вы использовали при регистрации, на него мы отправим подтверждение сброса пароля.'"
        />
      </div>
      <app-input
        class="app-modal-recovery-by-email__input-email"
        label="E-mail"
        placeholder="example@mail.com"
        :value="email"
        :errors="errors['email']"
        @onInput="(val) => onInput(val)"
      />
      <app-button text="Отправить" web-type="submit" :is-disabled="!email" />
    </form>
    <div class="app-modal-recovery-by-email__container" v-else>
      <img
        class="app-modal-recovery-by-email__img"
        :src="require(`@/assets/images/Auth/sendLetter.svg`)"
      />
      <div class="app-modal-recovery-by-email__description">
        <app-text
          type="primary-book"
          color="main"
          text="Если ваша почта зарегистрирована на платформе, вы получите письмо с ссылкой на восстановление пароля. Если вы еще не зарегистрированы, пожалуйста, создайте аккаунт, чтобы начать пользоваться нашим сервисом."
        />
        <app-text
          type="primary-book"
          class="app-modal-recovery-by-email__spam"
          color="secondary"
          :text="'Нет письма? Проверьте папку «Спам»'"
        />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'AppModalRecoveryByEmail',
  components: { AppModal, AppText, AppInput, AppButton },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      errors: {},
      isLetterSent: false,
    };
  },
  methods: {
    ...mapActions('auth', ['ARecover']),
    toggleModal(): void {
      this.$emit('toggleModal');
      this.clearData();
    },
    clearData(): void {
      this.email = '';
      this.errors = {};
      this.isLetterSent = false;
    },
    onInput(val: string) {
      this.email = val;
    },
    async recover(): Promise<void> {
      try {
        await this.ARecover(this.email);
        this.isLetterSent = true;
      } catch (e: any) {
        if (e.name === 'Error') Vue.set(this.errors, 'email', [e.message]);
      }
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
