<template>
  <div>
    <div class="layout">
      <header
        ref="headerRef"
        class="fixed left-[0] right-[0] z-[100] bg-white transition-[transform] duration-300"
        :style="`transform: ${
          isHeaderHidden ? `translateY(-${headerHeight}px);` : `translateY(0);`
        }`"
      >
        <HeaderComponent :is-header-hidden="isHeaderHidden" />
      </header>

      <!--      Отступ чтобы хедер не наезжал на контент-->
      <main :style="`paddingTop: ${headerHeight}px;`">
        <router-view />
      </main>

      <footer id="footer">
        <FooterComponent variant="BASE" :nav-items="[]" :documents="documents" />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'Layout' };
</script>

<script setup lang="ts">
import HeaderComponent from '@/layouts/Header/Header.vue';
import FooterComponent from '@/layouts/Footer/Footer.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useWindowSizeStore } from '@/store/pinia/window-size/window-size.store';
import store from '@/store';
import { useRoute } from 'vue-router/composables';
import { ROUTES_WITH_PAGE_CUSTOMIZATION } from '@/helpers/constants/shared/page-customization.constants';

const route = useRoute();

const windowSizeStore = useWindowSizeStore();

const headerRef = ref<HTMLElement | null>(null);
const headerHeight = ref(0);
const setInitialHeaderHeight = () => {
  headerHeight.value = headerRef.value?.clientHeight ?? 0;
};
const isHeaderHidden = ref(false);

const startProcessScroll = () => {
  let lastScrollTop = 0;

  const header = headerRef.value;
  if (!header) return;

  window.addEventListener('scroll', () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    isHeaderHidden.value = scrollTop > lastScrollTop;

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  });
};

const documents = computed(() => {
  if (customDocuments.value.length) {
    return customDocuments.value;
  } else {
    return store.state.client?.clientInfo?.footer?.documents ?? [];
  }
});

const customDocuments = ref([]);
watch(
  () => [route.name, store.state.event?.eventInfo?.pageCustomization?.footerDocuments],
  ([newRouteName, newCustomFooterDocuments]) => {
    const isRouteWithCustomization = ROUTES_WITH_PAGE_CUSTOMIZATION.includes(newRouteName);
    if (isRouteWithCustomization) {
      customDocuments.value = newCustomFooterDocuments?.length ? newCustomFooterDocuments : [];
    } else {
      customDocuments.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => windowSizeStore.window,
  (newValue, oldValue) => {
    if (newValue !== oldValue) setInitialHeaderHeight();
  },
  {
    immediate: true,
  }
);
onMounted(() => {
  setInitialHeaderHeight();
  startProcessScroll();
});
</script>

<style scoped lang="scss">
main {
  overflow-x: clip;
  flex: 1 0 auto;
}
</style>
