var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{staticClass:"side-bar",class:{ 'side-bar--active': _vm.isActive }},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onCloseSideBar),expression:"onCloseSideBar"}],staticClass:"side-bar__container",class:{ 'side-bar__container--active': _vm.isActive },style:(_vm.isFirstTimeActivated ? '' : 'animation: none;')},[_c('div',{staticClass:"side-bar__header",class:{ 'side-bar__header--active': _vm.isActive },on:{"click":_vm.toggleActive}},[(_vm.isActive)?_c('app-text',{staticClass:"side-bar__header-text side-bar__text",class:{ 'side-bar__text--active': _vm.isActive },attrs:{"text":"Панель управления"}}):_vm._e(),_c('app-icon',{staticClass:"side-bar__header-icon",class:{ rotate: _vm.isActive },attrs:{"name":"chevrons-right","color":"#FFFFFF"}})],1),_c('div',{staticClass:"side-bar__content"},[_c('div',{staticClass:"side-bar__event-info"},[_c('img',{attrs:{"src":_vm.eventInfo.image,"alt":"Событие"}}),(_vm.isActive)?_c('app-text',{class:{ 'side-bar__text--active': _vm.isActive },attrs:{"text":_vm.eventInfo.title,"type":"primary"}}):_vm._e()],1),_c('div',{staticClass:"side-bar__block-title"}),_c('router-link',{staticClass:"side-bar__block-item",class:{ 'side-bar__block-item--active': _vm.isActive },attrs:{"to":{
          name: 'AdminEvents',
          query: { category: 'all' },
          params: {
            scrollBehaviour: `event-${_vm.eventInfo.uuid}`,
          },
        }}},[_c('div',{staticClass:"side-bar__indicator"}),_c('div',{staticClass:"side-bar__block-item-content",class:{ 'item-padding-left': !_vm.isActive }},[_c('app-icon',{attrs:{"color":"#83888F","name":"arrow-left"}}),(_vm.isActive)?_c('app-text',{staticClass:"side-bar__text",class:{ 'side-bar__text--active': _vm.isActive },attrs:{"color":"secondary","text":'К событиям'}}):_vm._e()],1)]),_c('div',{staticClass:"side-bar__block-title"}),_c('nav',{staticClass:"side-bar__block-content"},_vm._l((_vm.settingsFunctionality),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.isManager && item.route === 'admin-event-publication')),expression:"!(isManager && item.route === 'admin-event-publication')"}],key:index,on:{"click":_vm.onCloseSideBar}},[_c('router-link',{staticClass:"side-bar__block-item group",class:{
              'side-bar__block-item--active': _vm.isActive,
            },attrs:{"to":item.route}},[_c('div',{staticClass:"side-bar__indicator"}),_c('div',{staticClass:"side-bar__block-item-content",class:{ 'item-padding-left': !_vm.isActive }},[_c('UIIcon',{class:_vm.getIconColor(item.iconName),attrs:{"name":item.iconName,"size":24}}),(_vm.isActive)?_c('app-text',{staticClass:"side-bar__text",class:{ 'side-bar__text--active': _vm.isActive },attrs:{"color":"secondary","text":item.title}}):_vm._e()],1)])],1)}),0),_c('div',{staticClass:"side-bar__block-title"}),_c('div',{staticClass:"side-bar__block-content"},_vm._l((_vm.controlUsers),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.isManager && (item.iconName === 'tool' || item.iconName === 'gear'))),expression:"!(isManager && (item.iconName === 'tool' || item.iconName === 'gear'))"}],key:index,staticClass:"side-bar__block-item inactive",class:{ 'side-bar__block-item--active': _vm.isActive }},[_c('div',{staticClass:"side-bar__indicator"}),_c('div',{staticClass:"side-bar__block-item-content-blocked",class:{ 'item-padding-left': !_vm.isActive }},[_c('div',{staticClass:"side-bar__block-item-name-blocked"},[_c('UIIcon',{staticClass:"min-w-6",class:_vm.getIconColor(item.iconName),attrs:{"name":item.iconName,"size":24}}),(_vm.isActive)?_c('app-text',{staticClass:"side-bar__text",class:{ 'side-bar__text--active': _vm.isActive },attrs:{"color":"secondary","text":item.title}}):_vm._e()],1),(_vm.isActive && item.isBlocked)?_c('div',{staticClass:"side-bar__soon"},[_c('img',{attrs:{"src":require('@/assets/images/create-event/nextRelease.svg'),"alt":"Скоро"}}),_c('app-text',{attrs:{"type":"small","text":"скоро","color":"text-on-dark-theme"}})],1):_vm._e()])])}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }