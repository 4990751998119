import { defineStore } from 'pinia';

interface IWindowSize {
  verySmall: boolean;
  small: boolean;
  mobile: boolean;
  tablet: boolean;
  tabletAndMobile: boolean;
  desktop: boolean;
}

export const useWindowSizeStore = defineStore('windowSize', {
  state: () => ({
    windowWidth: document.documentElement.clientWidth,
    windowHeight: document.documentElement.clientHeight,
  }),
  getters: {
    isWindow(): IWindowSize {
      return {
        verySmall: this.windowWidth < 436,
        small: this.windowWidth < 594,
        mobile: this.windowWidth <= 768,
        tablet: this.windowWidth > 768 && this.windowWidth < 1101,
        tabletAndMobile: this.windowWidth < 992,
        desktop: this.windowWidth >= 1101,
      };
    },
    window(): 'mobile' | 'tablet' | 'desktop' {
      if (this.windowWidth <= 768) {
        return 'mobile';
      } else if (this.windowWidth > 768 && this.windowWidth < 1101) {
        return 'tablet';
      } else {
        return 'desktop';
      }
    },
  },
  actions: {
    init(): void {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    handleResize(): void {
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = document.documentElement.clientHeight;
    },
    cleanup(): void {
      window.removeEventListener('resize', this.handleResize);
    },
  },
});
