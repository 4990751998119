
import { computed, defineComponent, PropType } from 'vue';
import { TUILoaderSize } from '@/helpers/types/ui.types';

const __default__ = defineComponent({
  name: 'UILoader',
  props: {
    size: {
      type: String as PropType<TUILoaderSize>,
      required: false,
      default: 'default',
    },
  },
  setup(props) {
    const sizes: Record<TUILoaderSize, string> = {
      mini: '25px',
      small: '35px',
      default: '50px',
      large: '100px',
    };
    const svgWidth = computed(() => {
      return sizes[props.size];
    });

    return {
      svgWidth,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "760ed8bc": (_vm.svgWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__