import { IState } from './state';
import { IClientInfo, ISearchInfo } from '@/services/ClientService/ClientServiceTypes';

export const types = {
  SET_CLIENT_INFO: 'SET_CLIENT_INFO',
  SET_CLIENT_COLORS: 'SET_CLIENT_COLORS',
  SET_SEARCH_INFO: 'SET_SEARCH_INFO',
  SET_IS_FETCH_CLIENT_INFO: 'SET_IS_FETCH_CLIENT_INFO',
};

export default {
  [types.SET_SEARCH_INFO](state: IState, searchInfo: ISearchInfo) {
    state.searchInfo = searchInfo;
  },
  [types.SET_CLIENT_INFO](state: IState, clientInfo: IClientInfo) {
    state.clientInfo = clientInfo;
  },
  [types.SET_CLIENT_COLORS](state: IState, clientInfo: IClientInfo) {
    const root = document.body;
    state.colorsText = [{ color: clientInfo.colors.textOnDarkTheme }];
    state.colorsButton = [{ color: clientInfo.colors.buttonColor }];
    state.clientColor = clientInfo.colors.clientColor;
    root.style.setProperty('--button-color', clientInfo.colors.buttonColor);
    root.style.setProperty('--client-color', clientInfo.colors.clientColor);
    root.style.setProperty('--main-text-color', clientInfo.colors.mainTextColor);
    root.style.setProperty('--background-color', clientInfo.colors.backgroundColor);
    root.style.setProperty('--button-text-color', clientInfo.colors.buttonTextColor);
    root.style.setProperty('--text-color-footer', clientInfo.colors.textColorFooter);
    root.style.setProperty('--text-color-header', clientInfo.colors.textColorHeader);
    root.style.setProperty('--text-on-dark-theme', clientInfo.colors.textOnDarkTheme);
    root.style.setProperty('--secondary-text-color', clientInfo.colors.secondaryTextColor);
    root.style.setProperty('--background-color-footer', clientInfo.colors.backgroundColorFooter);
    root.style.setProperty('--background-color-header', clientInfo.colors.backgroundColorHeader);
    root.style.setProperty('--hero-text-color', clientInfo.colors.heroTextColor);
    root.style.setProperty(
      '--mailing-background-color',
      clientInfo.footer?.mailingBlock?.backgroundColor
    );
  },
  [types.SET_IS_FETCH_CLIENT_INFO](state: IState, status: boolean) {
    state.isFetchClientInfo = status;
  },
};
