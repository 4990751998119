import { EX_$Observer } from '@/classes/base';

interface IToaster {
  success(message: string): void;
  error(message: string): void;
  warning(message: string): void;
  info(message: string): void;
}

class $Toaster implements IToaster {
  success(message: string): void {
    EX_$Observer.context.$toastr.success(message);
  }

  error(message: string): void {
    EX_$Observer.context.$toastr.error(message);
  }

  warning(message: string): void {
    EX_$Observer.context.$toastr.warning(message);
  }

  info(message: string): void {
    EX_$Observer.context.$toastr.info(message);
  }
}

export default new $Toaster();
