<template>
  <div v-if="isVk" class="social-networks">
    <AppText type="main-medium" text="Создайте аккаунт через соцсети — так быстрее" />
    <div class="social-networks__container">
      <div @click="openGoogle">
        <img
          class="social-networks__img"
          :src="require(`@/assets/images/socialNetworks/google.svg`)"
        />
      </div>
      <div @click="openMail">
        <img
          class="social-networks__img"
          :src="require(`@/assets/images/socialNetworks/mail.svg`)"
        />
      </div>
      <div @click="openVk">
        <img class="social-networks__img" :src="require(`@/assets/images/socialNetworks/vk.svg`)" />
      </div>
      <div @click="openYandex">
        <img
          class="social-networks__img"
          :src="require(`@/assets/images/socialNetworks/yandex.svg`)"
        />
      </div>
      <div @click="openCm">
        <img
          class="social-networks__img"
          :src="require(`@/assets/images/socialNetworks/classmates.svg`)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';

export default Vue.extend({
  name: 'SocialNetworks',
  components: { AppText },
  methods: {
    openGoogle() {
      console.log('openGoogle');
    },
    openMail() {
      console.log('openMail');
    },
    openVk() {
      console.log('openVk');
    },
    openYandex() {
      console.log('openYandex');
    },
    openCm() {
      console.log('openCm');
    },
  },
  computed: {
    isVk(): boolean {
      // return true;
      return document.location.host === 'vk.smw.team';
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
