<template>
  <div class="app-calendar-range">
    <app-text v-if="isHaveTitleAndSubtitle" text="* Даты проведения события" type="primary-book" />
    <div class="app-calendar-range__container" :class="{ 'with-err': isError.length }">
      <div class="app-calendar-range__border" />
      <vc-date-picker
        :popover="{ visibility: 'focus' }"
        :select-attribute="attributes"
        :drag-attribute="attributes"
        :min-date="new Date()"
        :max-date="maxDate"
        v-model="dateRange"
        is-range
        class="app-calendar-range__calendar"
        @dayclick="onDayClick"
      />
      <div v-if="isHaveTitleAndSubtitle" class="app-calendar-range__calendar-text">
        <app-text
          type="secondary"
          color="secondary"
          text="Выберите даты проведения события. Вы можете выбрать один день или задать период проведения в несколько дней."
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { mapState } from 'vuex';
export default Vue.extend({
  name: 'AppCalendarRange',
  components: { AppText },
  props: {
    defaultStartDate: {
      type: String,
      default: '',
    },
    defaultEndDate: {
      type: String,
      default: '',
    },
    isHaveTitleAndSubtitle: {
      type: Boolean,
      default: true,
    },
    maxDate: {
      type: String,
      default: '',
    },
    isError: {
      type: Array,
      default: [] as Array<string>,
    },
  },
  computed: {
    ...mapState('client', ['clientColor']),
  },
  data() {
    return {
      attributes: {
        highlight: {
          backgroundColor: 'red',
          start: {
            style: {
              backgroundColor: '#83888F', // blue
            },
            contentStyle: {
              color: '#ffffff', // color of the text
            },
          },
          base: {
            style: {
              backgroundColor: '#83888F', // light blue
            },
            contentStyle: {
              color: '#ffffff', // color of the text
            },
          },
          end: {
            style: {
              backgroundColor: '#83888F', // blue
            },
            contentStyle: {
              color: '#ffffff', // color of the text
            },
          },
        },
      },
      dateRange: {
        start: new Date(this.defaultStartDate || ''),
        end: new Date(this.defaultEndDate || ''),
      },
    };
  },
  methods: {
    onDayClick() {
      const data = {
        startDate: this.dateRange.start.toISOString(),
        endDate: this.dateRange.end.toISOString(),
      };
      this.$emit('getRangeDay', data);
    },
  },
  mounted() {
    this.attributes.highlight.start.style.backgroundColor = this.clientColor;
    this.attributes.highlight.base.style.backgroundColor = this.clientColor;
    this.attributes.highlight.end.style.backgroundColor = this.clientColor;
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
