import {
  IAuthTokens,
  IChangePassword,
  ICitiesRequestData,
  IRegionsRequestData,
  IRegistrationFormResponseData,
  IMunicipalityRequestData,
} from '@/services/AuthService/AuthServiceTypes';
import clientHttp from '@/utils/custom/http/СlientHttp';
import { IRecover } from '@/services/ProgramService/ProgramServiceTypes';

export const getRegistrationForm = (slug: string) => {
  return clientHttp.get(
    `/event/${slug}/sign-up-form-fields`
  ) as Promise<IRegistrationFormResponseData>;
};

export const registration = (payload: any) => {
  return clientHttp.post(`/event/${payload.eventUuid}/sign-up`, payload.body);
};

export const validateRegistrationStep = (payload: any) => {
  return clientHttp.post('/event/validate-fields', payload);
};

export const refreshTokens = (refreshToken: string | null): Promise<IAuthTokens> => {
  return clientHttp.post('/auth/refresh', { refreshToken: refreshToken });
};

export const recover = (payload: IRecover): Promise<IAuthTokens> => {
  return clientHttp.post('/auth/request-password-recovery', payload);
};

export const changePassword = (payload: IChangePassword): Promise<IAuthTokens> => {
  return clientHttp.post('/auth/recover-password', payload);
};
export const getSearchCities = (data: ICitiesRequestData) => {
  return clientHttp.get(
    `/countries/${data.countryId}/regions/${data.regionId}/cities?search=${data.search}&regionName=${data.regionName}`
  );
};
export const getSearchRegions = (data: IRegionsRequestData) => {
  return clientHttp.get(`/countries/${data.countryId}/regions?search=${data.search}`);
};
export const getSearchCountries = (search: string) => {
  return clientHttp.get(`/countries`, { search: search });
};
export const getSearchMunicipalities = (data: IMunicipalityRequestData) => {
  return clientHttp.get(
    `/countries/${data.countryId}/regions/${data.regionId}/cities/${data.cityId}`,
    { search: data.search, regionName: data.regionName }
  );
};
