import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';

interface IHttp {
  get(resource: string, paramsData?: any): void; // отправить get-запрос
  post(resource: string, apiData: any): void; // отправить post-запрос
  postFile(resource: string, apiData: any): void; // отправить post-запрос
  delete(resource: string): void;
  put(resource: string, params: any): void;
}

interface IHttpClientOptions {
  _baseURL?: string;
  _headers?: Record<string, string | undefined> | undefined;
}

/**
 * @class - базовый класс для работы с сервером
 * @implements IHttp
 */
export default class $HttpClient implements IHttp {
  instance: AxiosInstance;

  constructor(options?: IHttpClientOptions) {
    this.instance = axios.create({
      baseURL: options?._baseURL,
      headers: options?._headers,
    });
  }

  async get(resource: string, paramsData?: object, config?: any): Promise<any> {
    const params = getParams(paramsData);

    return await this.instance.get(resource, { params, ...config });
  }

  async post(resource: string, apiData?: object, params?: object, config?: any): Promise<any> {
    const data: any = getParams(apiData);

    return await this.instance.post(resource, data, { params, ...config });
  }

  async patch(resource: string, apiData?: object, params?: object): Promise<any> {
    const data: any = getParams(apiData);

    return await this.instance.patch(resource, data, { params });
  }

  async postFile(resource: string, apiData?: object, params?: object): Promise<any> {
    // Используется, например, для разработки локально, где урл загрузки файлов отличается
    const baseUrl = process.env.VUE_APP_UPLOADER_BASE_URL ?? '';
    return this.instance.post(baseUrl + resource, apiData, params);
  }

  async delete(resource: string): Promise<any> {
    return await this.instance.delete(resource);
  }

  async put(resource: string, data: object): Promise<any> {
    return await this.instance.put(resource, data);
  }
}

function getParams(paramsData: any): any {
  return {
    ...paramsData,
  };
}
