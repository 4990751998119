import { render, staticRenderFns } from "./AppModalEditDescription.vue?vue&type=template&id=09b3ef7a&scoped=true"
import script from "./AppModalEditDescription.vue?vue&type=script&lang=ts"
export * from "./AppModalEditDescription.vue?vue&type=script&lang=ts"
import style0 from "./AppModalEditDescription.vue?vue&type=style&index=0&id=09b3ef7a&prod&lang=scss&scoped=true"
import style1 from "./AppModalEditDescription.vue?vue&type=style&index=1&id=09b3ef7a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b3ef7a",
  null
  
)

export default component.exports