<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Название блока"
    type="thirdTitle"
    :is-align-text="true"
  >
    <div class="app-modal-change-title">
      <div class="app-modal-change-title__item">
        <app-input
          placeholder="Введите заголовок модуля"
          type="text"
          label="Какой заголовок должен быть у модуля?"
          :value="inputValueNameBlock"
          :is-have-empty-block="false"
          @onInput="
            (val) => {
              inputValueNameBlock = val;
            }
          "
        />
      </div>
      <app-button @click="onClick" text="Продолжить" />
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import { AppButton, AppInput } from '@/ui/components';
export default Vue.extend({
  components: {
    AppModal,
    AppInput,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    titleBlock: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputValueNameBlock: '',
    };
  },
  methods: {
    onClick() {
      this.$emit('changeTitle', this.inputValueNameBlock);
      this.toggleModal();
    },
    toggleModal() {
      this.$emit('toggleModal');
    },
  },
  mounted() {
    this.inputValueNameBlock = this.titleBlock;
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
