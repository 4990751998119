import {
  IEvent,
  IEventInfo,
  IEventProgram,
  IFavoriteEvent,
  IInvitationsEvent,
  IMyEvent,
  IRecommended,
  IUserEventDocument,
} from '@/services/EventService/EventServiceTypes';
import { BannerAdsBlockData } from '@/helpers/types/shared/banners.types';
import { INewsItem } from '@/helpers/types/admin/admin-news.types';

export interface IState {
  eventList: Array<IEvent>;
  eventInfo: IEventInfo;
  news: INewsItem[] | null;
  myEventList: Array<IMyEvent>;
  favoriteEvents: Array<IFavoriteEvent>;
  eventDocumentsRead: Array<IUserEventDocument>;
  invitationsEventInfo: Array<IInvitationsEvent>;
  searchProgram: Array<IEventProgram>;
  recommended: IRecommended;
  imageEventUpload: string;
  isFetchingUploadImage: boolean;
  fileUpload: string;
  isFetchingUploadFile: boolean;
  activeEventFilter: 'all' | 'future' | 'past';
  adBanners: BannerAdsBlockData | null;
}
export default () => ({
  eventList: [],
  eventInfo: {} as IEventInfo,
  myEventList: [] as IMyEvent[],
  favoriteEvents: [],
  eventDocumentsRead: [],
  invitationsEventInfo: [],
  searchProgram: [],
  recommended: {},
  imageEventUpload: '',
  isFetchingUploadImage: false,
  fileUpload: '',
  isFetchingUploadFile: false,
  activeEventFilter: 'all',
  adBanners: null as BannerAdsBlockData | null,
});
