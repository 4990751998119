<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    text-head="Поля, заведенные при создании пространства"
    type="thirdTitle"
    @toggleModal="toggleModal"
    :is-align-text="true"
  >
    <div class="app-modal-custom-fields__fields">
      <div v-for="item in availableFields" :key="item.uuid">
        <div v-if="item.isBlocked" class="app-modal-custom-fields__blocked-item">
          <div class="app-modal-custom-fields__blocked-check">
            <app-icon size="14" name="check" color="#FFFFFF" />
          </div>
          <app-text :text="item.name" />
        </div>
        <app-check-box
          v-else
          type="checkbox"
          :text="item.name"
          :is-checked="selectedFieldsLocal.includes(item.uuid)"
          class="app-modal-custom-fields__switcher"
          @change="setCheckbox(item)"
        />
      </div>
    </div>
    <app-button text="Сохранить" @click="onSave" />
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import { EX_$Modal } from '@/classes/content';
import { mapActions, mapState } from 'vuex';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { IAvailableField } from '@/services/AdminService/AdminServiceTypes';

export default Vue.extend({
  name: 'AppModalCustomFields',
  components: { AppText, AppIcon, AppModal, AppButton, AppCheckBox },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      isPassHidden: true,
      selectedFieldsLocal: [] as Array<string>,
    };
  },
  computed: {
    ...mapState('admin', ['availableFields', 'selectedFields']),
  },
  methods: {
    ...mapActions('admin', ['AGetAvailableFields', 'ASetSelectedFields']),
    onSave() {
      this.toggleModal();
      this.ASetSelectedFields(this.selectedFieldsLocal);
    },
    toggleModal() {
      return this.$emit('toggleModal');
    },
    setCheckbox(field: IAvailableField) {
      if (this.selectedFieldsLocal.includes(field.uuid)) {
        this.selectedFieldsLocal = this.selectedFieldsLocal.filter(
          (item: string) => item !== field.uuid
        );
        //убираем галку с зависимых от этого чекбокса полей
        const dependsFields = this.availableFields.filter((item: IAvailableField) =>
          item.additionalOptions?.dependsOn?.includes(field.key)
        );
        dependsFields.map((field: IAvailableField) => {
          if (this.selectedFieldsLocal.includes(field.uuid)) {
            this.selectedFieldsLocal = this.selectedFieldsLocal.filter(
              (item: string) => item !== field.uuid
            );
          }
        });
      } else {
        this.selectedFieldsLocal.push(field.uuid);
        //ставим галки на чекбоксы, от которых зависит это поле
        if (field.additionalOptions?.dependsOn?.length) {
          field.additionalOptions.dependsOn.map((depends: string) => {
            const dependsField = this.availableFields.find(
              (field: IAvailableField) => field.key === depends
            );
            if (!this.selectedFieldsLocal.includes(dependsField.uuid)) {
              this.selectedFieldsLocal.push(dependsField.uuid);
            }
          });
        }
      }
    },
  },
  mounted() {
    this.selectedFieldsLocal = [...this.selectedFields];
    this.AGetAvailableFields();
  },
});
</script>

<style lang="scss" scoped>
@use 'style';
</style>
