<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    text-type="fourthTitle"
    text-head="Карьера"
    :is-confirm-exit="true"
  >
    <div class="app-modal-profile-edit-career">
      <div class="app-modal-profile-edit-career__date">
        <app-input
          placeholder="Введите дату"
          label="Начало работы"
          type="date"
          :errors="errorStartDate"
          :value="inputValueStartDate.split('.').reverse().join('-')"
          @onInput="
            (val) => {
              inputValueStartDate = val;
            }
          "
        />
        <app-input
          v-if="!isUntilNow"
          placeholder="Введите дату"
          label="Окончание"
          type="date"
          :value="inputValueEndDate.split('.').reverse().join('-')"
          :errors="errorEndDate"
          @onInput="
            (val) => {
              inputValueEndDate = val;
            }
          "
        />
      </div>
      <app-check-box
        :is-checked="isUntilNow"
        class="app-modal-profile-edit-career__checkbox"
        text="По настоящее время"
        @input="toggleIsUntilNow"
        type-text="primary"
      />
      <app-input
        placeholder="Введите название организации"
        label="В какой организации работаете?"
        :value="inputValueOrganizationName"
        @onInput="
          (val) => {
            inputValueOrganizationName = val;
          }
        "
      />
      <app-input
        placeholder="Введите вашу должность"
        label="Какая у вас должность?"
        :value="inputValueJobTitle"
        @onInput="
          (val) => {
            inputValueJobTitle = val;
          }
        "
      />
      <app-input
        class="textarea"
        placeholder="Введите вашу область ответственности"
        label="Ваша область ответственности"
        tag="textarea"
        :value="inputValueAreaOfResponsibility"
        @onInput="
          (val) => {
            inputValueAreaOfResponsibility = val;
          }
        "
      />
    </div>
    <app-button
      text="Сохранить изменения"
      @click="saveProfileCareer"
      :is-disabled="isActiveSubmitButton"
    />
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton, AppInput } from '@/ui/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { EX_$Toaster } from '@/classes/content';
import { formattedDate } from '@/utils/custom';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import { ICareerPath, ICountry } from '@/services/ProfileService/ProfileServiceTypes';
import { IPayloadChangeProfile } from '@/ui/components/modals/user-management/app-modal-management-user-profile/ManagementUserProfileTypes';

export default Vue.extend({
  components: {
    AppModal,
    AppInput,
    AppButton,
    AppCheckBox,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    activeElement: {
      type: Number,
    },
  },
  data() {
    return {
      EX_Toaster: EX_$Toaster,
      careerPathList: [{}],
      inputValueStartDate: '',
      inputValueEndDate: '',
      inputValueJobTitle: '',
      inputValueOrganizationName: '',
      inputValueAreaOfResponsibility: '',
      errorEndDate: [] as Array<string>,
      errorStartDate: [] as Array<string>,
      isUntilNow: false,
      countryValue: {} as ICountry,
      firstName: '',
      lastName: '',
    };
  },
  watch: {
    sortedCareer() {
      this.careerPathList = this.sortedCareer;
    },
  },
  methods: {
    ...mapActions('profile', ['AChangeProfileCareer', 'AGetProfile']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    /**
     * @description Переключения чекбокса "По настоящее время"
     */
    toggleIsUntilNow() {
      this.isUntilNow = !this.isUntilNow;
      if (this.isUntilNow) {
        this.inputValueEndDate = '';
      }
    },
    saveProfileCareer() {
      const startDate = `${this.inputValueStartDate
        ?.toString()
        .trim()
        .split('.')
        .reverse()
        .join('-')}T00:00:00.000Z`;
      const endDate = `${this.inputValueEndDate
        ?.toString()
        .trim()
        .split('.')
        .reverse()
        .join('-')}T00:00:00.000Z`;
      const nowDate = Date.now();

      if (this.isUntilNow && Date.parse(startDate) <= nowDate) {
        if (this.activeElement === 0) {
          this.careerPathList.push({
            startDate: this.inputValueStartDate,
            endDate: null,
            organizationName: this.inputValueOrganizationName,
            areaOfResponsibility: this.inputValueAreaOfResponsibility.split(/\r\n|\r|\n/g),
            jobTitle: this.inputValueJobTitle,
          });
        } else {
          this.careerPathList[this.activeElement - 1] = {
            startDate: this.inputValueStartDate.split('.').reverse().join('-'),
            endDate: null,
            organizationName: this.inputValueOrganizationName,
            areaOfResponsibility: this.inputValueAreaOfResponsibility.split(/\r\n|\r|\n/g),
            jobTitle: this.inputValueJobTitle,
          };
        }
      } else if (
        startDate <= endDate &&
        Date.parse(startDate) <= nowDate &&
        Date.parse(endDate) <= nowDate
      ) {
        if (this.activeElement === 0) {
          this.careerPathList.push({
            startDate: this.inputValueStartDate,
            endDate: this.inputValueEndDate,
            organizationName: this.inputValueOrganizationName,
            areaOfResponsibility: this.inputValueAreaOfResponsibility.split(/\r\n|\r|\n/g),
            jobTitle: this.inputValueJobTitle,
          });
        } else {
          this.careerPathList[this.activeElement - 1] = {
            startDate: this.inputValueStartDate.split('.').reverse().join('-'),
            endDate: this.inputValueEndDate.split('.').reverse().join('-'),
            organizationName: this.inputValueOrganizationName,
            areaOfResponsibility: this.inputValueAreaOfResponsibility.split(/\r\n|\r|\n/g),
            jobTitle: this.inputValueJobTitle,
          };
        }
      }

      if (
        (startDate <= endDate &&
          Date.parse(startDate) <= nowDate &&
          Date.parse(endDate) <= nowDate) ||
        (this.isUntilNow && this.inputValueStartDate && Date.parse(startDate) <= nowDate)
      ) {
        const payload: IPayloadChangeProfile = {
          careerPath: this.careerPathList as Array<ICareerPath>,
        };
        this.AChangeProfileCareer(payload);
        this.errorEndDate = [];
        this.errorStartDate = [];
        this.toggleModal();
      } else {
        if (!this.inputValueStartDate) {
          this.errorStartDate = ['Обязательное поле'];
        }
        if (
          !this.isUntilNow &&
          (!this.inputValueEndDate || startDate > endDate || Date.parse(endDate) > nowDate)
        ) {
          this.errorEndDate = ['Некорректная дата'];
        }
        if (Date.parse(startDate) > nowDate) {
          this.errorStartDate = ['Некорректная дата'];
        }
        this.EX_Toaster.error('Проверьте правильность заполнения полей');
      }
    },
  },
  computed: {
    ...mapState('profile', ['profileInfo']),
    ...mapGetters('profile', ['sortedCareer']),
    getStartDate(): string {
      return formattedDate(this.sortedCareer[this.activeElement - 1].startDate, 'dateFullYear');
    },
    getEndDate(): string {
      return formattedDate(this.sortedCareer[this.activeElement - 1].endDate, 'dateFullYear');
    },
    isActiveSubmitButton(): boolean {
      return (
        (!this.inputValueStartDate ||
          !this.inputValueJobTitle ||
          !this.inputValueOrganizationName ||
          !this.inputValueAreaOfResponsibility) &&
        (!this.inputValueEndDate || !this.isUntilNow)
      );
    },
  },
  mounted() {
    this.countryValue = this.profileInfo?.profile?.countryOfResidence;
    this.firstName = this.profileInfo?.profile?.firstName;
    this.lastName = this.profileInfo?.profile?.lastName;
    if (this.activeElement > 0) {
      this.inputValueStartDate = formattedDate(
        this.sortedCareer[this.activeElement - 1].startDate,
        'dateFullYear'
      );
      if (this.sortedCareer[this.activeElement - 1].endDate !== null) {
        this.inputValueEndDate = formattedDate(
          this.sortedCareer[this.activeElement - 1].endDate,
          'dateFullYear'
        );
      } else {
        this.isUntilNow = true;
      }
      this.inputValueJobTitle = this.sortedCareer[this.activeElement - 1].jobTitle;
      this.inputValueOrganizationName = this.sortedCareer[this.activeElement - 1].organizationName;
      this.inputValueAreaOfResponsibility =
        this.sortedCareer[this.activeElement - 1].areaOfResponsibility.join('\n');
    } else {
      this.inputValueStartDate = '';
      this.inputValueEndDate = '';
      this.inputValueJobTitle = '';
      this.inputValueOrganizationName = '';
      this.inputValueAreaOfResponsibility = '';
      this.isUntilNow = false;
    }
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
.app-modal-profile-edit-career {
  .app-input__inner_input {
    resize: none;
  }
  .textarea {
    .app-input__inner_input {
      min-height: 75px;
      resize: none;
      &::-webkit-scrollbar {
        width: 6px;

        &-thumb {
          border-radius: 6px;
          background-color: var(--client-color);
          border: 1px solid transparent;
          background-clip: content-box;
        }

        &-track {
          background: white;
          border-radius: 6px;
        }
      }
    }
  }
  .app-modal-profile-edit-career__checkbox {
    width: 190px;
  }
}
</style>
