<template>
  <div class="app-slider">
    <swiper :options="swiperOptions">
      <swiper-slide v-for="item in list" :key="item.id">
        <slot />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default Vue.extend({
  name: 'AppSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 24,
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        navigation: true,
      },
    };
  },
  props: {
    list: {
      type: Array,
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'style';
</style>
