import { render, staticRenderFns } from "./AppModalCustomFields.vue?vue&type=template&id=296c582e&scoped=true"
import script from "./AppModalCustomFields.vue?vue&type=script&lang=ts"
export * from "./AppModalCustomFields.vue?vue&type=script&lang=ts"
import style0 from "./AppModalCustomFields.vue?vue&type=style&index=0&id=296c582e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296c582e",
  null
  
)

export default component.exports