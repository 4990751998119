<template>
  <component
    :is="tag"
    v-if="!isFetch"
    :class="`app-text ${getClass} color-${color}`"
    :style="`line-height: ${lineHeight}px; ${specialTextColor ? `color: ${specialTextColor}` : ''}`"
    class="app-text"
    v-on="$listeners"
  >
    {{ text }}
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IText, ITextColor, TextColorEnum, TextTypeEnum } from './AppTextTypes';

export default Vue.extend({
  name: 'AppText',
  props: {
    text: {
      type: [String, Number],
      default: '',
      required: true,
    },
    type: {
      type: String as PropType<IText>,
      default: 'main',
      validator: (value) => Object.values(TextTypeEnum).includes(value as IText),
    },
    tag: {
      type: String,
      default: 'span',
    },
    isFetch: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<ITextColor>,
      default: 'main',
      validator: (value) => Object.values(TextColorEnum).includes(value as ITextColor),
    },
    lineHeight: {
      type: String,
    },
    specialTextColor: {
      type: String,
    },
  },
  computed: {
    getClass(): string {
      return this.type?.replaceAll('-', '__');
    },
    getTag(): string {
      switch (this.type) {
        case 'title':
          return 'h1';
        case 'block':
          return 'p';
        default:
          return 'span';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
