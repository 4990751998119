import { Commit } from 'vuex';
import { types } from './mutations';
import { EventService, ProfileService, AdminService } from '@/services';
import { IChangeProfileInfo } from '@/services/ProfileService/ProfileServiceTypes';
import { AxiosError } from 'axios';

export default {
  async AGetProfile({ commit }: { commit: Commit }): Promise<void> {
    try {
      const res = await ProfileService.getProfileInfo();
      commit(types.SET_PROFILE, res);
    } catch (e) {
      console.log(e);
    }
  },
  AClearProfileInfo({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_PROFILE_INFO);
  },

  async AChangeProfilePersonalInfo(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfilePersonalInfo(body);
      commit(types.SET_PROFILE_CHANGES, body);
      commit(types.SET_ERROR_STATUS_CODE, null);
    } catch (error) {
      const err = error as AxiosError;
      commit(types.SET_ERROR_STATUS_CODE, err.response?.status);
      console.log(error);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AChangeProfileAvatar(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfileAvatar(body);
      commit(types.SET_PROFILE_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AChangeProfileDescription(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfileDescription(body);
      commit(types.SET_PROFILE_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AChangeProfileCareer(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfileCareer(body);
      commit(types.SET_PROFILE_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AChangeProfileEducation(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfileEducation(body);
      commit(types.SET_PROFILE_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AChangeProfilePortfolio(
    { commit }: { commit: Commit },
    body: IChangeProfileInfo
  ): Promise<void> {
    try {
      await ProfileService.changeProfilePortfolio(body);
      commit(types.SET_PROFILE_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, false);
  },

  async AUploadImageProfile({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_IMAGE_PROFILE, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.SET_IMAGE_UPLOAD_PROFILE, res.url);
    } catch (e) {
      console.log(e);
    }
  },

  AClearProfileImage({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_PROFILE_IMAGE);
  },

  async AUploadFileProfilePortfolio({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_PROFILE_UPLOAD_FILE, true);
    try {
      const res = await AdminService.uploadAll(body);
      commit(types.SET_PROFILE_FILE_UPLOAD, res.url);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_FETCHING_PROFILE_UPLOAD_FILE, false);
  },
};
