import { VNode } from 'vue';

export function checkIsSlotFilled(slot: VNode[] | undefined): boolean {
  if (!slot) {
    return false;
  }

  const visibleElements = slot.filter((vnode) => {
    // элементы с v-if=false считаются за закоментированные
    return !vnode.isComment;
  });

  return Boolean(visibleElements.length);
}
