<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Технические работы"
    type="thirdTitle"
    :is-align-text="true"
  >
    <div class="app-modal-technical-work">
      <app-text
        tag="p"
        text="В настоящее время мы проводим обновление сервиса чтобы расширить функциональные возможности для вас. Некоторые функции могут быть временно недоступны. Приносим извинения за неудобства."
        line-height="30"
      />
      <img :src="require(`@/assets/images/technicalWork/TechnicalWork.svg`)" alt="mmco logo" />
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
export default Vue.extend({
  components: {
    AppText,
    AppModal,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggleModal');
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'style';
</style>
