<template>
  <div
    v-if="isShowModal"
    class="app-modal-wrap animate"
    :class="{
      'open-modal': isShowModal,
      'close-modal': !isShowModal,
      top: positionModal === 'top',
      center: positionModal === 'center',
    }"
    @mousedown="onBackGroundClick"
  >
    <div class="modal-main__icon-mobile-container">
      <div
        v-if="$isWindow.small && !isHideIconClose && !fullOnMobile"
        class="modal-main__icon-mobile"
        @click="toggleModal"
      >
        <app-icon :size="16" color="#83888F" name="close" />
      </div>
    </div>
    <div
      class="modal-main"
      @mousedown="stopPropagation"
      :class="{
        'full-on-mobile': fullOnMobile,
        'large-size': size === 'large',
      }"
    >
      <div id="appModalTop" style="margin-top: -25px"></div>

      <div v-if="textHead" class="modal-main__head" :class="{ 'text-left': isAlignText }">
        <app-text :type="textType" :text="textHead" />
      </div>
      <div
        v-if="!$isWindow.small && !isHideIconClose"
        class="modal-main__icon"
        @click="toggleModal"
      >
        <app-icon :size="24" color="#83888F" name="close" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { TPositionModal, TSizeModal } from '@/ui/components/modals/app-modal/ModalTypes';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { MWidthWindow } from '@/mixins';
import { IText } from '@/ui/components/text/app-text/AppTextTypes';
import { EX_$Modal } from '@/classes/content';

export default defineComponent({
  name: 'AppModal',
  components: { AppIcon, AppText },
  mixins: [MWidthWindow],
  props: {
    textHead: {
      type: String,
      required: false,
    },
    textType: {
      type: String as PropType<IText>,
      default: 'thirdTitle',
    },
    isShowModal: {
      type: Boolean,
      required: true,
    },
    positionModal: {
      type: String as PropType<TPositionModal>,
      required: false,
      default: 'top',
    },
    isCanCloseByBackgroundClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHideIconClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAlignText: {
      type: Boolean,
      default: false,
    },
    isConfirmExit: {
      type: Boolean,
      default: false,
    },
    fullOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String as PropType<TSizeModal>,
      required: false,
    },
    innerWidth: {
      type: String,
      required: false,
      default: '560px',
    },
  },
  data() {
    return {
      EX_ModalClass: EX_$Modal,
    };
  },
  methods: {
    toggleModal(): void {
      if (this.isConfirmExit) {
        this.EX_ModalClass.toggleModal('appModalConfirmExit');
      } else this.$emit('toggleModal');
    },
    onBackGroundClick() {
      if (this.isCanCloseByBackgroundClick) {
        if (this.isConfirmExit) {
          this.EX_ModalClass.toggleModal('appModalConfirmExit');
        } else this.toggleModal();
      }
    },
    stopPropagation(e: Event) {
      e.stopPropagation();
    },
    lockBody() {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.classList.add('lock');
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    },
    unlockBody() {
      document.body.classList.remove('lock');
      document.body.style.paddingRight = '';
    },
  },
  mounted() {
    this.lockBody();
  },
  destroyed() {
    this.unlockBody();
  },
});
</script>

<style lang="scss">
@import '~@/ui/styles/colors/index.scss';

.app-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(18.5px);
  z-index: 1000;

  .modal-main {
    //width: 660px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: minmax(v-bind(innerWidth), v-bind(innerWidth));
    overflow-y: auto;
    max-height: 80%;
    padding: 25px 50px;
    border-radius: 24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }

    background-color: $WHITE;

    &__head {
      display: flex;
      justify-content: center;
      .app-text {
        @media (max-width: 610px) {
          width: 100%;
        }
        //@media (max-width: 365px) {
        //  font-size: 30px;
        //}
        width: calc(100% - 25px);
      }
      margin-bottom: 16px;
      text-align: center;
    }
    .text-left {
      display: flex;
      align-items: center;
      justify-content: left;
      text-align: left;
      margin-top: 3px;
    }
    &__icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
    }
    &__icon-mobile-container {
      display: flex;
      height: 80px;
      margin: 0 auto;
      align-items: center;
      justify-content: flex-end;
      max-width: calc(100% - 40px);
      @media (max-width: 610px) {
        max-width: 420px;
      }
      @media (max-width: 440px) {
        max-width: 360px;
      }
      @media (max-width: 380px) {
        max-width: 340px;
      }
      @media (max-width: 365px) {
        max-width: 300px;
      }
    }
    &__icon-mobile {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $WHITE;

      top: 25px;
      right: 40px;
      @media (max-width: 440px) {
        right: 40px;
      }
    }
    @media (max-width: 700px) {
      box-sizing: initial;
      grid-template-columns: minmax(310px, 600px);
      max-width: 520px;
      width: 100%;
      padding: 25px 20px;
    }

    @media (max-width: 610px) {
      grid-template-columns: minmax(310px, 600px);
      max-width: 380px;
      width: 100%;
      padding: 25px 20px;
    }

    @media (max-width: 440px) {
      grid-template-columns: minmax(270px, 320px);
      max-width: 320px;
    }
    @media (max-width: 380px) {
      grid-template-columns: 300px;
      max-width: 300px;
    }

    @media (max-width: 365px) {
      grid-template-columns: 260px;
      max-width: 260px;
    }
  }

  &.top {
    padding: 0 0;
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .large-size {
    max-height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.open-modal {
    .modal-main {
      animation-duration: 0.3s;
      animation-name: opacity;
      visibility: visible;
    }
  }

  &.close-modal {
    animation-duration: 0.3s;
    animation-name: opacity-reverse;

    .modal-main {
      animation-duration: 0.3s;
      animation-name: scale-dialog-reverse;
    }
  }

  &.animate {
    animation-duration: 0.15s;
    animation-name: animate-dialog;
  }

  @media (max-width: 600px) {
    padding: 20px 10px;
  }
}
.full-on-mobile {
  @media (max-width: 1100px) {
    max-width: none !important;
    width: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    border-radius: 0 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    box-sizing: unset !important;
    transform: unset !important;
    grid-template-columns: unset !important;
    padding: 0 !important;
  }
}

@keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-dialog {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-dialog-reverse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}
</style>
