<template>
  <div class="app-select-wrap" v-click-outside="closeList">
    <slot name="label">
      <label v-if="label" class="app-select-wrap__label">
        <app-text :text="label" type="primary-book" />
      </label>
    </slot>
    <button
      class="selected-block"
      :class="{ active: isShowList, 'with-err': errors.length }"
      @click="toggleIsShowList"
    >
      <div class="selected-block__container">
        <div
          v-if="(Object.keys(this.activeItem) || []).length && isColor"
          :style="`background-color:${this.activeItem[this.listKey]}`"
          class="app-select-wrap__color"
        />
        <app-text
          v-if="(Object.keys(this.activeItem) || []).length"
          class="selected-block__name"
          type="main"
          :text="this.activeItem[this.listKey]"
        />
        <app-text v-else :color="'secondary'" :text="titleText" type="main" />
      </div>
      <app-icon
        class="app-select-wrap__chevron-down"
        name="chevron-down"
        :class="{ 'app-select-wrap__chevron-down_active': isShowList }"
      />
    </button>
    <div v-if="isShowList" class="select-list" :class="{ scroll: items.length > 7 }">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="select-list__item"
        :class="{ active: item[listKey] === activeItem[listKey] }"
        @click="changeActiveItem(item)"
      >
        <div
          v-if="isColor"
          class="app-select-wrap__color"
          :style="`background-color:${item[listKey]}`"
        />
        <app-text :text="item[listKey]" />
      </button>
    </div>
    <span v-if="!!errors.length && isHaveBottomText" class="app-input__error">
      <app-text
        v-for="(err, i) in errors"
        :key="err"
        :text="`${err}${i < errors.length - 1 ? '.' : ''}`"
        type="small-book"
        :color="'error'"
      />
    </span>
    <div v-else-if="isHaveEmptyBlock" class="app-select-wrap__empty" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { ISelectItem } from '@/ui/components/selects/app-multi-select/AppMultiSelectTypes';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';

export default Vue.extend({
  name: 'AppSelect',
  components: { AppIcon, AppText },
  props: {
    defaultValue: {
      type: Object,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    listKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isColor: {
      type: Boolean,
      default: false,
    },
    isHaveBottomText: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHaveEmptyBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
    isChangeLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const activeItem: ISelectItem = {};
    return {
      isShowList: false,
      activeItem,
    };
  },
  beforeMount() {
    this.activeItem = { ...this.defaultValue };
  },
  computed: {
    getTitle() {
      let title = '';
      if (Object.keys(this.activeItem)?.length) {
        title = this.activeItem[this.listKey];
      }
      return title || this.titleText;
    },
  },
  methods: {
    toggleIsShowList(): void {
      if (!this.disabled) {
        this.isShowList = !this.isShowList;
      }
    },
    changeActiveItem(value: ISelectItem): void {
      this.closeList();
      if (this.activeItem[this.listKey] == value[this.listKey]) {
        this.activeItem = {};
      } else {
        this.activeItem = { ...value };
      }
      this.$emit('onSelect', this.activeItem);
    },
    closeList(): void {
      this.isShowList = false;
    },
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
