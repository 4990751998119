<template>
  <div class="app-pagination">
    <div
      class="app-pagination__btn-prev app-pagination__btn"
      :class="{ 'btn-disabled': EX_$AppPaginationClass.isDisabledPrev }"
    >
      <div @click="EX_$AppPaginationClass.goToFirst()">
        <app-icon
          :color="EX_$AppPaginationClass.isDisabledPrev ? '#83888F' : '#111111'"
          name="arrow-left"
        />
      </div>
      <div @click="EX_$AppPaginationClass.goToPrev()">
        <app-text
          :color="EX_$AppPaginationClass.isDisabledPrev ? 'secondary' : 'main'"
          type="primary"
          text="Назад"
        />
      </div>
    </div>
    <div class="app-pagination__pages">
      <span
        :class="['app-pagination__pages_dots', { visible: EX_$AppPaginationClass.currentPage > 2 }]"
        @click="EX_$AppPaginationClass.goToFirst()"
      >
        ...
      </span>
      <div class="app-pagination__pages_list">
        <div
          v-for="item in EX_$AppPaginationClass.paginationController"
          :key="item"
          :class="[
            'app-pagination__pages_list-item',
            { active: item === EX_$AppPaginationClass.currentPage },
          ]"
          @click="EX_$AppPaginationClass.goToPage(item)"
        >
          <app-text :text="item" />
        </div>
      </div>
      <span
        :class="[
          'app-pagination__pages_dots',
          { visible: EX_$AppPaginationClass.currentPage < EX_$AppPaginationClass.getMaxPage - 1 },
        ]"
        @click="EX_$AppPaginationClass.goToLast()"
      >
        ...
      </span>
    </div>
    <div
      class="app-pagination__btn-next app-pagination__btn"
      :class="{ 'btn-disabled': EX_$AppPaginationClass.isDisabledNext }"
    >
      <div @click="EX_$AppPaginationClass.goToNext()">
        <app-text
          :color="EX_$AppPaginationClass.isDisabledNext ? 'secondary' : 'main'"
          type="primary"
          text="Далее"
        />
      </div>
      <div @click="EX_$AppPaginationClass.goToLast()">
        <app-icon
          :color="EX_$AppPaginationClass.isDisabledNext ? '#83888F' : '#111111'"
          name="arrow-right"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import $AppPaginationClass from '@/classes/base/pagination';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';

export default Vue.extend({
  name: 'AppPagination',
  components: { AppIcon, AppText },
  props: {
    count: {
      type: Number,
      required: true,
    },
    stepPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      EX_$AppPaginationClass: $AppPaginationClass,
    };
  },
  watch: {
    'EX_$AppPaginationClass.currentPage'() {
      this.$emit('onPaginate', this.EX_$AppPaginationClass.currentPage);
    },
    page(value) {
      this.EX_$AppPaginationClass.currentPage = value;
    },
    count() {
      this.paginationInit();
    },
  },
  mounted() {
    this.paginationInit();
  },
  methods: {
    paginationInit() {
      this.EX_$AppPaginationClass.count = this.count;
      this.EX_$AppPaginationClass.itemOnPage = this.stepPage;
      this.EX_$AppPaginationClass.currentPage = this.page;
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
