<template>
  <button @click.stop="changeActive" class="heart">
    <svg
      v-if="isProps"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z"
        fill="black"
        fill-opacity="0.6"
      />
      <path
        d="M21.09 14.26L18 8L14.91 14.26L8 15.27L13 20.14L11.82 27.02L18 23.77L24.18 27.02L23 20.14L28 15.27L21.09 14.26Z"
        fill="#FFC93E"
        stroke="#FFC93E"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8V28C0 32.4183 3.58172 36 8 36H28C32.4183 36 36 32.4183 36 28V8C36 3.58172 32.4183 0 28 0H8ZM18.807 7.60164C18.6554 7.29447 18.3426 7.1 18 7.1C17.6574 7.1 17.3446 7.29447 17.193 7.60164L14.3122 13.4378L7.86983 14.3795C7.53093 14.429 7.24952 14.6666 7.14388 14.9924C7.03824 15.3182 7.12668 15.6757 7.37204 15.9147L12.0329 20.4544L10.933 26.8679C10.875 27.2055 11.0139 27.5468 11.291 27.7481C11.5682 27.9495 11.9357 27.976 12.2389 27.8166L18 24.7869L23.7611 27.8166C24.0643 27.976 24.4318 27.9495 24.709 27.7481C24.9861 27.5468 25.125 27.2055 25.067 26.8679L23.9671 20.4544L28.628 15.9147C28.8733 15.6757 28.9618 15.3182 28.8561 14.9924C28.7505 14.6666 28.4691 14.429 28.1302 14.3795L21.6878 13.4378L18.807 7.60164ZM15.717 14.6584L18 10.0333L20.283 14.6584C20.4139 14.9237 20.667 15.1077 20.9598 15.1505L26.0664 15.8969L22.372 19.4953C22.1598 19.702 22.0629 20.0001 22.113 20.2921L22.9846 25.3745L18.4189 22.9734C18.1567 22.8355 17.8433 22.8355 17.5811 22.9734L13.0154 25.3745L13.887 20.2921C13.9371 20.0001 13.8402 19.702 13.628 19.4953L9.93357 15.8969L15.0402 15.1505C15.333 15.1077 15.5861 14.9237 15.717 14.6584Z"
        fill="white"
        fill-opacity="0.5"
      />
    </svg>
  </button>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {};
  },
  props: {
    isProps: {
      type: Boolean,
    },
  },
  methods: {
    changeActive() {
      if (this.isProps) {
        this.$emit('removeFavoriteCard');
      } else {
        this.$emit('addFavoriteCard');
      }
    },
  },
});
</script>
<style lang="scss">
@import 'style';
</style>
