import clientHttp from '@/utils/custom/http/СlientHttp';
import { ISubscribeToNewsLetterRequestData } from '@/services/ClientService/ClientServiceTypes';

export const getInfo = () => {
  return clientHttp.get('/client/info');
};
export const getSearchInfo = (search: string) => {
  return clientHttp.get(`/event/search/`, {
    search: search,
  });
};
export const subscribeToNewsLetter = (data: ISubscribeToNewsLetterRequestData) => {
  return clientHttp.post(`/newsletter`, { email: data.email });
};
