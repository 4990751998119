import { IState } from './state';
import { formattedDate } from '@/utils/custom';

export default {
  getPrograms: (state: IState) => {
    const sortedPrograms = {} as any;
    const copyArray = [...(state.adminPrograms || [])];
    copyArray?.sort((a, b) => {
      const dateTampA = new Date(a.startDate.slice(0, 19));
      const dateTampB = new Date(b.startDate.slice(0, 19));
      return dateTampA.getTime() - dateTampB.getTime();
    });
    copyArray?.forEach((item) => {
      const fullDate = `${formattedDate(item.startDate.slice(0, 19), 'dayOfWeek')}, ${formattedDate(
        item.startDate.slice(0, 19),
        'dateFullMonth'
      )}, ${formattedDate(item.startDate.slice(0, 19), 'onlyYear')}`;
      if (Object.keys(sortedPrograms).includes(fullDate)) {
        sortedPrograms[fullDate].push(item);
      } else {
        sortedPrograms[fullDate] = [item];
      }
    });
    return sortedPrograms;
  },
  getRangeDate: (state: IState) => {
    const rangeDateSortedPrograms = {} as any;
    const sortedPrograms = {} as any;
    const sortedProgramsEndDate = {} as any;
    const copyArray = [...(state.adminPrograms || [])];
    const copyArrayEndDate = [...(state.adminPrograms || [])];
    copyArray?.sort((a, b) => {
      const dateTampA = new Date(a.startDate);
      const dateTampB = new Date(b.startDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    copyArrayEndDate?.sort((a, b) => {
      const dateTampA = new Date(a.endDate);
      const dateTampB = new Date(b.endDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    copyArray?.forEach((item) => {
      const fullDate = `${formattedDate(item.startDate, 'dayOfWeek')}, ${formattedDate(
        item.startDate,
        'dateFullMonth'
      )}, ${formattedDate(item.startDate, 'onlyYear')}`;
      if (Object.keys(sortedPrograms).includes(fullDate)) {
        sortedPrograms[fullDate].push(item);
      } else {
        sortedPrograms[fullDate] = [item];
      }
    });
    copyArrayEndDate?.forEach((item) => {
      const fullDate = `${formattedDate(item.startDate, 'dayOfWeek')}, ${formattedDate(
        item.startDate,
        'dateFullMonth'
      )}, ${formattedDate(item.startDate, 'onlyYear')}`;
      if (Object.keys(sortedProgramsEndDate).includes(fullDate)) {
        sortedProgramsEndDate[fullDate].push(item);
      } else {
        sortedProgramsEndDate[fullDate] = [item];
      }
    });
    Object.keys(sortedPrograms).forEach((item) => {
      const newTime = formattedDate(sortedPrograms[item][0].startDate, 'onlyHour');
      return (rangeDateSortedPrograms[item] = [`${newTime}:00`]);
    });
    Object.keys(sortedProgramsEndDate).forEach((item) => {
      const newTime = formattedDate(
        sortedProgramsEndDate[item][sortedProgramsEndDate[item].length - 1].endDate,
        'onlyHour'
      );
      return rangeDateSortedPrograms[item].push(`${newTime}:00`);
    });

    return rangeDateSortedPrograms;
  },
  getAllDates: (state: IState) => {
    const dateList = [];
    const startDate = new Date(`${state.eventStartDate?.substr(0, 10)}`);
    const endDate = new Date(`${state.eventEndDate?.substr(0, 10)}`);
    for (let cur = startDate; cur <= endDate; cur.setDate(cur.getDate() + 1)) {
      let startTime = '';
      let endTime = '';
      const date = `${formattedDate(cur.toDateString(), 'dayOfWeek')}, ${formattedDate(
        cur.toDateString(),
        'dateFullMonth'
      )}, ${formattedDate(cur.toString(), 'onlyYear')}`;
      if (
        cur.toDateString() === new Date(`${state.eventStartDate?.substr(0, 10)}`).toDateString()
      ) {
        startTime = `${formattedDate(state.eventStartDate?.substr(0, 19), 'onlyTime').substr(
          0,
          2
        )}:00`;
      } else {
        startTime = '00:00';
      }
      if (cur.toDateString() === new Date(`${state.eventEndDate?.substr(0, 10)}`).toDateString()) {
        endTime = `${formattedDate(state.eventEndDate?.substr(0, 19), 'onlyTime').substr(0, 2)}:00`;
      } else {
        endTime = '00:00';
      }
      dateList.push({
        date: date,
        startTime: startTime,
        endTime: endTime,
        fullDate: cur.toDateString(),
      });
    }
    return dateList;
  },
  getHourRange: (state: IState, getters: any): string[] => {
    const hourRange = [];
    const startHour = +getters.getAllDates[state.activeDateIndex]?.startTime?.slice(0, 2) ?? 0;
    let lastHour = +getters.getAllDates[state.activeDateIndex]?.endTime?.slice(0, 2) ?? 0;

    hourRange.push(`0${startHour}:00`.slice(-5));
    let index = 0;
    let currentHour = +hourRange[index].slice(0, 2);

    if (+lastHour === 0) {
      lastHour += 24;
    }
    if (+startHour >= +lastHour) {
      lastHour += 24;
    }
    while (currentHour < +lastHour) {
      currentHour = currentHour + 1;
      if (currentHour > 24) {
        hourRange.push(`0${currentHour - 24}:00`.slice(-5));
      } else {
        hourRange.push(`0${currentHour}:00`.slice(-5));
      }
      index++;
    }
    return hourRange;
  },
};
