import { Commit } from 'vuex';
import { types } from './mutations';
import {
  IChatInfoRequestParams,
  ICheckUserInChatRequestParams,
  IListOfMessagesRequestParams,
  IMessagesByTypeRequestParams,
  IMessagesInThreadRequestParams,
  IMessageThreadSocketType,
  IMessageType,
  IRespondInThreadRequestData,
  ISendMessageRequestData,
  IToggleLikeMessageRequestData,
} from '@/services/ChatService/ChatServiceTypes';
import { ChatService } from '@/services';
import { useAuthStore } from '@/store/pinia/auth/auth.store';

export default {
  async AGetChatInfo(
    { commit }: { commit: Commit },
    params: IChatInfoRequestParams
  ): Promise<void> {
    try {
      const res = await ChatService.getChatInfo(params);
      commit(types.SET_CHAT_INFO, res);
    } catch (e) {
      console.log(e);
    }
  },
  // Добавить лайк
  async AAddLikeMessage(
    { commit }: { commit: Commit },
    body: IToggleLikeMessageRequestData
  ): Promise<void> {
    try {
      await ChatService.addLikeMessage(body);
      if (body.mainMessageUUID) {
        commit(types.ADD_LIKE_THREAD_MESSAGE, body);
      } else {
        commit(types.ADD_LIKE_MESSAGE, body);
      }
    } catch (e) {
      console.log(e);
    }
  },
  // Убрать лайк
  async ARemoveLikeMessage(
    { commit }: { commit: Commit },
    body: IToggleLikeMessageRequestData
  ): Promise<void> {
    try {
      await ChatService.removeLikeMessage(body);
      if (body.mainMessageUUID) {
        commit(types.REMOVE_LIKE_THREAD_MESSAGE, body);
      } else {
        commit(types.REMOVE_LIKE_MESSAGE, body);
      }
    } catch (e) {
      console.log(e);
    }
  },
  // Отправка сообщения
  ASendMessage({ commit }: { commit: Commit }, body: ISendMessageRequestData): Promise<object> {
    return ChatService.sendMessage(body);
  },

  // Отправка сообщения в тред
  ARespondInThread(
    { commit }: { commit: Commit },
    body: IRespondInThreadRequestData
  ): Promise<object> {
    return ChatService.respondInThread(body);
  },

  //Добавление сообщения
  ASocketAddMessage({ commit }: { commit: Commit }, message: IMessageType): void {
    commit(types.SOCKET_ADD_MESSAGE, message);
  },

  //Добавление сообщения в тред
  ASocketAddThreadMessage({ commit }: { commit: Commit }, message: IMessageThreadSocketType): void {
    commit(types.SOCKET_ADD_THREAD_MESSAGE, message);
  },

  // Получение списка сообщений
  async AGetListOfMessage(
    { commit }: { commit: Commit },
    params: IListOfMessagesRequestParams
  ): Promise<void> {
    try {
      const res = await ChatService.getListOfMessage(params);
      commit(types.SET_LIST_OF_MESSAGE, res);
    } catch (e) {
      console.log(e);
    }
  },

  // Получение списка сообщений по типу
  async AGetMessagesByType(
    { commit }: { commit: Commit },
    params: IMessagesByTypeRequestParams
  ): Promise<void> {
    try {
      const res = await ChatService.getMessagesByType(params);
      commit(types.SET_LIST_OF_MESSAGE, res);
    } catch (e) {
      console.log(e);
    }
  },

  // Получение списка сообщений треде
  async AGetMessagesInThread(
    { commit }: { commit: Commit },
    params: IMessagesInThreadRequestParams
  ): Promise<void> {
    try {
      const res = await ChatService.getMessagesInThread(params);
      commit(types.SET_THREAD_MESSAGES, res);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetListOfMessageLoadMore(
    { commit }: { commit: Commit },
    params: IListOfMessagesRequestParams
  ): Promise<void> {
    commit(types.SET_LIST_OF_MESSAGE_FETCHING_MORE, true);
    try {
      const res = await ChatService.getListOfMessage(params);
      commit(types.ADD_LIST_OF_MESSAGE, res);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_LIST_OF_MESSAGE_FETCHING_MORE, false);
  },
  async ACheckUserInChat(
    { commit }: { commit: Commit },
    params: ICheckUserInChatRequestParams
  ): Promise<void> {
    try {
      if (!useAuthStore().isLoggedIn) return;
      const res = await ChatService.checkUserInChat(params);
      commit(types.CHECK_USER_IN_CHAT, res);
    } catch (e) {
      commit(types.CHECK_USER_IN_CHAT, false);
    }
  },
  AClearChat({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_CHAT);
  },
};
