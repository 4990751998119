import { IState } from './state';
import {
  IChatInfo,
  ICheckUserInChatResponseData,
  IListOfMessagesResponseData,
  IMessagesInThreadResponseData,
  IMessageThreadSocketType,
  IMessageType,
  IToggleLikeMessageRequestData,
} from '@/services/ChatService/ChatServiceTypes';

export const types = {
  SET_CHAT_INFO: 'SET_CHAT_INFO',
  SET_LIST_OF_MESSAGE: 'SET_LIST_OF_MESSAGE',
  ADD_LIST_OF_MESSAGE: 'ADD_LIST_OF_MESSAGE',
  SOCKET_ADD_MESSAGE: 'SOCKET_ADD_MESSAGE',
  SET_THREAD_MESSAGES: 'SET_THREAD_MESSAGES',
  SOCKET_ADD_THREAD_MESSAGE: 'SOCKET_ADD_THREAD_MESSAGE',
  CHECK_USER_IN_CHAT: 'CHECK_USER_IN_CHAT',
  CLEAR_CHAT: 'CLEAR_CHAT',
  SET_LIST_OF_MESSAGE_FETCHING_MORE: 'SET_LIST_OF_MESSAGE_FETCHING_MORE',
  ADD_LIKE_MESSAGE: 'ADD_LIKE_MESSAGE',
  REMOVE_LIKE_MESSAGE: 'REMOVE_LIKE_MESSAGE',
  ADD_LIKE_THREAD_MESSAGE: 'ADD_LIKE_THREAD_MESSAGE',
  REMOVE_LIKE_THREAD_MESSAGE: 'REMOVE_LIKE_THREAD_MESSAGE',
};

export default {
  [types.SET_CHAT_INFO](state: IState, chatInfo: IChatInfo): void {
    state.chatInfo = chatInfo;
  },
  [types.SET_LIST_OF_MESSAGE](state: IState, responseListMessages: IListOfMessagesResponseData) {
    state.messages = responseListMessages?.messages;
    state.firstMessageSentAt = responseListMessages?.firstMessageSentAt;
  },
  [types.SOCKET_ADD_MESSAGE](state: IState, message: IMessageType) {
    if (!state?.messages?.length) {
      state.messages = [message];
    } else {
      state.messages?.unshift(message);
    }
  },
  [types.ADD_LIKE_THREAD_MESSAGE](state: IState, body: IToggleLikeMessageRequestData) {
    state.threadMessages.forEach((item) => {
      if (item.uuid === body.mainMessageUUID) {
        item.threads?.forEach((itemThread) => {
          if (itemThread.uuid === body.messageUUID) {
            if (itemThread.numberOfLikes) {
              itemThread.numberOfLikes += 1;
            } else {
              itemThread.numberOfLikes = 1;
            }
            itemThread.hasLiked = true;
          }
        });
      }
    });
  },
  [types.REMOVE_LIKE_THREAD_MESSAGE](state: IState, body: IToggleLikeMessageRequestData) {
    state.threadMessages.forEach((item) => {
      if (item.uuid === body.mainMessageUUID) {
        item.threads?.forEach((itemThread) => {
          if (itemThread.uuid === body.messageUUID) {
            if (itemThread.numberOfLikes) {
              itemThread.numberOfLikes -= 1;
            } else {
              itemThread.numberOfLikes = 0;
            }
            itemThread.hasLiked = false;
          }
        });
      }
    });
  },
  [types.ADD_LIKE_MESSAGE](state: IState, body: IToggleLikeMessageRequestData) {
    state.messages?.forEach((item) => {
      if (item.uuid === body.messageUUID) {
        if (item.numberOfLikes) {
          item.numberOfLikes += 1;
        } else {
          item.numberOfLikes = 1;
        }
        item.hasLiked = true;
      }
    });
  },
  [types.REMOVE_LIKE_MESSAGE](state: IState, body: IToggleLikeMessageRequestData) {
    state.messages?.forEach((item) => {
      if (item.uuid === body.messageUUID) {
        if (item.numberOfLikes) {
          item.numberOfLikes -= 1;
        } else {
          item.numberOfLikes = 0;
        }
        item.hasLiked = false;
      }
    });
  },
  [types.ADD_LIST_OF_MESSAGE](state: IState, responseListMessages: IListOfMessagesResponseData) {
    state.messages?.push(...(responseListMessages?.messages ?? []));
  },
  [types.SET_THREAD_MESSAGES](
    state: IState,
    responseThreadMessages: IMessagesInThreadResponseData
  ) {
    const isHaveMessage = state.threadMessages.find((item) => {
      return item.uuid === responseThreadMessages.uuid;
    });
    const newObj = { threads: responseThreadMessages.threads, uuid: responseThreadMessages.uuid };
    if (!isHaveMessage) {
      state.threadMessages.push(newObj);
    }
  },
  [types.SOCKET_ADD_THREAD_MESSAGE](state: IState, threadMessage: IMessageThreadSocketType) {
    state.threadMessages.forEach((item) => {
      if (item.uuid === threadMessage.messageUUID) {
        item?.threads?.push(threadMessage.threadMessage);
      }
    });
    state.messages?.forEach((item) => {
      if (item.uuid === threadMessage.messageUUID) {
        if (item.threadsCount) {
          item.threadsCount += 1;
        } else {
          item.threadsCount = 1;
        }
      }
    });
  },
  [types.CHECK_USER_IN_CHAT](state: IState, responseData: ICheckUserInChatResponseData) {
    state.chatAccess = responseData;
  },
  [types.CLEAR_CHAT](state: IState) {
    state.messages = [];
    state.firstMessageSentAt = '';
    state.threadMessages = [];
  },
  [types.SET_LIST_OF_MESSAGE_FETCHING_MORE](state: IState, isFetching: boolean) {
    state.isFetchingMoreMessage = isFetching;
  },
};
