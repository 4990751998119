import clientHttp from '@/utils/custom/http/СlientHttp';
import {
  IProgramInfo,
  IProgramInfoRequestParams,
  IReadProgramDocumentRequestData,
  IToggleFavoriteProgramRequestData,
  IUserProgramDocumentRequestParams,
} from '@/services/ProgramService/ProgramServiceTypes';

export const getUserProgramDocuments = (params: IUserProgramDocumentRequestParams) => {
  return clientHttp.get(`/program/${params.programId}/user-program-document`);
};

export const readProgramDocument = (data: IReadProgramDocumentRequestData) => {
  return clientHttp.post(`/program/${data.programUUID}/user-program-document`, {
    programDocumentUUID: data.programDocumentUUID,
    isPresentation: data.isPresentation,
  });
};

export const addProgramToFavoritePrograms = (data: IToggleFavoriteProgramRequestData) => {
  return clientHttp.post('/favorite-program', {
    programUUID: data.programUUID,
  });
};
export const removeProgramFromFavoritePrograms = (programUUID: string) => {
  return clientHttp.delete(`/favorite-program/${programUUID}`);
};
export const getFavoritePrograms = () => {
  return clientHttp.get('/favorite-program');
};

export const getProgramInfo = (params: IProgramInfoRequestParams) => {
  return clientHttp.get(
    `/events/${params.eventUUID}/programs/${params.programUUID}`
  ) as Promise<IProgramInfo>;
};
