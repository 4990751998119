<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    text-head="Восстановление пароля"
  >
    <form @submit.prevent="change">
      <div class="app-modal-recovery-password__subtitle">
        <app-text
          type="primary-book"
          color="secondary"
          text="Вы перешли по ссылке из письма и теперь можете изменить свой пароль"
        />
      </div>
      <app-input
        class="app-modal-recovery-password__input-password"
        label="Пароль"
        placeholder="Введите новый пароль"
        note="Пароль должен иметь не менее 8 символов, строчные и прописные буквы и цифры."
        :value="password"
        :errors="errors['password']"
        :type="isPassHidden ? 'password' : 'text'"
        @onInput="(val) => onInput(val)"
      >
        <template #append>
          <div
            class="app-modal-recovery-password__password-controller"
            @click="isPassHidden = !isPassHidden"
          >
            <app-icon :name="isPassHidden ? 'eye-off' : 'eye'" />
          </div> </template
      ></app-input>
      <app-button
        text="Изменить"
        web-type="submit"
        :is-disabled="!password.length"
        class="app-modal-recovery-password__button"
      />
    </form>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { AppIcon } from '@/ui/components/icons';
import { mapActions, mapState } from 'vuex';
import { EX_$Modal } from '@/classes/content';

export default Vue.extend({
  name: 'AppModalRecoveryPassword',
  components: { AppModal, AppText, AppInput, AppButton, AppIcon },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isLetterSent: {
      type: Boolean,
      default: true,
    },
    isShowPasswordInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      password: '',
      errors: {},
      isPassHidden: true,
    };
  },
  methods: {
    ...mapActions('auth', ['AChangePassword', 'ASetRecoverToken']),
    toggleModal(): void {
      this.$emit('toggleModal');
      this.password = '';
    },
    onInput(val: string) {
      this.password = val;
    },
    async change(): Promise<void> {
      try {
        const payload = {
          recoverToken: this.recoverToken,
          password: this.password,
        };
        await this.AChangePassword(payload);
        this.ASetRecoverToken('');
        this.toggleModal();
        this.$router.push('/');
        this.EX_$ModalClass.toggleModal('appModalAuth');
      } catch (e: any) {
        if (e.name === 'Error') Vue.set(this.errors, 'password', [e.message]);
      }
    },
  },
  computed: {
    ...mapState('auth', ['recoverToken']),
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
