<template>
  <div class="app-input">
    <slot name="label">
      <label v-if="label" class="app-input__label">
        <app-text :text="label" type="primary-book" />
      </label>
    </slot>
    <div
      :class="[
        'app-input__inner',
        {
          'with-err': errors.length,
          'with-success': !!success && !errors.length,
          disabled: disabled,
          'app-input__inner-search': isSearch,
        },
      ]"
    >
      <slot name="prepend" />
      <input
        v-if="tag === 'input'"
        :value="value"
        v-mask="getMask"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :maxlength="maxlength"
        class="app-input__inner_input"
        @input="input"
        @blur="$emit('onBlur')"
        @keyup.enter="(event) => $emit('onEnter', event.target.value)"
        @focusout="(event) => $emit('onFocusLeave', event.target.value)"
        @change="change"
      />
      <textarea
        v-if="tag === 'textarea'"
        :value="value"
        v-mask="getMask"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :maxlength="maxlength"
        class="app-input__inner_input"
        @input="input"
        @blur="$emit('onBlur')"
        @keyup.enter="(event) => $emit('onEnter', event.target.value)"
        @focusout="(event) => $emit('onFocusLeave', event.target.value)"
      />
      <slot name="append" />
    </div>
    <span v-if="!!errors.length && isHaveBottomTextError" class="app-input__error">
      <app-text
        v-for="(err, i) in errors"
        :key="err"
        :text="`${err}${i < errors.length - 1 ? '.' : ''}`"
        type="small-book"
        :color="'error'"
      />
    </span>
    <span v-else-if="!!success" class="app-input__success">
      <app-text :text="success" type="small-book" :color="'success'" />
    </span>
    <span v-else-if="!!note" class="app-input__note">
      <app-text :text="note" type="small-book" :color="'secondary'" />
    </span>
    <div v-else-if="isHaveEmptyBlock" class="app-input__empty" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import { TAutocompleteState, TInputTypes } from './AppInputTypes';
import { mapState } from 'vuex';

interface InputEvent extends Event {
  target: HTMLInputElement;
}
export default Vue.extend({
  name: 'AppInput',
  components: { AppText },
  props: {
    tag: {
      type: String,
      default: 'input',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
      required: false,
    },
    type: {
      type: String as PropType<TInputTypes>,
      default: 'text',
    },
    autocomplete: {
      type: String as PropType<TAutocompleteState>,
      default: 'off',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    success: {
      type: String,
      required: false,
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    note: {
      type: String,
      required: false,
    },
    isHaveBottomTextError: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHaveEmptyBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('client', ['clientInfo']),
    getMask(): string {
      let customMask = '';
      switch (this.type) {
        case 'phone':
          return '+7 (###) ###-##-##';
        case 'number':
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.maxlength; i++) {
            customMask += '#';
          }
          return customMask;
        case 'numeric':
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.maxlength; i++) {
            customMask += '#';
          }
          return customMask;
        case 'clock':
          return '##:##';
        case 'telegram':
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.maxlength; i++) {
            customMask += 'X';
          }
          return `@${customMask}`;
        case 'birthDate':
          return '##.##.####';
        case 'snils':
          return '###-###-### ##';
        default:
          return '';
      }
    },
  },
  methods: {
    input(event: InputEvent) {
      const value = event.target.value;
      this.$emit('onInput', value);
    },
    change(event: InputEvent) {
      const value = event.target.value;
      this.$emit('onChange', value);
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
