<template>
  <div
    class="box-border select-none hover:rounded-md hover:bg-neutral-100 active:rounded-md active:bg-neutral-100 px-[15px] py-2.5"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script lang="ts">
export default { name: 'MobileUserItem' };
</script>

<script setup lang="ts">
defineEmits<{
  (e: 'click'): void;
}>();
</script>

<style scoped lang="scss">
a {
  -webkit-tap-highlight-color: transparent !important;
}
</style>
