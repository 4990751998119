import { IState } from './state';
import { IChangeProfileInfo, IProfileInfo } from '@/services/ProfileService/ProfileServiceTypes';

export const types = {
  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_CHANGES: 'SET_PROFILE_CHANGES',
  SET_IMAGE_UPLOAD_PROFILE: 'SET_IMAGE_UPLOAD_PROFILE',
  SET_FETCHING_UPLOAD_IMAGE_PROFILE: 'SET_FETCHING_UPLOAD_IMAGE_PROFILE',
  SET_PROFILE_FILE_UPLOAD: 'SET_PROFILE_FILE_UPLOAD',
  SET_FETCHING_PROFILE_UPLOAD_FILE: 'SET_FETCHING_PROFILE_UPLOAD_FILE',
  CLEAR_PROFILE_INFO: 'CLEAR_PROFILE_INFO',
  CLEAR_PROFILE_IMAGE: 'CLEAR_PROFILE_IMAGE',
  SET_ERROR_STATUS_CODE: 'SET_ERROR_STATUS_CODE',
};

export default {
  [types.SET_PROFILE](state: IState, profileInfo: IProfileInfo) {
    state.profileInfo = profileInfo;
  },
  [types.CLEAR_PROFILE_INFO](state: IState) {
    state.profileInfo = {} as IProfileInfo;
  },
  [types.SET_PROFILE_CHANGES](state: IState, body: IChangeProfileInfo) {
    state.profileInfo.profile = { ...state.profileInfo.profile, ...body };
  },
  [types.SET_IMAGE_UPLOAD_PROFILE](state: IState, url: string) {
    state.imageUploadProfile = url;
  },
  [types.CLEAR_PROFILE_IMAGE](state: IState) {
    state.profileInfo.profile.avatarUrl = '';
  },
  [types.SET_FETCHING_UPLOAD_IMAGE_PROFILE](state: IState, isFetching: boolean) {
    state.isFetchingImageUploadProfile = isFetching;
  },
  [types.SET_PROFILE_FILE_UPLOAD](state: IState, url: string) {
    state.portfolioFileUpload = url;
  },
  [types.SET_FETCHING_PROFILE_UPLOAD_FILE](state: IState, isFetching: boolean) {
    state.isFetchingPortfolioFileUpload = isFetching;
  },
  [types.SET_ERROR_STATUS_CODE](state: IState, statusCode: number) {
    state.patchProfileErrorStatusCode = statusCode;
  },
};
