
import { computed, defineComponent, PropType, ref } from 'vue';
import EditProgramSpeakerForm from '@/components/admin/ProgramsControlPanel/EditProgram/EditProgramSpeakers/EditProgramSpeakerForm.vue';
import { AppButtonIcon, AppInput, AppText } from '@/ui/components';
import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';
import { ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import { AdminService } from '@/services';
import { EX_$Toaster } from '@/classes/content';
import { cloneDeep, debounce, some } from 'lodash';

const __default__ = defineComponent({
  name: 'EditProgramSpeakers',
  components: {
    AppSpinner,
    AppInput,
    AppText,
    AppButtonIcon,
    EditProgramSpeakerForm,
  },
  emits: ['update'],
  props: {
    speakers: {
      type: Array as PropType<ISpeakerCard[]>,
      required: true,
    },
    showSearchLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const searchInput = ref('');
    const isLoadingSearch = ref(false);
    const searchSpeakersList = ref<ISpeakerCard[]>([]);
    const doSearch = async (value: string) => {
      searchInput.value = value;
      isLoadingSearch.value = true;
      try {
        searchSpeakersList.value = await AdminService.searchSpeakers(searchInput.value);
      } catch (e) {
        EX_$Toaster.error('Произошла ошибка');
      } finally {
        isLoadingSearch.value = false;
      }
    };
    const debouncedDoSearch = debounce(doSearch, 800);
    const clearSearch = () => {
      searchInput.value = '';
      isLoadingSearch.value = false;
      isOpenSearchSpeakersList.value = false;
      searchSpeakersList.value = [];
    };
    const onInputClick = () => {
      if (!isOpenSearchSpeakersList.value) isOpenSearchSpeakersList.value = true;
    };

    const isOpenSearchSpeakersList = ref(false);

    const isOpenCreateForm = ref(false);
    const openCreateForm = () => {
      isOpenCreateForm.value = true;
      clearSearch();
      // Выбрасываем в таймаут чтобы отрисовать форму
      setTimeout(() => {
        document.getElementById(`speakerForm`)?.scrollIntoView({ block: 'start' });
      }, 0);
    };

    const getIsSpeakerAlreadyAdded = (userUUID: string | undefined) => {
      return some(props.speakers, { userUUID });
    };
    const addSpeaker = (speaker: ISpeakerCard) => {
      if (getIsSpeakerAlreadyAdded(speaker.userUUID)) {
        EX_$Toaster.error('Выбранный спикер уже добавлен');
      } else {
        emit('update', [speaker, ...props.speakers]);
        clearSearch();
        EX_$Toaster.success('Спикер добавлен');
      }
    };

    const getDottedText = (string: string | undefined) => {
      if (!string) return '';
      return string.length < 54 ? string : string.slice(0, 53) + '...';
    };

    const onSpeakerSave = (speaker: ISpeakerCard) => {
      isOpenCreateForm.value = false;
      emit('update', [speaker, ...props.speakers]);
    };

    const openEditFormsList = ref<string[]>([]);
    const getIsOpenEditForm = (userUUID: string | undefined) => {
      return userUUID ? openEditFormsList.value.includes(userUUID) : false;
    };
    const toggleEditForm = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        if (getIsOpenEditForm(userUUID)) {
          closeEditForm(userUUID);
        } else {
          openEditFormsList.value.push(userUUID);
          // Выбрасываем в таймаут чтобы отрисовать форму
          setTimeout(() => {
            document.getElementById(`speaker${userUUID}`)?.scrollIntoView({ block: 'start' });
          }, 0);
        }
      }
    };
    const closeEditForm = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        openEditFormsList.value = openEditFormsList.value.filter((item) => item !== userUUID);
      }
    };

    const removeSpeaker = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        closeEditForm(userUUID);
        const speakersToEmit = props.speakers.filter((item) => item.userUUID !== userUUID);
        emit('update', speakersToEmit);
      }
    };

    const onSpeakerEdit = (speaker: ISpeakerCard) => {
      const speakersToEmit = cloneDeep(props.speakers);
      const index = speakersToEmit.findIndex((item) => {
        return item.userUUID === speaker.userUUID;
      });
      if (index !== -1) speakersToEmit[index] = speaker;
      emit('update', speakersToEmit);
      closeEditForm(speaker.userUUID);
    };

    const toggleModerator = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        const speakersToEmit = cloneDeep(props.speakers);
        const speaker = speakersToEmit.find((item) => {
          return item.userUUID === userUUID;
        });
        if (speaker) {
          speaker.isModerator = !speaker.isModerator;
          emit('update', speakersToEmit);
        }
      }
    };

    const searchListTop = computed(() => {
      return props.showSearchLabel ? '93px' : '69px';
    });

    return {
      searchInput,
      isLoadingSearch,
      searchSpeakersList,
      debouncedDoSearch,
      onInputClick,
      isOpenSearchSpeakersList,
      isOpenCreateForm,
      openCreateForm,
      addSpeaker,
      getDottedText,
      onSpeakerSave,
      getIsOpenEditForm,
      toggleEditForm,
      closeEditForm,
      removeSpeaker,
      onSpeakerEdit,
      toggleModerator,
      searchListTop,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "7e93ac1c": (_vm.searchListTop)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__