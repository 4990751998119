import { IState } from '@/store/modules/event/state';
import { formattedDate } from '@/utils/custom';
import { IMyEvent } from '@/services/EventService/EventServiceTypes';
import { cloneDeep } from 'lodash';

export default {
  uncheckedInvitations: (state: IState) => state.invitationsEventInfo,
  myFutureEvents: (state: IState) => {
    return state.myEventList.filter((item) => new Date(item.event.endDate) >= new Date());
  },
  myPastEvents: (state: IState) => {
    return state.myEventList.filter((item) => new Date(item.event.endDate) < new Date());
  },
  sortedPrograms: (state: IState) => {
    const copyArray = cloneDeep(state.eventInfo.eventProgram);
    copyArray.sort((a, b) => {
      const dateTampA = new Date(a.startDate);
      const dateTampB = new Date(b.startDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    return copyArray;
  },
  dateSortedPrograms: (state: IState) => {
    const sortedPrograms = {} as any;
    const copyArray = cloneDeep(state.eventInfo.eventProgram);
    copyArray.sort((a, b) => {
      const dateTampA = new Date(a.startDate);
      const dateTampB = new Date(b.startDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    copyArray.forEach((item) => {
      const fullDate = formattedDate(item.startDate, 'dateFullMonth');
      if (Object.keys(sortedPrograms).includes(fullDate)) {
        sortedPrograms[fullDate].push(item);
      } else {
        sortedPrograms[fullDate] = [item];
      }
    });
    return sortedPrograms;
  },
  dateAndTimeSortedPrograms: (state: IState) => {
    const sortedPrograms = {} as any;
    const copyArray = cloneDeep(state.eventInfo.eventProgram);
    copyArray.sort((a, b) => {
      const dateTampA = new Date(a.startDate);
      const dateTampB = new Date(b.startDate);
      return dateTampA.getTime() - dateTampB.getTime();
    });
    copyArray.forEach((item) => {
      const fullDate = formattedDate(item.startDate, 'dateFullMonth');
      const fullTime = formattedDate(item.startDate, 'onlyTime');
      if (Object.keys(sortedPrograms).includes(fullDate)) {
        if (Object.keys(sortedPrograms[fullDate]).includes(fullTime)) {
          sortedPrograms[fullDate][fullTime].push(item);
        } else {
          sortedPrograms[fullDate][fullTime] = [item];
        }
      } else {
        sortedPrograms[fullDate] = {};
        sortedPrograms[fullDate][fullTime] = [item];
      }
    });
    return sortedPrograms;
  },
  isRegisteredOnEvent(state: IState): boolean {
    return state.myEventList.some((item: IMyEvent) => {
      return item.event.uuid === state.eventInfo.uuid;
    });
  },
};
