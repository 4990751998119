var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.UIPopover,{staticClass:"w-full",attrs:{"disabled":!_vm.disabled || !_setup.checkIsSlotFilled(_vm.$slots.disabledTip),"trigger":"hover","placement":_vm.disabledTipPosition},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('button',_vm._b({staticClass:"ui-button",class:{
        [_vm.type]: true,
        ghost: _vm.ghost,
        light: _vm.light,
        icon: Boolean(_vm.icon),
        [_vm.size]: true,
        [_vm.iconColorApproach]: true,
        'cursor-not-allowed opacity-30': _vm.disabled,
        'hover:cursor-pointer': !_vm.disabled,
      },attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},'button',_vm.$attrs,false),[(!_vm.icon && _vm.iconStart)?_c(_setup.UIIcon,{attrs:{"name":_vm.iconStart,"size":_setup.iconSize}}):_vm._e(),(!_vm.icon && _setup.checkIsSlotFilled(_vm.$slots.default))?_c('span',[_vm._t("default")],2):_vm._e(),(!_vm.icon && _vm.iconEnd)?_c(_setup.UIIcon,{attrs:{"name":_vm.iconEnd,"size":_setup.iconSize}}):_vm._e(),(_vm.icon)?_c(_setup.UIIcon,{attrs:{"name":_vm.icon,"size":_setup.iconSize,"rotate":_vm.iconRotate}}):_vm._e()],1)]},proxy:true}],null,true)},[_vm._t("disabledTip")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }