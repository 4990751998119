<template>
  <div class="ui-loader">
    <svg class="svg-spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="3" />
    </svg>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { TUILoaderSize } from '@/helpers/types/ui.types';

export default defineComponent({
  name: 'UILoader',
  props: {
    size: {
      type: String as PropType<TUILoaderSize>,
      required: false,
      default: 'default',
    },
  },
  setup(props) {
    const sizes: Record<TUILoaderSize, string> = {
      mini: '25px',
      small: '35px',
      default: '50px',
      large: '100px',
    };
    const svgWidth = computed(() => {
      return sizes[props.size];
    });

    return {
      svgWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
.ui-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
svg {
  height: v-bind(svgWidth);
  width: v-bind(svgWidth);
  animation: rotate 2s linear infinite;
  .path {
    stroke: var(--client-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
