import Vue from 'vue';
import VueToastr2 from 'vue-toastr-2';
import 'vue-toastr-2/dist/vue-toastr-2.min.css';

window.toastr = require('toastr');

Vue.use(VueToastr2, {
  closeHtml: '<button></button>',
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 9000,
  extendedTimeOut: 1000,
  onHidden: false,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  toastClass: 'cs-toast',
  iconClasses: {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
  },
});
