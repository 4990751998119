<template>
  <div class="app-modal-create-program__speakers">
    <div v-click-outside="() => (isOpenSearchSpeakersList = false)">
      <AppInput
        :value="searchInput"
        :color="'secondary'"
        :label="showSearchLabel ? 'Спикеры' : ''"
        type="main"
        placeholder="Введите e-mail или ФИО"
        @onInput="debouncedDoSearch"
        @click.native="onInputClick"
      />
      <div
        v-if="searchInput && isOpenSearchSpeakersList"
        class="app-modal-create-program__speakers-select-list"
        :class="{ scroll: searchSpeakersList.length >= 3 }"
      >
        <div v-if="isLoadingSearch" class="app-modal-create-program__speakers-select-list-spinner">
          <AppSpinner size="small" />
        </div>

        <div v-if="!isLoadingSearch" class="app-modal-create-program__speakers-select-list-header">
          <div class="app-modal-create-program__speakers-select-list-title">
            <div
              v-if="searchSpeakersList.length"
              class="app-modal-create-program__speakers-select-list-found-text"
            >
              <div>
                <AppText text="Добавить нового" line-height="22" type="primary" color="secondary" />
              </div>
              <div>
                <AppText text="→" line-height="20" type="primary" color="secondary" />
              </div>
            </div>
            <div
              v-if="!searchSpeakersList.length"
              class="app-modal-create-program__speakers-select-list-not-found-text"
            >
              <div>
                <AppText
                  text="Cпикер по вашему запросу не найден"
                  line-height="22"
                  type="primary"
                  color="secondary"
                />
              </div>
              <div>
                <AppText
                  text="Добавьте нового спикера →"
                  line-height="16"
                  type="small"
                  specialTextColor="#3282FF"
                />
              </div>
            </div>
          </div>
          <AppButtonIcon
            class="app-modal-create-program__speakers-select-list-add"
            :class="{
              'app-modal-create-program__speakers-select-list-add--blue-border':
                !searchSpeakersList.length,
            }"
            name="plus"
            icon-size="16"
            @click="openCreateForm"
          />
        </div>
        <button
          v-for="item in searchSpeakersList"
          :key="item.userUUID"
          class="app-modal-create-program__speakers-select-list-item"
          @click="addSpeaker(item)"
        >
          <span class="app-modal-create-program__speakers-item">
            <img
              :src="item.info?.image"
              alt="Спикер"
              @error="$event.target.src = require(`@/assets/images/chat/user.svg`)"
            />
            <span class="app-modal-create-program__speakers-item-info">
              <span class="app-modal-create-program__speakers-item-title">
                <AppText :text="item.info?.name" type="primary" line-height="22px" />
              </span>
              <span class="app-modal-create-program__speakers-item-personal">
                <AppText
                  :text="getDottedText(item.info?.jobTitle)"
                  type="small"
                  line-height="20"
                  color="secondary"
                />
                <AppText
                  :text="getDottedText(item.info?.description)"
                  type="small"
                  line-height="20"
                  color="secondary"
                />
              </span>
            </span>
          </span>
        </button>
      </div>
    </div>

    <div v-if="isOpenCreateForm" class="app-modal-create-program__speaker-form-wrapper">
      <EditProgramSpeakerForm @close="isOpenCreateForm = false" @save="onSpeakerSave" />
    </div>

    <div class="speaker-card-list">
      <div
        v-for="(item, index) in speakers"
        :key="index"
        class="speaker-card-wrapper"
        :id="`speaker${item?.userUUID}`"
      >
        <div class="speaker-card" @click="toggleEditForm(item?.userUUID)">
          <img
            :src="item.info?.image"
            alt="Спикер"
            @error="$event.target.src = require(`@/assets/images/chat/user.svg`)"
          />
          <div class="speaker-card-info">
            <div class="speaker-card-info-name">{{ item.info?.name }}</div>
            <div class="speaker-card-info-description">
              <div>
                {{ item.info?.jobTitle }}
              </div>
              <div>
                {{ item.info?.description }}
              </div>
            </div>
          </div>

          <button
            class="speaker-card-make-moderator"
            v-if="!item.isModerator"
            @click.stop="toggleModerator(item.userUUID)"
          >
            Сделать модератором
          </button>
          <div class="speaker-card-is-moderator" v-if="item.isModerator" @click.stop="() => {}">
            <div>Модератор</div>
            <div @click.stop="toggleModerator(item.userUUID)">
              <AppButtonIcon
                class="speaker-card-is-moderator-remove"
                name="x"
                color="#FFFFFF"
                icon-size="12"
              />
            </div>
          </div>
          <AppButtonIcon
            class="speaker-card-remove"
            name="x"
            color="#FFFFFF"
            icon-size="12"
            @click="removeSpeaker(item?.userUUID)"
          />
        </div>

        <EditProgramSpeakerForm
          v-if="getIsOpenEditForm(item.userUUID)"
          :speaker-info="item.info"
          :uuid="item.userUUID"
          @close="closeEditForm(item.userUUID)"
          @save="onSpeakerEdit"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import EditProgramSpeakerForm from '@/components/admin/ProgramsControlPanel/EditProgram/EditProgramSpeakers/EditProgramSpeakerForm.vue';
import { AppButtonIcon, AppInput, AppText } from '@/ui/components';
import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';
import { ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import { AdminService } from '@/services';
import { EX_$Toaster } from '@/classes/content';
import { cloneDeep, debounce, some } from 'lodash';

export default defineComponent({
  name: 'EditProgramSpeakers',
  components: {
    AppSpinner,
    AppInput,
    AppText,
    AppButtonIcon,
    EditProgramSpeakerForm,
  },
  emits: ['update'],
  props: {
    speakers: {
      type: Array as PropType<ISpeakerCard[]>,
      required: true,
    },
    showSearchLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const searchInput = ref('');
    const isLoadingSearch = ref(false);
    const searchSpeakersList = ref<ISpeakerCard[]>([]);
    const doSearch = async (value: string) => {
      searchInput.value = value;
      isLoadingSearch.value = true;
      try {
        searchSpeakersList.value = await AdminService.searchSpeakers(searchInput.value);
      } catch (e) {
        EX_$Toaster.error('Произошла ошибка');
      } finally {
        isLoadingSearch.value = false;
      }
    };
    const debouncedDoSearch = debounce(doSearch, 800);
    const clearSearch = () => {
      searchInput.value = '';
      isLoadingSearch.value = false;
      isOpenSearchSpeakersList.value = false;
      searchSpeakersList.value = [];
    };
    const onInputClick = () => {
      if (!isOpenSearchSpeakersList.value) isOpenSearchSpeakersList.value = true;
    };

    const isOpenSearchSpeakersList = ref(false);

    const isOpenCreateForm = ref(false);
    const openCreateForm = () => {
      isOpenCreateForm.value = true;
      clearSearch();
      // Выбрасываем в таймаут чтобы отрисовать форму
      setTimeout(() => {
        document.getElementById(`speakerForm`)?.scrollIntoView({ block: 'start' });
      }, 0);
    };

    const getIsSpeakerAlreadyAdded = (userUUID: string | undefined) => {
      return some(props.speakers, { userUUID });
    };
    const addSpeaker = (speaker: ISpeakerCard) => {
      if (getIsSpeakerAlreadyAdded(speaker.userUUID)) {
        EX_$Toaster.error('Выбранный спикер уже добавлен');
      } else {
        emit('update', [speaker, ...props.speakers]);
        clearSearch();
        EX_$Toaster.success('Спикер добавлен');
      }
    };

    const getDottedText = (string: string | undefined) => {
      if (!string) return '';
      return string.length < 54 ? string : string.slice(0, 53) + '...';
    };

    const onSpeakerSave = (speaker: ISpeakerCard) => {
      isOpenCreateForm.value = false;
      emit('update', [speaker, ...props.speakers]);
    };

    const openEditFormsList = ref<string[]>([]);
    const getIsOpenEditForm = (userUUID: string | undefined) => {
      return userUUID ? openEditFormsList.value.includes(userUUID) : false;
    };
    const toggleEditForm = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        if (getIsOpenEditForm(userUUID)) {
          closeEditForm(userUUID);
        } else {
          openEditFormsList.value.push(userUUID);
          // Выбрасываем в таймаут чтобы отрисовать форму
          setTimeout(() => {
            document.getElementById(`speaker${userUUID}`)?.scrollIntoView({ block: 'start' });
          }, 0);
        }
      }
    };
    const closeEditForm = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        openEditFormsList.value = openEditFormsList.value.filter((item) => item !== userUUID);
      }
    };

    const removeSpeaker = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        closeEditForm(userUUID);
        const speakersToEmit = props.speakers.filter((item) => item.userUUID !== userUUID);
        emit('update', speakersToEmit);
      }
    };

    const onSpeakerEdit = (speaker: ISpeakerCard) => {
      const speakersToEmit = cloneDeep(props.speakers);
      const index = speakersToEmit.findIndex((item) => {
        return item.userUUID === speaker.userUUID;
      });
      if (index !== -1) speakersToEmit[index] = speaker;
      emit('update', speakersToEmit);
      closeEditForm(speaker.userUUID);
    };

    const toggleModerator = (userUUID: string | undefined) => {
      if (!userUUID) {
        EX_$Toaster.error('У спикера отсутствует userUUID');
      } else {
        const speakersToEmit = cloneDeep(props.speakers);
        const speaker = speakersToEmit.find((item) => {
          return item.userUUID === userUUID;
        });
        if (speaker) {
          speaker.isModerator = !speaker.isModerator;
          emit('update', speakersToEmit);
        }
      }
    };

    const searchListTop = computed(() => {
      return props.showSearchLabel ? '93px' : '69px';
    });

    return {
      searchInput,
      isLoadingSearch,
      searchSpeakersList,
      debouncedDoSearch,
      onInputClick,
      isOpenSearchSpeakersList,
      isOpenCreateForm,
      openCreateForm,
      addSpeaker,
      getDottedText,
      onSpeakerSave,
      getIsOpenEditForm,
      toggleEditForm,
      closeEditForm,
      removeSpeaker,
      onSpeakerEdit,
      toggleModerator,
      searchListTop,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/ui/styles/font/style';
@import '~@/ui/styles/colors/index';

.app-modal-create-program__speakers-select-list {
  top: v-bind(searchListTop);
}

.speaker-card {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 6px 10px;
  cursor: pointer;
  img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    object-fit: cover;
  }
  &:hover {
    background-color: $SMOKY-WHITE;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-name {
      color: #111;
      font-family: $font-family-Suisse-Intl-Regular, sans-serif;
      font-size: 16px;
      line-height: 22px;
    }
    &-description {
      display: flex;
      flex-direction: column;
      gap: 3px;
      color: #83888f;
      font-family: $font-family-Suisse-Intl-Regular, sans-serif;
      font-size: 12px;
      line-height: 16px;
      > div {
        width: calc(100% - 68px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &-make-moderator {
    all: unset;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    position: absolute;
    top: -10px;
    right: 26px;
    z-index: 2;
    background: #83888f;
    border: 2px solid #ffffff;
    border-radius: 18px;
    color: #fff;
    font-family: $font-family-Suisse-Intl-Regular, sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
  &-is-moderator {
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding-left: 8px;
    padding-right: 4px;
    position: absolute;
    top: -10px;
    right: 26px;
    z-index: 2;
    background: #3282ff;
    border: 2px solid #ffffff;
    border-radius: 18px;
    color: #fff;
    font-family: $font-family-Suisse-Intl-Regular, sans-serif;
    font-size: 12px;
    line-height: 16px;
    &-remove {
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3282ff;
    }
  }
  &-remove {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: -10px;
    right: -3px;
    background: #83888f;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
}
</style>
