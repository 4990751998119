<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-align-text="true"
    size="large"
    text-type="thirdTitle"
    text-head="Спикеры"
  >
    <div class="flex items-center space-x-4 bg-orange rounded-md px-4 py-2.5">
      <div>
        <UIIcon name="warning" :size="16" class="stroke-yellow fill-none" />
      </div>
      <div>
        <p class="text-sm text-yellow">
          Вы можете изменить очередность отображения карточек, а также скрыть или добавить карточки
          по своему усмотрению. Чтобы добавить нового спикера или отредактировать уже существующего,
          перейдите в раздел
          <AppButtonLink
            :link="`/program-control-panel/${eventInfo.slug}`"
            title="Деловая программа."
            font-type="secondary"
            type="outline"
            special-text-color="#957400"
            class="underline"
          />
        </p>
      </div>
    </div>
    <Draggable
      v-model="speakers"
      @start="drag = true"
      @end="drag = false"
      v-bind="dragOptions"
      tag="div"
      class="speaker-list space-y-2 mt-7 mb-7 max-h-[342px] overflow-y-auto custom-scrollbar"
      :class="{ scroll: speakers.length > 4 }"
    >
      <div
        v-for="speaker in speakers"
        :key="speaker.uuid"
        class="flex items-center space-x-2.5 cursor-grab"
      >
        <div><UIIcon name="drag-slider" class="fill-neutral-200" :size="24" /></div>
        <div
          class="w-full bg-white flex items-center space-x-4 py-2.5 px-2.5 border-1 border-solid border-neutral-200 rounded-[8px]"
        >
          <div>
            <AppButtonIcon
              :name="speaker.visible ? 'eye' : 'eye-off'"
              @click="handleChangeVisible(speaker)"
            />
          </div>
          <div class="flex items-center space-x-4" :class="{ 'opacity-50': !speaker.visible }">
            <div>
              <img
                :src="speaker.info?.image"
                alt="speaker"
                class="h-[53px] w-[53px] object-cover rounded-full"
              />
            </div>
            <div class="flex flex-col overflow-hidden space-y-[1px]">
              <p class="text-base text-black-50">{{ speaker.info?.name }}</p>
              <span class="text-xs text-gray">{{ lineBreaker(speaker.info?.jobTitle, 58) }}</span>
              <span class="text-xs text-gray">{{
                lineBreaker(speaker.info?.description, 58)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
    <div class="app-modal-event-speakers__buttons">
      <AppButton text="Сохранить" @click="saveChanges" :is-disabled="isLoading" />
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import Draggable from 'vuedraggable';
import AppButtonLink from '@/ui/components/buttons/app-button-link/AppButtonLink.vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { AppButton, AppButtonIcon } from '@/ui/components';
import { ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import { mapActions, mapState } from 'vuex';
import { isEqual } from 'lodash';
import { lineBreaker } from '@/helpers/methods/string.method';

export default Vue.extend({
  name: 'AppModalEventSpeakers',
  components: {
    UIIcon,
    AppButtonLink,
    AppModal,
    AppButtonIcon,
    AppButton,
    Draggable,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      speakers: [] as Array<ISpeakerCard>,
      countKey: 0,
      isLoading: false,
      drag: false,
    };
  },
  methods: {
    ...mapActions('event', ['AGetEventInfo', 'updateEventSpeakersOrder']),
    lineBreaker,
    toggleModal() {
      this.$emit('toggleModal');
    },
    async saveChanges() {
      // Сохраняем данные, если в массиве произошли изменения
      if (!isEqual(this.eventInfo.eventSpeaker, this.speakers)) {
        this.isLoading = true;

        const speakers = this.assignSpeakerOrder(this.speakers);
        const slug = this.$route.params.slug;

        await this.updateEventSpeakersOrder({ speakers, slug });

        this.isLoading = false;
      }

      this.toggleModal();
    },
    assignSpeakerOrder(speakers: ISpeakerCard[]) {
      return speakers.map((item, i) => ({ ...item, order: i }));
    },
    handleChangeVisible(speaker: ISpeakerCard) {
      speaker.visible = !speaker.visible;
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    dragOptions() {
      return {
        animation: 150,
        disabled: false,
        removeCloneOnHide: true,
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder
        chosenClass: 'sortable-chosen', // Class name for the chosen item
        dragClass: 'sortable-drag',
        forceFallback: true,
      };
    },
  },
  async mounted() {
    await this.AGetEventInfo(this.$route.params.slug);
    this.speakers = this.eventInfo?.eventSpeaker;
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>

<style lang="scss" scoped>
.speaker-list {
  width: 100%;
  padding-right: 9px;

  &::-webkit-scrollbar {
    width: 10px;
    &-thumb {
      width: 10px;
      border-radius: 3.5px;
      background-color: rgba(222, 222, 222, 0.5);
      background-clip: content-box;
    }
    &-track {
      background: white;
      border-radius: 6px;
    }
  }
}
.scroll {
  width: calc(100% + 9px);
  height: 352px;
  overflow-y: auto;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.sortable-ghost {
  opacity: 0;
}
</style>
