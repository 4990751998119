import {
  IEventProgramFavorite,
  IProgramInfo,
  IUserProgramDocument,
} from '@/services/ProgramService/ProgramServiceTypes';
import { EventProgramBannerAdsBlockData } from '@/helpers/types/event-program/event-program.types';

export interface IState {
  programDocumentsRead: Array<IUserProgramDocument>;
  favoritePrograms: Array<IEventProgramFavorite>;
  programInfo: IProgramInfo;
  imageUploadCreateProgram: string;
  isFetchingImageUploadCreateProgram: boolean;
  adBanners: EventProgramBannerAdsBlockData | null;
}
export default () => ({
  programDocumentsRead: [],
  favoritePrograms: [] as IEventProgramFavorite[],
  programInfo: {} as IProgramInfo,
  imageUploadNewProgram: '',
  isFetchingImageUploadNewProgram: '',
  adBanners: null as EventProgramBannerAdsBlockData | null,
});
