<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :text-head="`Одна регистрация на все события ${clientInfo.name}`"
  >
    <div v-if="!isLogin" class="app-modal-registration__subtitle">
      <app-text type="primary-book" color="secondary" :text="'Уже есть аккаунт?'" />
      <div @click="openAuthModal" class="app-modal-auth__text">
        <app-text type="primary-book" color="text-link" :text="'Войдите'" />
      </div>
    </div>
    <AppSocialNetworks />
    <div class="app-modal-registration__container-names">
      <AppInput
        label="Имя"
        placeholder="Имя"
        :value="name"
        @onInput="(val) => onInput(val, 'name')"
      /><AppInput
        label="Фамилия"
        placeholder="Фамилия"
        :value="secondName"
        @onInput="(val) => onInput(val, 'secondName')"
      />
    </div>
    <AppInput
      class="app-modal-registration__input-email"
      label="E-mail"
      placeholder="example@mail.com"
      :value="email"
      @onInput="(val) => onInput(val, 'email')"
    />
    <AppInput
      class="app-modal-registration__input-password"
      label="Пароль"
      placeholder="Придумайте пароль"
      :value="password"
      :type="isPassHidden ? 'password' : 'text'"
      :note="'Пароль должен иметь не менее 8 символов, строчные и прописные буквы и цифры'"
      @onInput="(val) => onInput(val, 'password')"
      ><template #append>
        <div
          class="app-modal-registration__password-controller"
          @click="isPassHidden = !isPassHidden"
        >
          <app-icon :name="isPassHidden ? 'eye-off' : 'eye'" />
        </div>
      </template>
    </AppInput>
    <AppCheckBox
      class="app-modal-registration__switcher"
      type="checkbox"
      text="Хочу получать уведомления и новости на почту"
    />
    <AppButton text="Зарегистрироваться" :is-disabled="false" @click="registration" />
    <div class="app-modal-registration__forgot-container">
      <app-text
        type="primary-book"
        color="secondary"
        :text="'Нажимая кнопку «Зарегистрироваться», вы принимаете условия '"
      />
      <a :href="clientInfo?.footer?.termsOfUse ?? ''" target="_blank"
        ><app-text type="primary-book" color="text-link" :text="'пользовательского соглашения'"
      /></a>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppModal from '@/ui/components/modals/app-modal';
import { AppSocialNetworks } from '@/components/modals';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { AppIcon } from '@/ui/components/icons';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import { EX_$Modal } from '@/classes/content';
import MAuth from '@/mixins/MAuth';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'AppModalRegistration2',
  components: {
    AppCheckBox,
    AppButton,
    AppInput,
    AppText,
    AppModal,
    AppSocialNetworks,
    AppIcon,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [MAuth],
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      email: '',
      password: '',
      name: '',
      secondName: '',
      isPassHidden: true,
    };
  },
  methods: {
    toggleModal() {
      return this.$emit('toggleModal');
    },
    openAuthModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalAuth');
    },

    openRecoveryModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalRecoveryByEmail');
    },
    onInput(val: string, field: string) {
      if (field === 'email') {
        this.email = val;
      }
      if (field === 'password') {
        this.password = val;
      }
      if (field === 'secondName') {
        this.secondName = val;
      }
    },
  },
  computed: {
    ...mapState('auth', ['registrationForm', 'isLogin']),
    ...mapState('client', ['clientInfo']),
  },
});
</script>

<style lang="scss">
@use 'style';
</style>
